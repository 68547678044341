import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/free-mode/free-mode.min.css";
import "swiper/modules/thumbs/thumbs.min.css";
import "./nexus.scss";
import { prodFilterName } from "../../helpers/common";

import {
  Card,
  Typography,
  Grid,
  Button,
  CircularProgress,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Example2 = ({ categories, loading }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div
      className="carousel-container"
      style={{ marginTop: "30px", color: "#494949" }}
    >
      <Box
        my={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography className="main-heading-landing-page">
          {t("marketplace.Categories")}
        </Typography>
        <Button
          className="outlined-grey"
          variant="outlined"
          sx={{
            padding: { xs: "5px 12px !important", sm: "10px 28px !important" },
            fontSize: { xs: "12px !important", sm: "14px !important" },
          }}
          onClick={() => navigate("/categorylisting")}
        >
          {t("marketplace.exploreAll")}
        </Button>
      </Box>
      {loading ? (
        <Box textAlign="center">
          <CircularProgress sx={{ color: "#235D5E" }} />
        </Box>
      ) : categories && categories?.length > 0 ? (
        <Swiper
          spaceBetween={0}
          slidesPerGroup={4}
          rewind={true}
          height={361}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          loop={true}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
          breakpoints={{
            320: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 5,
              slidesPerGroup: 5,
              slidesPerView: 5,
            },
            1199: {
              slidesPerView: 6,
              slidesPerGroup: 6,
              slidesPerView: 6,
            },
          }}
        >
          {categories &&
            categories?.map((category, index) => {
              return (
                <SwiperSlide key={index}>
                  <Card raised className="category-card">
                    <Grid item xs={3}>
                      <Box className="carousel-img-container-category">
                        <img src={category?.imageCover} />
                      </Box>
                    </Grid>
                    <Box pt={1}>
                      <Typography className="category-text text-ellipses">
                        {prodFilterName(category?.title)}
                      </Typography>
                    </Box>
                  </Card>
                </SwiperSlide>
              );
            })}
        </Swiper>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
          width="100%"
        >
          No Categories are available!
        </Box>
      )}
      <br />
    </div>
  );
};

export default Example2;
