import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, TextField } from "@mui/material";
import logo from "../../assets/images/newLogo.svg";
import MailImg1 from "../../assets/businessPage/mail-img1.png";
import MailImg2 from "../../assets/businessPage/mail-img2.png";
import { Formik } from "formik";
import { queriesInitialValues, QueriesSchema } from "./helper";
import { ToastContainer } from "react-toastify";
import FErrorMessage from "../../shared/components/FErrorMessage";
import PhoneInput from "react-phone-input-2";
import { queryDetails } from "../../services/auth";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";

const Queries = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.queries?.loading);

  return (
    <>
      <Formik
        initialValues={queriesInitialValues}
        enableReinitialize={true}
        onSubmit={(values, { resetForm }) => {
          dispatch(queryDetails(values, navigate));
        }}
        validationSchema={QueriesSchema}
      >
        {(props) => (
          <Box
            display="flex"
            flexDirection="column"
            sx={{ height: { xs: "100%", sm: "100vh" } }}
          >
            <Box className="logoContainer" justifyContent=" center">
              <img src={logo} className="logo" />
              <Typography className="logoText">UnioRx</Typography>
            </Box>
            <Box
              padding="0px 10px"
              width={"100%"}
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ height: { xs: "100%", sm: "100vh" } }}
            >
              <Box
                pr={6}
                sx={{
                  display: { xs: "none", md: "block" },
                }}
              >
                <img src={MailImg1} alt="IMG" />
              </Box>

              <form
                onSubmit={props.handleSubmit}
                style={{
                  padding: "30px",
                  boxShadow: "0 0 15px -5px #707070",
                  maxWidth: "650px",
                  margin: "30px 0",
                  borderRadius: "20px",
                  // backgroundColor: "#f9f9f9",
                }}
              >
                <Box>
                  <Box mb={2}>
                    <Typography
                      sx={{
                        color: "#101828",
                        fontWeight: "500",
                        fontSize: "22px",
                      }}
                    >
                      Queries
                    </Typography>
                    <Typography color="#101828" fontWeight="400" pb={1}>
                      We're open for any suggestion or just to have a chat
                    </Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12} md={6} lg={6}>
                      <InputLabel shrink>First Name</InputLabel>
                      <TextField
                        fullWidth
                        className="authfield"
                        placeholder="First Name"
                        value={props.values.first_name}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        name="first_name"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12} md={6} lg={6}>
                      <InputLabel shrink>Last Name</InputLabel>
                      <TextField
                        fullWidth
                        className="authfield"
                        placeholder="Last Name"
                        value={props.values.last_name}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        name="last_name"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <InputLabel shrink htmlFor="email-input">
                        Email
                      </InputLabel>
                      <TextField
                        fullWidth
                        className="authfield"
                        placeholder="Email"
                        value={props.values.email}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        name="email"
                        error={
                          props.touched.email && Boolean(props.errors.email)
                        }
                        type="email"
                        required
                      />
                      <FErrorMessage name="email" />
                    </Grid>
                    {/* <Grid item sm={6} xs={12} md={6} lg={6}>
                      <InputLabel shrink>Address</InputLabel>
                      <TextField
                        fullWidth
                        className="authfield"
                        placeholder="Address"
                        value={props.values.location}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        name="location"
                      />
                    </Grid> */}

                    <Grid item sm={6} xs={12}>
                      <InputLabel shrink>Phone Number</InputLabel>
                      <PhoneInput
                        international
                        className="authfield"
                        country="ca"
                        preferredCountries={["ca"]}
                        excludeCountries={["us"]}
                        disableInitialCountryGuess={false}
                        name="mobile_no"
                        autoComplete="mobile_no"
                        variant="filled"
                        onChange={(phone, country) => {
                          props.setFieldValue("mobile_no", phone);
                        }}
                        onBlur={props.handleBlur}
                        value={props?.values?.mobile_no}
                        required
                        style={{
                          height: "45px",
                          color: "#333",
                          border: "1px solid #d0d5dd",
                          boxShadow: " 0px 1px 2px rgba(16, 24, 40, 0.05)",
                          borderRadius: "8px",
                          backgroundColor: "white",
                        }}
                        inputStyle={{
                          border: "none",
                          outline: "none",
                          boxShadow: "none",
                          background: "none",
                          padding: "8px 0px 8px 60px",
                          width: "100%",
                        }}
                        buttonStyle={{
                          border: "none",
                          outline: "none",
                          boxShadow: "none",
                          background: "none",
                        }}
                      >
                        {() => (
                          <TextField
                            fullWidth
                            label="Landline"
                            name="mobile_no"
                            value={props.values.mobile_no}
                          />
                        )}
                      </PhoneInput>
                    </Grid>
                    {/* <Grid item sm={6} xs={12} md={6} lg={6}>
                      <InputLabel shrink>Query</InputLabel>
                      <TextField
                        fullWidth
                        className="authfield"
                        placeholder="Query"
                        value={props.values.query}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        name="query"
                      />
                    </Grid> */}
                  </Grid>
                </Box>
                <Box
                  sx={{
                    width: { xs: "100%", md: "100%" },
                    margin: "auto",
                    marginTop: "24px",
                  }}
                >
                  <Button
                    className="containedPrimary"
                    variant="contained"
                    sx={{ width: "100%" }}
                    onClick={props.handleSubmit}
                    disabled={loading}
                  >
                    {loading ? (
                      <ClipLoader size={25} color="white" loading />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Box>
                <ToastContainer />
              </form>
            </Box>
          </Box>
        )}
      </Formik>
    </>
  );
};
//for pr1s
export default Queries;
