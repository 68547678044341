import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import Footer from "../../../shared/components/footer";
import NewsLetter from "../../../shared/components/footer/newsletter";

import "./blog.scss";
import {
  Card,
  Grid,
  List,
  TextField,
  ListSubheader,
  ListItemButton,
  ListItemText,
  Button,
  IconButton,
  Stack,
} from "@mui/material";
import Vactor from "../../../assets/businessPage/Vactor.svg";
import BlogImage from "../../../assets/businessPage/blog.jpg";
import FaqResponsiveHeader from "../faqHeader";
import { useTranslation } from "react-i18next";

const Blog = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <Container>
      <FaqResponsiveHeader />
      <Container maxWidth={false} className="uniorxLandingPageContainer">
        <Box sx={{ background: "#235d5e" }}>
          <Box
            className="VactorCus"
            sx={{
              backgroundImage: `url(${Vactor})`,
            }}
          >
            <Typography
              color="#ffff"
              className="mainHeading"
              sx={{
                padding: {
                  xs: "20px 15px 10px !important",
                  sm: "32px !important",
                },
              }}
            >
              {t("blogs.blog")}
            </Typography>

            <Typography
              color="#ffff"
              fontSize={"14px !important"}
              fontWeight={200}
              className="mainParagraph"
            >
              {t("blogs.descr")}
            </Typography>
          </Box>
        </Box>
        <Grid mt={2}>
          <Box className="blogCardMain">
            <Box className="blogCard">
              <Box className="imageIcon">
                <img src={BlogImage} />
              </Box>
              <Box className="blogText">
                <Typography className="heading">
                  How to Save Money by Selling Medication to Your Fellow
                  Pharmacists
                  {/* {t("blogs.cardTitle")} */}
                </Typography>
                <Typography className="title">
                  In today's fast-paced pharmacy industry, every dollar counts.
                  Managing surplus medications can be a challenge, and
                  sometimes, it leads to wasted products and lost revenue.
                  However, what if there was a way to recoup some of these
                  losses?
                  {/* {t("blogs.cardDesc")} */}
                </Typography>

                <Box className="cardBtn">
                  <Button
                    sx={{
                      backgroundColor: "white !important",
                      color: "#235d5e",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    onClick={() => navigate("/blogdetail")}
                  >
                    {t("blogs.readMore")}
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box className="blogCard">
              <Box className="imageIcon">
                <img src={BlogImage} />
              </Box>
              <Box className="blogText">
                <Typography className="heading">
                  The Secret to Profitable Pharmacy: Sell Drugs to Other
                  Pharmacists
                  {/* {t("blogs.cardTitle")} */}
                </Typography>
                <Typography className="title">
                  The secret to a profitable pharmacy might be simpler than you
                  think, and it's right within our professional community. Ever
                  wondered what to do with the surplus medications cluttering
                  your shelves? The answer: sell them to other pharmacists!
                  {/* {t("blogs.cardDesc")} */}
                </Typography>

                <Box className="cardBtn">
                  <Button
                    sx={{
                      backgroundColor: "white !important",
                      color: "#235d5e",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    onClick={() => navigate("/blogdetail")}
                  >
                    {t("blogs.readMore")}
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box className="blogCard">
              <Box className="imageIcon">
                <img src={BlogImage} />
              </Box>
              <Box className="blogText">
                <Typography className="heading">
                  Pharmacist-to-Pharmacist: A New Way to Boost Your Income and
                  Cut Costs
                  {/* {t("blogs.cardTitle")} */}
                </Typography>
                <Typography className="title">
                  The pharmacy world is abuzz with a new trend that's
                  revolutionizing the way we think about surplus medications.
                  Instead of viewing excess stock as a burden, innovative
                  pharmacists are seeing it as an opportunity. Welcome to the
                  Pharmacist-to-Pharmacist exchange!
                  {/* {t("blogs.cardDesc")} */}
                </Typography>

                <Box className="cardBtn">
                  <Button
                    sx={{
                      backgroundColor: "white !important",
                      color: "#235d5e",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    onClick={() => navigate("/blogdetail")}
                  >
                    {t("blogs.readMore")}
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box className="blogCard">
              <Box className="imageIcon">
                <img src={BlogImage} />
              </Box>
              <Box className="blogText">
                <Typography className="heading">
                  Why You Should Sell Medication to Other Pharmacists (and How
                  to Do It)
                  {/* {t("blogs.cardTitle")} */}
                </Typography>
                <Typography className="title">
                  In today's fast-paced pharmacy landscape, we're always seeking
                  ways to optimize operations and improve our bottom line. A
                  game-changer in this endeavor? Selling medication directly to
                  fellow pharmacists. If you haven't considered this approach,
                  here's why you should, and a guide on how to get started.
                  {/* {t("blogs.cardDesc")} */}
                </Typography>

                <Box className="cardBtn">
                  <Button
                    sx={{
                      backgroundColor: "white !important",
                      color: "#235d5e",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    onClick={() => navigate("/blogdetail")}
                  >
                    {t("blogs.readMore")}
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box className="blogCard">
              <Box className="imageIcon">
                <img src={BlogImage} />
              </Box>
              <Box className="blogText">
                <Typography className="heading">
                  The Ultimate Guide to Pharmacist-to-Pharmacist Drug Sales
                  {t("blogs.cardTitle")}
                </Typography>
                <Typography className="title">
                  Ever wondered if there's a smarter, more efficient way to
                  manage surplus medications while also enhancing your revenue?
                  Enter the world of Pharmacist-to-Pharmacist drug sales, a
                  trend that is rapidly redefining the pharmacy ecosystem. Dive
                  into our ultimate guide on how to navigate this innovative
                  space.
                  {/* {t("blogs.cardDesc")} */}
                </Typography>

                <Box className="cardBtn">
                  <Button
                    sx={{
                      backgroundColor: "white !important",
                      color: "#235d5e",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    onClick={() => navigate("/blogdetail")}
                  >
                    {t("blogs.readMore")}
                  </Button>
                </Box>
              </Box>
            </Box>
            {/* <Box className="blogCard">
              <Box className="imageIcon">
                <img src={BlogImage} />
              </Box>
              <Box className="blogText">
                <Typography className="heading">
                  {t("blogs.cardTitle")}
                </Typography>
                <Typography className="title">{t("blogs.cardDesc")}</Typography>

                <Box className="cardBtn">
                  <Button
                    sx={{
                      backgroundColor: "white !important",
                      color: "#235d5e",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    onClick={() => navigate("/blogdetail")}
                  >
                    {t("blogs.readMore")}
                  </Button>
                </Box>
              </Box>
            </Box> */}
          </Box>
        </Grid>
      </Container>
      <NewsLetter />
      <Footer />
    </Container>
  );
};
export default Blog;
