import React from "react";
import Box from "@mui/material/Box";
import ResponsiveAppBar from "./businessHeader";
import "./business.scss";
import Star from "../../assets/businessPage/Star.svg";
import Illustration from "../../assets/businessPage/Illsituration.png";
import MaskMan from "../../assets/businessPage/MaskMan.png";
import FooterImg from "../../assets/businessPage/Footer.png";
import { Stack, Typography } from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import logo from "../../assets/images/newLogo.svg";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/system";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import FaqResponsiveHeader from "./faqHeader";
import Footer from "../../shared/components/footer";
import NewsLetter from "../../shared/components/footer/newsletter";
import { useTranslation } from "react-i18next";
import { HelmetProvider } from "react-helmet-async";

const About = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <Container>
      <ResponsiveAppBar />
      <Container maxWidth={false} className="uniorxLandingPageContainer">
        <Box>
          <Typography
            fontSize={{ xs: "30px", sm: "36px", md: "48px" }}
            fontWeight="700"
            mt={3}
            mb={3}
            color={"#325C5D"}
            textAlign="center"
          >
            {t("aboutUs.about")}
          </Typography>
        </Box>
        <Box className="section-main" textAlign="center">
          <img src={MaskMan} alt="Mask man" />
        </Box>

        <Box
          sx={{
            flexDirection: { xs: "column", md: "row" },
            padding: { xs: "0px 20px", md: "0px" },
          }}
          display="flex"
          justifyContent="space-around"
          width="100%"
          mt={{ xs: 4, sm: 8, md: 10 }}
        >
          <Typography
            sx={{ fontSize: { xs: "28px", sm: "34px", md: "38px" } }}
            fontWeight="700"
            color={"#101828"}
          >
            {t("aboutUs.whoAreWe")}
          </Typography>
          <Box marginTop="10px" sx={{ width: { xs: "100%", md: "48.9%" } }}>
            <Typography fontSize={18} color="#101828" textAlign="justify">
              {t("aboutUs.paragraph1")}
            </Typography>
            <Typography
              fontSize={18}
              color="#101828"
              mt={2}
              mb={2}
              textAlign="justify"
            >
              {t("aboutUs.paragraph2")}
            </Typography>
            <Typography
              fontSize={18}
              color="#101828"
              mt={2}
              mb={5}
              textAlign="justify"
            >
              {t("aboutUs.paragraph3")}
            </Typography>
          </Box>
        </Box>
        <hr />

        <Box
          sx={{
            flexDirection: { xs: "column", md: "row" },
            padding: { xs: "0px 20px", md: "0px" },
          }}
          display="flex"
          justifyContent="space-around"
          width="100%"
          mt={7}
        >
          <Typography
            sx={{ fontSize: { xs: "28px", sm: "34px", md: "38px" } }}
            fontWeight="700"
            color={"#101828"}
          >
            {t("aboutUs.ourApproach")}
          </Typography>

          <Box marginTop="10px" sx={{ width: { xs: "100%", md: "48.9%" } }}>
            <Typography fontSize={18} color="#101828" textAlign="justify">
              {t("aboutUs.paragraph4")}
            </Typography>
            <Typography
              fontSize={18}
              color="#101828"
              mt={2}
              mb={2}
              textAlign="justify"
            >
              {t("aboutUs.paragraph5")}
            </Typography>
            <Typography
              fontSize={18}
              color="#101828"
              mt={2}
              mb={2}
              textAlign="justify"
            >
              {t("aboutUs.paragraph6")}
            </Typography>
            <Typography
              fontSize={18}
              color="#101828"
              mt={2}
              mb={2}
              textAlign="justify"
            >
              {t("aboutUs.paragraph7")}
            </Typography>
          </Box>
        </Box>

        <Box className="section_three">
          <Box>
            <Typography
              fontSize="42px"
              fontWeight="700"
              mb={3}
              color="#ffff"
              className="font_mbl"
            >
              {t("aboutUs.whyUnioRX")}
            </Typography>
            <Typography
              fontSize="32px"
              fontWeight="700"
              color="#ffff"
              mb={3}
              className="saving_text"
            >
              {t("landingPage.bulletPoints")}
            </Typography>
            <ul>
              <li>
                <Typography
                  color="#ffff"
                  fontWeight="500"
                  display="flex"
                  alignItems="start"
                >
                  <img src={Star} alt="star" height="20px" width="20px" />
                  {t("landingPage.bp1")}
                </Typography>
              </li>
              <li>
                <Typography
                  color="#ffff"
                  fontWeight="500"
                  display="flex"
                  alignItems="start"
                >
                  <img src={Star} alt="star" height="20px" width="20px" />
                  {t("landingPage.bp2")}
                </Typography>
              </li>
              <li>
                <Typography
                  color="#ffff"
                  fontWeight="500"
                  display="flex"
                  alignItems="start"
                >
                  <img src={Star} alt="star" height="20px" width="20px" />
                  {t("landingPage.bp3")}
                </Typography>
              </li>
              <li>
                <Typography
                  color="#ffff"
                  fontWeight="500"
                  display="flex"
                  alignItems="start"
                >
                  <img src={Star} alt="star" height="20px" width="20px" />
                  {t("landingPage.bp4")}
                </Typography>
              </li>
              <li>
                <Typography
                  color="#ffff"
                  fontWeight="500"
                  display="flex"
                  alignItems="start"
                >
                  <img src={Star} alt="star" height="20px" width="20px" />
                  {t("landingPage.bp5")}
                </Typography>
              </li>
            </ul>
          </Box>
          <Box className="tablet_img">
            <img src={Illustration} alt="alt" />
          </Box>
        </Box>
      </Container>
      <NewsLetter />
      <Footer />
    </Container>
  );
};

export default About;
