import { Box, Card, Grid, Typography } from "@mui/material";
import { FilterContext } from "../../context/FilterContext";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import { prodFilterName } from "../../helpers/common";

const CategoryLists = ({ prod, listBulleted }) => {
  const [filterState, setFilterState] = useContext(FilterContext);
  const history = useNavigate();
  const handleCategoryRedirect = (category) => {
    history(`/productlisting`);
    let selectedFilters = [];
    selectedFilters.push({
      label: "category",
      value: category,
      type: "selectedCategories",
    });

    setFilterState({
      ...filterState,
      selectedCategories: [category],
      selectedFilters,
    });
  };

  return (
    <>
      {!listBulleted && (
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2.4}
          onClick={() => handleCategoryRedirect(prod?.title)}
        >
          <Card raised className="category-card">
            <Grid item xs={12}>
              <Box className="carousel-img-container-category">
                <img src={prod?.imageCover} />
              </Box>
            </Grid>
            <Box pt={1}>
              <Typography
                className="category-text text-ellipses"
                title={prodFilterName(prod?.title)}
              >
                {prodFilterName(prod?.title)}
              </Typography>
            </Box>
          </Card>
        </Grid>
      )}

      {listBulleted && (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ padding: "0px 5px 15px 5px" }}
            onClick={() => handleCategoryRedirect(prod?.title)}
          >
            <Box
              sx={{
                background: "#fff",
                borderRadius: "8px",
                overflow: "hidden",
                boxShadow: "0px 0px 7px 1px rgb(115 102 255 / 12%)",
                cursor: "pointer",
              }}
            >
              <Box display="flex">
                <Box pl={3} className="carousel-img-container-category">
                  <img src={prod?.imageCover} />
                </Box>
                <Box
                  alignSelf="center"
                  display="flex"
                  width="100%"
                  justifyContent="center"
                >
                  <Typography
                    className="category-text text-ellipses"
                    title={prodFilterName(prod?.title)}
                  >
                    {prodFilterName(prod?.title)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </>
      )}
    </>
  );
};

export default CategoryLists;
