import React from "react";
import "./footer.scss";
import Box from "@mui/material/Box";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import logo from "../../../assets/images/newLogo.svg";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import { useLocation, useNavigate } from "react-router";
import { Container } from "@mui/system";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <Container sx={{ padding: "20px !important" }}>
      <Grid container spacing={3} mt={2}>
        <Grid item md={4} sm={12} xs={12} lg={4}>
          <Box mb={3}>
            <Box
              className="headerLogoContainer"
              onClick={() => navigate("/marketplace")}
            >
              <img src={logo} className="logo" alt="logo" />
              <Typography className="logoText">UnioRx</Typography>
            </Box>
          </Box>
          <Typography component="div">{t("footer.title")}</Typography>
        </Grid>
        <Grid item md={4} sm={4} xs={6} lg={4}>
          <List
            component="nav"
            subheader={
              <ListSubheader component="div" className="nav-heading">
                {t("footer.quickLinks")}
              </ListSubheader>
            }
          >
            <ListItemButton
              sx={{ paddingTop: "0px" }}
              onClick={() => navigate("/about-us")}
            >
              <ListItemText
                primary={t("footer.aboutUs")}
                className="footer-sub-heading"
              />
            </ListItemButton>
            <ListItemButton
              sx={{ paddingTop: "0px" }}
              onClick={() => navigate("/contact-us")}
            >
              <ListItemText
                primary={t("footer.contact")}
                className="footer-sub-heading"
              />
            </ListItemButton>
            <ListItemButton
              sx={{ paddingTop: "0px" }}
              onClick={() => navigate("/privacy-policy")}
            >
              <ListItemText
                primary={t("footer.privacyPolicy")}
                className="footer-sub-heading"
              />
            </ListItemButton>
            <ListItemButton
              sx={{ paddingTop: "0px" }}
              onClick={() => navigate("/term-&-conditions")}
            >
              <ListItemText
                primary={t("footer.termsService")}
                className="footer-sub-heading"
              />
            </ListItemButton>
          </List>
        </Grid>
        <Grid item md={4} sm={3} xs={6} lg={4}>
          <List
            component="nav"
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                className="nav-heading"
              >
                {t("footer.company")}
              </ListSubheader>
            }
          >
            <ListItemButton
              sx={{ paddingTop: "0px" }}
              onClick={() =>
                window.open(
                  "https://www.facebook.com/profile.php?id=61552489185918"
                )
              }
            >
              <ListItemText primary="Facebook" className="footer-sub-heading" />
            </ListItemButton>
            <ListItemButton
              sx={{ paddingTop: "0px" }}
              onClick={() => window.open(" https://www.instagram.com/unio_rx/")}
            >
              <ListItemText
                primary="Instagram"
                className="footer-sub-heading"
              />
            </ListItemButton>
            <ListItemButton
              sx={{ paddingTop: "0px" }}
              onClick={() => window.open("https://twitter.com/RxUnio72850")}
            >
              <ListItemText primary="Twitter" className="footer-sub-heading" />
            </ListItemButton>
            <ListItemButton
              sx={{ paddingTop: "0px" }}
              onClick={() =>
                window.open("https://www.linkedin.com/company/101019391/")
              }
            >
              <ListItemText primary="Linkedin" className="footer-sub-heading" />
            </ListItemButton>
            {/* <ListItemButton
              sx={{ paddingTop: "0px" }}
              onClick={() => navigate("/approach")}
            >
              <ListItemText
                primary={t("footer.ourApproach")}
                className="footer-sub-heading"
              />
            </ListItemButton> */}
            {/* <ListItemButton
              sx={{ paddingTop: "0px" }}
              onClick={() => navigate("/testimonial")}
            >
              <ListItemText
                primary={t("footer.testimonial")}
                className="footer-sub-heading"
              />
            </ListItemButton> */}
            {/* <ListItemButton
              sx={{ paddingTop: "0px" }}
              onClick={() => navigate("/privacy-policy")}
            >
              <ListItemText
                primary={t("footer.privacyPolicy")}
                className="footer-sub-heading"
              />
            </ListItemButton> */}
            {/* <ListItemButton
              sx={{ paddingTop: "0px" }}
              onClick={() => navigate("/whyUs")}
            >
              <ListItemText
                primary={t("footer.whyus")}
                className="footer-sub-heading"
              />
            </ListItemButton> */}
            <ListItemButton
              sx={{ paddingTop: "0px" }}
              onClick={() => navigate("/faq")}
            >
              <ListItemText
                primary={t("footer.fAQ")}
                className="footer-sub-heading"
              />
            </ListItemButton>
          </List>
        </Grid>
        <Grid item md={2} sm={2} xs={6} lg={2}>
          {/* <List
            component="nav"
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                className="nav-heading"
              >
                {t("footer.learn")}
              </ListSubheader>
            }
          > */}
          {/* <ListItemButton sx={{ paddingTop: "0px" }}>
              <ListItemText
                primary={t("footer.articles")}
                className="footer-sub-heading"
              />
            </ListItemButton>
            <ListItemButton sx={{ paddingTop: "0px" }}>
              <ListItemText
                primary={t("footer.pressMedia")}
                className="footer-sub-heading"
              />
            </ListItemButton>
            <ListItemButton sx={{ paddingTop: "0px" }}>
              <ListItemText
                primary={t("footer.shows")}
                className="footer-sub-heading"
              />
            </ListItemButton> */}
          {/* </List> */}
        </Grid>
        {/* <Grid item md={2} sm={3} xs={6} lg={2}> */}
        {/* <List
            component="nav"
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                className="nav-heading"
              >
                {t("footer.followUs")}
              </ListSubheader>
            }
          >
           
          </List> */}
        {/* </Grid> */}
        <Grid item xs={12} sx={{ textAlign: "center" }} pt={"0 !important"}>
          <hr className="footer-line" />
          <Box component="div">
            <Typography variant="caption" className="footer-sub-heading">
              {t("footer.rightsReserved")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
// PR
