import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import { Container } from "@mui/system";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import nxusLogo from "../../assets/businessPage/Group.svg";
import { useState } from "react";
import Vactor from "../../assets/businessPage/Vactor.svg";
import LanguageToggle from "../../../LanguageToggle";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function FaqResponsiveHeader() {
  const { t } = useTranslation();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const { authenticated } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const darkTheme = createTheme({
    shadows: "none",
    color: "#333",
    palette: {
      primary: {
        main: "#FFFFFF00",
      },
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar position="static" sx={{ background: "#235d5e" }}>
        <Box
          className="VactorCus"
          sx={{
            backgroundImage: `url(${Vactor})`,
          }}
        >
          <Container
            maxWidth={false}
            sx={{ maxWidth: "100%", padding: "0px " }}
          >
            <Toolbar
              sx={{
                justifyContent: "space-between",
                width: "100%",
                padding: "0px !important",
              }}
            >
              <Box
                className="landingPageheader1"
                onClick={() => {
                  navigate("/");
                }}
                sx={{ cursor: "pointer" }}
              >
                <img src={nxusLogo} />
                <Typography
                  href="/marketplace"
                  sx={{ color: "#ffff !important", marginBottom: "8px" }}
                  className="logoText"
                >
                  UnioRx
                </Typography>
              </Box>

              {/* <Box sx={{ display: { xs: "block", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  sx={{ color: "white !important" }}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  <MenuItem onClick={() => navigate("/products")}>
                    <Typography
                      sx={{ color: "#ffff !important" }}
                      textAlign="center"
                    >
                      {t("header.products")}
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/pricing")}>
                    <Typography
                      sx={{ color: "#ffff !important" }}
                      textAlign="center"
                    >
                      {t("header.pricing")}
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/blog")}>
                    <Typography
                      sx={{ color: "#ffff !important" }}
                      textAlign="center"
                    >
                      {t("header.blog")}
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box> */}

              <Box
                className="landingPageheader"
                onClick={() => {
                  navigate("/");
                }}
                sx={{ cursor: "pointer" }}
              >
                <img src={nxusLogo} />
                <Typography
                  href="/marketplace"
                  className="logoText"
                  sx={{ display: { xs: "none", sm: "flex" } }}
                >
                  UnioRx
                </Typography>
              </Box>

              {/* <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <Button
                  onClick={() => navigate("/products")}
                  sx={{
                    padding: "6px 16px",
                    color: "#ffff",
                    display: "block",
                    textTransform: "capitalize",
                    fontSize: "14px",
                  }}
                >
                  {t("header.products")}
                </Button>
                <Button
                  onClick={() => navigate("/pricing")}
                  sx={{
                    padding: "6px 16px",
                    color: "#ffff",
                    display: "block",
                    textTransform: "capitalize",
                    fontSize: "14px",
                  }}
                >
                  {t("header.pricing")}
                </Button>
                <Button
                  onClick={() => navigate("/blog")}
                  sx={{
                    padding: "6px 16px",
                    color: "#ffff",
                    display: "block",
                    textTransform: "capitalize",
                    fontSize: "14px",
                  }}
                >
                  {t("header.blog")}
                </Button>
              </Box> */}
              <Box display="flex">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "6px",
                    textTransform: "capitalize",
                    fontSize: "14px",
                    border: "1px solid #ffff",
                    marginRight: "20px",
                    color: "#ffff",
                    padding: {
                      xs: "0px 10px !important",
                      sm: "7px 24px !important",
                    },
                  }}
                  onClick={() => {
                    if (authenticated && authenticated?.email) {
                      navigate("/login");
                    } else {
                      navigate("/authenticate");
                    }
                  }}
                >
                  {t("header.login")}
                </Button>
                <LanguageToggle
                  className={"contactUs-language-toggle"}
                  langColor="#ffff"
                />
              </Box>
            </Toolbar>
          </Container>
        </Box>
      </AppBar>
    </ThemeProvider>
  );
}
export default FaqResponsiveHeader;
