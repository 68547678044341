import * as Yup from "yup";

export const initialValues = {
  email: "",
  password: "",
  token: "",
};
export const Schema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  password: Yup.string().max(255).required("Password is required"),
  token: Yup.string().max(4),
});

export const queriesInitialValues = {
  first_name: "",
  last_name: "",
  email: "",
  location: "",
  mobile_no: "",
  query: "",
};

export const QueriesSchema = Yup.object().shape({
  first_name: Yup.string().max(255),
  last_name: Yup.string().max(255),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  location: Yup.string().max(700),
  mobile_no: Yup.string().max(255),
  query: Yup.string().max(255),
});
