import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Footer from "../../shared/components/footer";
import {
  Card,
  Grid,
  List,
  TextField,
  ListSubheader,
  ListItemButton,
  ListItemText,
  Button,
  IconButton,
  Stack,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import logo from "../../assets/images/newLogo.svg";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import FaqResponsiveHeader from "./faqHeader";
import Icon1 from "../../assets/businessPage/Button1.svg";
import Icon2 from "../../assets/businessPage/Button2.svg";
import Icon3 from "../../assets/businessPage/Button3.svg";
import Icon4 from "../../assets/businessPage/Button4.svg";
import Rectangle from "../../assets/businessPage/rectangle.png";
import Vector42 from "../../assets/businessPage/Vector42.svg";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phoneNumber: Yup.string().required("Phone Number is required"),
  message: Yup.string().required("Message is required"),
});

const ContactUs = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  };

  const handleSubmit = (values) => {
    console.log("values", values);
  };

  const cardList = [
    {
      icon: (
        <img src={Icon1} style={{ height: "40px", marginBottom: "30px" }} />
      ),
      title: "contactUs.Product",
      detail: "contactUs.Details",
      email: "contactUs.email1",
    },
    {
      icon: (
        <img src={Icon2} style={{ height: "40px", marginBottom: "30px" }} />
      ),

      title: "contactUs.Sales",
      detail: "contactUs.Details",
      email: "contactUs.email2",
    },
    ,
    {
      icon: (
        <img src={Icon3} style={{ height: "40px", marginBottom: "30px" }} />
      ),
      title: "contactUs.Partnerships",
      detail: "contactUs.Details",
      email: "contactUs.email3",
    },
    ,
    {
      icon: (
        <img src={Icon4} style={{ height: "40px", marginBottom: "30px" }} />
      ),
      title: "contactUs.Help",
      detail: "contactUs.Details",
      email: "contactUs.email4",
    },
  ];

  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <Container>
      <FaqResponsiveHeader />
      <Container maxWidth={false} className="uniorxLandingPageContainer">
        <Box
          className="VactorCus"
          sx={{
            backgroundImage: `url(${Rectangle})`,
          }}
        >
          <Typography
            color="#ffff"
            className="mainHeading"
            sx={{
              paddingTop: "20px !important",
              paddingBottom: "300px !important",
            }}
          >
            {t("contactUs.title")}
          </Typography>
        </Box>

        <Grid
          container
          spacing={{ xs: 2, lg: 4 }}
          marginTop="-230px !important"
          sx={{ padding: { xs: "0px 10px", sm: "0px 40px" } }}
        >
          {cardList?.map((el, index) => {
            const cardStyles = {
              borderRadius: "10px",
              boxShadow: "0px 2px 30px rgba(0, 0, 0, 0.2) !important",
              marginRight: "20px",
              padding: "15px 20px",
              minWidth: "100%",
              maxWidth: "100%",
            };

            const titleStyles = {
              fontSize: "18px",
              fontWeight: "500",
              marginBottom: "5px",
            };

            const typographyStyles = {
              fontSize: "14px",
              fontWeight: "400",
              marginBottom: "30px",
            };
            const emailStyles = {
              fontSize: "14px",
              fontWeight: "400",
              marginBottom: "20px",
            };

            if (index === 0) {
              cardStyles.border = "1px solid green";
              cardStyles.backgroundColor = "#235d5e !important";
              typographyStyles.color = "white !important";
              emailStyles.color = "white !important";
              titleStyles.color = "white";
            } else {
              cardStyles.border = "1px solid white";
              cardStyles.backgroundColor = "white !important";
              typographyStyles.color = "black !important";
              emailStyles.color = "#03AD54 !important";
              titleStyles.color = "black";
            }

            return (
              <Grid item xs={12} sm={6} md={3} lg={3}>
                {/* <Card raised sx={cardStyles}>
                  {el?.icon}
                  <Typography sx={titleStyles}>{t(el?.title)}</Typography>
                  <Typography sx={typographyStyles}>{t(el?.detail)}</Typography>
                  <Typography sx={emailStyles}>{t(el?.email)}</Typography>
                </Card> */}
              </Grid>
            );
          })}
        </Grid>

        <Box sx={{ padding: { xs: "0px 8px", md: "0px 60px" } }}>
          <Box
            mt={31}
            mb={4}
            borderRadius="10px"
            boxShadow="0px 2px 30px rgba(0, 0, 0, 0.2) !important"
          >
            <Grid container spacing={2} padding="8px">
              <Grid
                item
                xs={12}
                md={6}
                sm={6}
                lg={5}
                sx={{ paddingTop: "0px !important" }}
              >
                <Box
                  className="VactorCus"
                  sx={{
                    backgroundImage: `url(${Rectangle})`,
                    borderRadius: "10px",
                    padding: "40px 30px",
                  }}
                >
                  <Typography
                    color="#FFF"
                    fontSize="24px"
                    fontWeight="500"
                    pb={1}
                  >
                    {t("contactUs.Product")}
                  </Typography>
                  <Typography
                    color="rgba(255, 255, 255, 0.75)"
                    fontSize="16px"
                    fontWeight="300"
                    pb={8}
                  >
                    {t("contactUs.canHelp")}
                  </Typography>
                  <Stack direction={"column"} gap={6}>
                    <Box display="flex" width="100%" alignItems="center">
                      {/* <IconButton sx={{ padding: "0px" }}>
                        <PhoneIcon sx={{ color: "#fff" }} />
                      </IconButton> */}

                      {/* <Typography
                        variant="span"
                        color="#FFF"
                        fontSize="16px"
                        fontWeight="300"
                        pl={3}
                      >
                        +1 012 345 9908
                      </Typography> */}
                    </Box>
                    <Box display="flex" width="100%" alignItems="center">
                      <IconButton sx={{ padding: "0px" }}>
                        <EmailIcon sx={{ color: "#fff" }} />
                      </IconButton>
                      <Typography
                        variant="span"
                        color="#FFF"
                        fontSize="16px"
                        fontWeight="300"
                        pl={3}
                      >
                        hello@uniorx.com
                      </Typography>
                    </Box>
                    <Box display="flex" width="100%" alignItems="center">
                      {/* <IconButton sx={{ padding: "0px" }}>
                        <LocationOnIcon sx={{ color: "#fff" }} />
                      </IconButton> */}
                      {/* <Typography
                        variant="span"
                        color="#FFF"
                        fontSize="16px"
                        fontWeight="300"
                        pl={3}
                      >
                        50 Something Address, Calgary Alberta, Canada L08 334
                      </Typography> */}
                    </Box>
                  </Stack>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sm={6}
                lg={7}
                sx={{
                  paddingTop: "0px !important",
                }}
              >
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched, values }) => (
                    <form>
                      <Grid container spacing={2} pl={4} pr={4} pt={4} pb={1}>
                        <Grid item xs={12} sm={12} md={6}>
                          <Field
                            as={TextField}
                            name="firstName"
                            label={t("contactUs.firstName")}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            error={touched.firstName && !!errors.firstName}
                            helperText={touched.firstName && errors.firstName}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <Field
                            as={TextField}
                            name="lastName"
                            label={t("contactUs.lastName")}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            error={touched.lastName && !!errors.lastName}
                            helperText={touched.lastName && errors.lastName}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <Field
                            as={TextField}
                            name="email"
                            label="Email"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            error={touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <Field
                            as={TextField}
                            name="phoneNumber"
                            label={t("Signup.phoneNumber")}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            error={touched.phoneNumber && !!errors.phoneNumber}
                            helperText={
                              touched.phoneNumber && errors.phoneNumber
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            name="message"
                            label="Message"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            margin="normal"
                            error={touched.message && !!errors.message}
                            helperText={touched.message && errors.message}
                          />
                        </Grid>
                        <Box marginLeft="auto">
                          <Button
                            className="containedPrimary"
                            variant="contained"
                            size="small"
                            onClick={() => {
                              handleSubmit(values);
                            }}
                            sx={{
                              padding: "8px 16px !important",
                              marginTop: "48px",
                            }}
                          >
                            {t("contactUs.senMessage")}
                          </Button>
                        </Box>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box
          height="100%"
          widt="100%"
          textAlign="center"
          className="VactorCus"
          sx={{
            backgroundImage: `url(${Rectangle})`,
            paddingBottom: "100px",
            paddingTop: "60px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <Typography fontSize="28px" font-Weight="500" color="#fff">
            {t("contactUs.FindOutMore")}
          </Typography>
          <Grid container spacing={3} mt={2} justifyContent="center">
            <Grid
              onClick={() => navigate(`/about-us`, { replace: true })}
              item
              md={3}
              sm={6}
              xs={12}
              lg={3}
            >
              <Box border="1px solid #03AD54" borderRadius="10px" p="16px 0px">
                <Typography
                  fontSize="20px"
                  font-Weight="500"
                  color="#fff"
                  mb={1.5}
                >
                  {t("footer.aboutUs")}
                </Typography>
                <Typography
                  fontSize="15px"
                  font-Weight="400"
                  color="#fff"
                  mb={1.5}
                >
                  {t("contactUs.subtitle1")}
                </Typography>
                <Typography
                  font-Weight="400"
                  fontSize="15px"
                  color="#fff"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {t("landingPage.learnMore")}
                  <ArrowForwardIcon sx={{ fontSize: "18px" }} />
                </Typography>
              </Box>
            </Grid>
            <Grid item md={3} sm={6} xs={12} lg={3}>
              <Box p="16px 0px">
                <Typography
                  fontSize="20px"
                  font-Weight="500"
                  color="#fff"
                  mb={1.5}
                >
                  {t("contactUs.Newsroom")}
                </Typography>
                <Typography
                  fontSize="15px"
                  font-Weight="400"
                  color="#fff"
                  mb={1.5}
                >
                  {t("contactUs.subtitle2")}
                </Typography>
                <Typography
                  font-Weight="400"
                  fontSize="15px"
                  color="#fff"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {t("landingPage.stayTuned")}
                  <ArrowForwardIcon sx={{ fontSize: "18px" }} />
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              md={0.5}
              sm={0}
              xs={12}
              lg={0.5}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                },
              }}
            >
              <img src={Vector42} height="150px" />
            </Grid>
            <Grid
              onClick={() => navigate(`/blogs`, { replace: true })}
              item
              md={3}
              sm={12}
              xs={12}
              lg={3}
            >
              <Box p="16px 0px">
                <Typography
                  fontSize="20px"
                  font-Weight="500"
                  color="#fff"
                  mb={1.5}
                >
                  Blog
                </Typography>
                <Typography
                  fontSize="15px"
                  font-Weight="400"
                  color="#fff"
                  mb={1.5}
                >
                  {t("contactUs.subtitle3")}
                </Typography>
                <Typography
                  font-Weight="400"
                  fontSize="15px"
                  color="#fff"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {t("landingPage.learnMore")}{" "}
                  <ArrowForwardIcon sx={{ fontSize: "18px" }} />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </Container>
  );
};
export default ContactUs;
