import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const withDynamicMeta = (WrappedComponent, metaConfig) => {
  const WithDynamicMeta = () => {
    const location = useLocation();

    useEffect(() => {
      const currentPath = location.pathname;
      const metaInfo = metaConfig[currentPath] || {}; // Check if there's a configuration for the current path

      document.title = metaInfo.title || "UnioRx";
      let metaDescription = document.querySelector('meta[name="description"]');
      const description = metaInfo.description || "Default Description";

      if (!metaDescription) {
        metaDescription = document.createElement("meta");
        metaDescription.setAttribute("name", "description");
        document.head.appendChild(metaDescription);
      }

      metaDescription.setAttribute("content", description);
    }, [location.pathname, metaConfig]);

    return <WrappedComponent />;
  };

  return WithDynamicMeta;
};

export default withDynamicMeta;

// import React, { useEffect } from "react";
// import { useLocation } from "react-router-dom";

// const withDynamicMeta = (WrappedComponent, metaConfig) => {
//   const WithDynamicMeta = () => {
//     const location = useLocation();

//     useEffect(() => {
//       document.title = metaConfig[location.pathname]?.title || "Default Title";
//       const metaDescription = document.querySelector(
//         'meta[name="description"]'
//       );
//       const description =
//         metaConfig[location.pathname]?.description || "Default Description";

//       if (metaDescription) {
//         metaDescription.setAttribute("content", description);
//       } else {
//         const newMetaTag = document.createElement("meta");
//         newMetaTag.setAttribute("name", "description");
//         newMetaTag.setAttribute("content", description);
//         document.head.appendChild(newMetaTag);
//       }
//     }, [location.pathname]);

//     return <WrappedComponent />;
//   };

//   return WithDynamicMeta;
// };

// export default withDynamicMeta;
