import React, { useEffect, useState } from "react";
import { Button, TextField, IconButton, InputLabel } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { Formik } from "formik";
import { initialValues, Schema } from "./helper";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../shared/components/authLayout";
import { authLoginRequest } from "../../services/BAuth";
import { ClipLoader } from "react-spinners";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import QRModal from "./QRModal";
import useDialogModal from "../../hooks/useDialogModal";
import ResendQRModal from "../ResendQR";
import { addProducts } from "../../services/cart";
import { useLocation } from "react-router-dom";
import eye from "../../assets/images/autheye.svg";
import FErrorMessage from "../../shared/components/FErrorMessage";
import { useTranslation } from "react-i18next";

const Signin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const loading = useSelector((state) => state?.auth?.pharmacyLogin?.loading);
  const navigate = useNavigate();
  const [qrImg, setQRImage] = useState("");
  const [is_qr, setIs_Qr] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [authLoading, setAuthLoading] = useState(false);
  const [ResendQRModalDialog, showResendQRModalDialog] =
    useDialogModal(ResendQRModal);
  const [QRModalDetailDialog, showPharmacyDetailDialog] =
    useDialogModal(QRModal);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleQueriesSubmit = () => {
    navigate("/qr/businesscard");
  };

  useEffect(() => {
    let products = [];
    if (typeof window !== "undefined") {
      if (localStorage.getItem("products")) {
        products = JSON.parse(localStorage.getItem("products"));
      }
      dispatch(addProducts(products));
    }
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values) => {
          setAuthLoading(true);
          dispatch(
            authLoginRequest(
              values,
              navigate,
              toast,
              function (obj) {
                if (obj.response && obj.is_qr) {
                  if (obj?.response?.data?.token != "false") {
                    setQRImage(obj?.response?.data?.token);
                    showPharmacyDetailDialog();
                    setIs_Qr(true);
                  } else {
                    setIs_Qr(true);
                  }
                } else {
                  setAuthLoading(false);
                }
              },
              location,
              function (err) {
                setAuthLoading(false);
              }
            )
          );
        }}
        validationSchema={Schema}
      >
        {(props) => (
          <>
            <AuthLayout>
              <Box>
                <Box
                  textAlign="center"
                  className="containedPrimaryAuth"
                  size="large"
                  sx={{
                    marginTop: {
                      xs: "0px",
                      lg: "-90px",
                    },
                    marginBottom: "0px",
                  }}
                  variant="contained"
                >
                  <img
                    width=" 215px"
                    height="215px"
                    onClick={handleQueriesSubmit}
                    src={
                      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJQAAACUCAYAAAB1PADUAAAAAklEQVR4AewaftIAAATBSURBVO3BQY4bSRAEwfAC//9lXx3zVECjk4NZKczwj1QtOaladFK16KRq0UnVopOqRSdVi06qFp1ULTqpWnRSteikatFJ1aKTqkUnVYtOqhZ98hKQn6TmBsgTaiYgT6i5AfKEmgnIT1LzxknVopOqRSdViz5ZpmYTkDfUTEA2AXlCzQTkCTWbgGw6qVp0UrXopGrRJ18G5Ak1TwB5Qs0E5EbNBGRScwNkArIJyBNqvumkatFJ1aKTqkWf/GXUTEAmNTdqJiBPAJnUTEAmNX+Tk6pFJ1WLTqoWffKPAfKEmgnIBGRS8y87qVp0UrXopGrRJ1+m5jdRMwF5Qs0bajap+U1OqhadVC06qVr0yTIgfxMgk5oJyKRmAjKpmYBMam6A/GYnVYtOqhadVC365CU1v5maCchPUjMBmdTcqPk/OaladFK16KRqEf6RF4BMaiYgm9TcAJnUTEBu1LwBZFJzA2RSMwHZpOabTqoWnVQtOqla9MlLaiYgN2omIJOaGyA3aiYgk5oJyATkCTWTmhsg36TmCSCTmjdOqhadVC06qVqEf+QXAzKpmYDcqJmAbFIzAblRMwG5UTMBmdT8JidVi06qFp1ULcI/8gKQSc0E5EbNJiA3am6ATGomIDdq3gDyhJoJyKRmAjKp2XRSteikatFJ1aJPfhkgb6iZgExAJjWTmgnIjZoJyKTmBsikZhOQSc03nVQtOqladFK16JOX1LwB5EbNDZAn1ExAJjWTmhsgN0DeAPKEmieATGreOKladFK16KRq0SfLgGwCcqNmAvJNQCY1N0AmNROQCcgbQG7UTGo2nVQtOqladFK16JNlap4AMql5Q80mIJOaJ9RMQJ5QswnIpGbTSdWik6pFJ1WLPnkJyKRmAnKj5gbIjZoJyI2aSc2NmgnIE2pu1ExA3gByo+abTqoWnVQtOqla9MmXqbkBcqPmBsgTQN5QMwG5AfIGkCfUTEBugExq3jipWnRSteikatEnXwbkRs0EZALyhJo3gNwAeULNDZBJzQRkUrNJzaaTqkUnVYtOqhZ98mVqJiA3at4AMqmZgExqbtRMQCY1N0AmNTdAboBMam7UTEAmNZtOqhadVC06qVr0yQ9TMwGZ1ExAbtRMat5QMwF5AsikZgKyCcikZgJyA2RS88ZJ1aKTqkUnVYvwj/yPAblRcwNkUvMEkEnNBGRSMwGZ1DwBZJOaN06qFp1ULTqpWvTJS0B+kppJzQTkBsgbQJ5QMwF5Asik5g0133RSteikatFJ1aJPlqnZBOQGyBNqngAyqflJap5QcwPkRs0bJ1WLTqoWnVQt+uTLgDyh5g01E5BJzQRkUjOpmYBMaiYgk5pJzQRkArIJyI2aTSdVi06qFp1ULfrkHwPkCSCTmgnIpOYGyI2aN4A8AWRS88ZJ1aKTqkUnVYs++cepuVEzAbkB8gaQn6Rm00nVopOqRSdViz75MjXfpOZGzRNAbtRsAnKjZgLyhJoJyDedVC06qVp0UrXok2VAfhKQSc0EZFJzo+YGyBNqJiCTmhsgbwCZ1HzTSdWik6pFJ1WL8I9ULTmpWnRSteikatFJ1aKTqkUnVYtOqhadVC06qVp0UrXopGrRSdWik6pFJ1WLTqoW/QfwsyNPX90O5QAAAABJRU5ErkJggg=="
                    }
                  />
                </Box>
                <Box display="flex" justifyContent="center">
                  <Button
                    className="contained "
                    sx={{
                      border: "1px solid #ffc107",
                      padding: "6px",
                      fontSize: "13px",
                      color: "#000000",
                      backgroundColor: "#ffc107",
                      borderRadius: "6px",
                      marginBottom: "10px",
                      textTransform: "capitalize",
                      display: "flex",
                    }}
                    onClick={() => navigate("/qr/businesscard")}
                  >
                    {t("landingPage.joinforWaiting")}
                  </Button>
                </Box>

                <Box>
                  <Typography
                    fontSize={{ lg: 34, md: 34, sm: 30, xs: 25 }}
                    sx={{
                      fontWeight: "700",
                      color: "#000000",
                    }}
                  >
                    {is_qr
                      ? t("SignIn.enterCode")
                      : t("SignIn.authenticateYourself")}
                  </Typography>
                  <Typography
                    fontSize={{ lg: 16, md: 16, sm: 14, xs: 12 }}
                    sx={{
                      fontWeight: "400",
                      color: "#70747E",
                    }}
                  >
                    {is_qr ? t("SignIn.heading3") : t("SignIn.heading2")}
                  </Typography>
                </Box>
                <form onSubmit={props.handleSubmit}>
                  <Box pt={3}>
                    {!is_qr ? (
                      <>
                        <InputLabel shrink>Email</InputLabel>
                        <TextField
                          fullWidth
                          placeholder={t("newsLetter.enterEmail")}
                          className="authfield"
                          value={props.values.email}
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                          name="email"
                          error={
                            props.touched.email && Boolean(props.errors.email)
                          }
                          type="email"
                          required
                        />
                        <FErrorMessage name="email" />
                        <Box pt={2}>
                          <InputLabel shrink>{t("Signup.password")}</InputLabel>
                        </Box>
                        <TextField
                          fullWidth
                          placeholder={t("Signup.password")}
                          value={props.values.password}
                          type={showPassword ? "text" : "password"}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          name="password"
                          className="authfield"
                          error={
                            props.touched.password &&
                            Boolean(props.errors.password)
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Box pr={1}>
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <img src={eye} />
                                    ) : (
                                      <img src={eye} />
                                    )}
                                  </IconButton>
                                </Box>
                              </InputAdornment>
                            ),
                          }}
                          required
                        />
                        <FErrorMessage name="password" />
                      </>
                    ) : (
                      <>
                        <>
                          <Box pt={2}>
                            <InputLabel shrink>
                              {t("SignIn.authenticatorCode")}
                            </InputLabel>
                          </Box>
                          <TextField
                            className="authfield"
                            fullWidth
                            placeholder={t("SignIn.authenticatorCode")}
                            value={props.values.code}
                            onBlur={props.handleBlur}
                            onChange={props.handleChange}
                            name="token"
                            type="text"
                          />
                          <FErrorMessage name="token" />
                          <Box sx={{ cursor: "pointer" }} mt={2}>
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              onClick={() => showResendQRModalDialog()}
                            >
                              {t("SignIn.didn'tGetCode")}
                            </Typography>
                          </Box>
                        </>
                      </>
                    )}
                  </Box>

                  {!is_qr && (
                    <>
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked
                              style={{
                                color: "#235D5E",
                                "&.Mui-checked": {
                                  color: "#D3DFDF",
                                },
                              }}
                            />
                          }
                          label={t("SignIn.rememberMe")}
                        />
                      </Box>
                    </>
                  )}

                  <Button
                    disabled={loading || authLoading}
                    className="containedPrimaryAuth"
                    size="large"
                    sx={{ marginTop: "16px", width: "100% !important" }}
                    variant="contained"
                    onClick={props.handleSubmit}
                  >
                    {authLoading ? (
                      <ClipLoader size={25} color="white" loading />
                    ) : (
                      "Get Authenticated"
                    )}
                  </Button>
                </form>
                <QRModalDetailDialog qrImage={qrImg} />
                <ResendQRModalDialog />
              </Box>
            </AuthLayout>
          </>
        )}
      </Formik>
    </>
  );
};

export default Signin;
