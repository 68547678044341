export default {
  GET_STARSHIP_DETAILS: "GET_STARSHIP_DETAILS",
  GET_STARSHIP_DETAILS_SUCCESS: "GET_STARSHIP_DETAILS_SUCCESS",
  GET_STARSHIP_DETAILS_ERROR: "GET_STARSHIP_DETAILS_ERROR",

  USER_SIGNUP_DETAILS_START: "USER_SIGNUP_DETAILS_START",
  USER_SIGNUP_DETAILS_SUCCESS: "USER_SIGNUP_DETAILS_SUCCESS",
  USER_SIGNUP_DETAILS_ERROR: "USER_SIGNUP_DETAILS_ERROR",

  PHARMACY_LOGIN_START: "PHARMACY_LOGIN_START",
  PHARMACY_LOGIN_SUCCESS: "PHARMACY_LOGIN_SUCCESS",
  PHARMACY_LOGIN_ERROR: "PHARMACY_LOGIN_ERROR",

  AUTH_LOGIN_START: "AUTH_LOGIN_START",
  AUTH_LOGIN_SUCCESS: "AUTH_LOGIN_SUCCESS",
  AUTH_LOGIN_ERROR: "AUTH_LOGIN_ERROR",

  AUTH_LOGOUT_START: "AUTH_LOGOUT_START",
  AUTH_LOGOUT_SUCCESS: "AUTH_LOGOUT_SUCCESS",
  AUTH_LOGOUT_ERROR: "AUTH_LOGOUT_ERROR",

  PHARMACY_SIGNUP_DETAILS_START: "PHARMACY_SIGNUP_DETAILS_START",
  PHARMACY_SIGNUP_DETAILS_SUCCESS: "PHARMACY_SIGNUP_DETAILS_SUCCESS",
  PHARMACY_SIGNUP_DETAILS_ERROR: "PHARMACY_SIGNUP_DETAILS_ERROR",

  QUERY_DETAILS_START: "QUERY_DETAILS_START",
  QUERY_DETAILS_SUCCESS: "QUERY_DETAILS_SUCCESS",
  QUERY_DETAILS_ERROR: "QUERY_DETAILS_ERROR",

  RESEND_QR_DETAILS_START: "RESEND_QR_DETAILS_START",
  RESEND_QR_DETAILS_SUCCESS: "RESEND_QR_DETAILS_SUCCESS",
  RESEND_QR_DETAILS_ERROR: "RESEND_QR_DETAILS_ERROR",

  ROOT_CATEGORY_START: "ROOT_CATEGORY_START",
  ROOT_CATEGORY_SUCCESS: "ROOT_CATEGORY_SUCCESS",
  ROOT_CATEGORY_ERROR: "ROOT_CATEGORY_ERROR",

  PRODUCT_CATEGORY_START: "PRODUCT_CATEGORY_START",
  PRODUCT_CATEGORY_SUCCESS: "PRODUCT_CATEGORY_SUCCESS",
  PRODUCT_CATEGORY_ERROR: "PRODUCT_CATEGORY_ERROR",

  CREATE_PHARMACY_PRODUCT_START: "CREATE_PHARMACY_PRODUCT_START",
  CREATE_PHARMACY_PRODUCT_SUCCESS: "CREATE_PHARMACY_PRODUCT_SUCCESS",
  CREATE_PHARMACY_PRODUCT_ERROR: "CREATE_PHARMACY_PRODUCT_ERROR",

  UPLOAD_PRODUCT_IMAGE_START: "UPLOAD_PRODUCT_IMAGE_START",
  UPLOAD_PRODUCT_IMAGE_SUCCESS: "UPLOAD_PRODUCT_IMAGE_SUCCESS",
  UPLOAD_PRODUCT_IMAGE_ERROR: "UPLOAD_PRODUCT_IMAGE_ERROR",

  UPLOAD_VERIFICATION_DOCS_START: "UPLOAD_VERIFICATION_DOCS_START",
  UPLOAD_VERIFICATION_DOCS_SUCCESS: "UPLOAD_VERIFICATION_DOCS_SUCCESS",
  UPLOAD_VERIFICATION_DOCS_ERROR: "UPLOAD_VERIFICATION_DOCS_ERROR",

  VERIFY_OTP_START: "VERIFY_OTP_START",
  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  VERIFY_OTP_ERROR: "VERIFY_OTP_ERROR",

  RESEND_VERIFY_OTP_START: "RESEND_VERIFY_OTP_START",
  RESEND_VERIFY_OTP_SUCCESS: "RESEND_VERIFY_OTP_SUCCESS",
  RESEND_VERIFY_OTP_ERROR: "RESEND_VERIFY_OTP_ERROR",

  FORGOT_PASSWORD_DETAILS_START: "FORGOT_PASSWORD_DETAILS_START",
  FORGOT_PASSWORD_DETAILS_SUCCESS: "FORGOT_PASSWORD_DETAILS_SUCCESS",
  FORGOT_PASSWORD_DETAILS_ERROR: "FORGOT_PASSWORD_DETAILS_ERROR",

  CREATE_PASSWORD_DETAILS_START: "CREATE_PASSWORD_DETAILS_START",
  CREATE_PASSWORD_DETAILS_SUCCESS: "CREATE_PASSWORD_DETAILS_SUCCESS",
  CREATE_PASSWORD_DETAILS_ERROR: "CREATE_PASSWORD_DETAILS_ERROR",

  RESET_PASSWORD_DETAILS_START: "RESET_PASSWORD_DETAILS_START",
  RESET_PASSWORD_DETAILS_SUCCESS: "RESET_PASSWORD_DETAILS_SUCCESS",
  RESET_PASSWORD_DETAILS_ERROR: "RESET_PASSWORD_DETAILS_ERROR",

  UPDATE_PASSWORD_DETAILS_START: "UPDATE_PASSWORD_DETAILS_START",
  UPDATE_PASSWORD_DETAILS_SUCCESS: "UPDATE_PASSWORD_DETAILS_SUCCESS",
  UPDATE_PASSWORD_DETAILS_ERROR: "UPDATE_PASSWORD_DETAILS_ERROR",

  UPDATE_PROFILE_DETAILS_START: "UPDATE_PROFILE_DETAILS_START",
  UPDATE_PROFILE_DETAILS_SUCCESS: "UPDATE_PROFILE_DETAILS_SUCCESS",
  UPDATE_PROFILE_DETAILS_ERROR: "UPDATE_PROFILE_DETAILS_ERROR",

  EMAIL_VERIFICATION_DETAILS_START: "EMAIL_VERIFICATION_DETAILS_START",
  EMAIL_VERIFICATION_DETAILS_SUCCESS: "EMAIL_VERIFICATION_DETAILS_SUCCESS",
  EMAIL_VERIFICATION_DETAILS_ERROR: "EMAIL_VERIFICATION_DETAILS_ERROR",

  GET_PHARMACY_PRODUCTS_START: "GET_PHARMACY_PRODUCTS_START",
  GET_PHARMACY_PRODUCTS_SUCCESS: "GET_PHARMACY_PRODUCTS_SUCCESS",
  GET_PHARMACY_PRODUCTS_ERROR: "GET_PHARMACY_PRODUCTS_ERROR",

  GET_PHARMACY_PRODUCT_DETAIL_START: "GET_PHARMACY_PRODUCT_DETAIL_START",
  GET_PHARMACY_PRODUCT_DETAIL_SUCCESS: "GET_PHARMACY_PRODUCT_DETAIL_SUCCESS",
  GET_PHARMACY_PRODUCT_DETAIL_ERROR: "GET_PHARMACY_PRODUCT_DETAIL_ERROR",

  GET_COVER_PRODUCTS_START: "GET_COVER_PRODUCTS_START",
  GET_COVER_PRODUCTS_SUCCESS: "GET_COVER_PRODUCTS_SUCCESS",
  GET_COVER_PRODUCTS_ERROR: "GET_COVER_PRODUCTS_ERROR",

  GET_PRODUCT_CATEGORY_START: "GET_PRODUCT_CATEGORY_START",
  GET_PRODUCT_CATEGORY_SUCCESS: "GET_PRODUCT_CATEGORY_SUCCESS",
  GET_PRODUCT_CATEGORY_ERROR: "GET_PRODUCT_CATEGORY_ERROR",

  GET_PRODUCT_DETAIL_START: "GET_PRODUCT_DETAIL_START",
  GET_PRODUCT_DETAIL_SUCCESS: "GET_PRODUCT_DETAIL_SUCCESS",
  GET_PRODUCT_DETAIL_ERROR: "GET_PRODUCT_DETAIL_ERROR",

  GET_SAME_PRODUCT_INVENTORY_START: "GET_SAME_PRODUCT_INVENTORY_START",
  GET_SAME_PRODUCT_INVENTORY_SUCCESS: " GET_SAME_PRODUCT_INVENTORY_SUCCESS",
  GET_SAME_PRODUCT_INVENTORY_ERROR: "GET_SAME_PRODUCT_INVENTORY_ERROR",

  GET_INVENTORY_BY_DETAIL_START: "GET_INVENTORY_BY_DETAIL_START",
  GET_INVENTORY_BY_DETAIL_SUCCESS: " GET_INVENTORY_BY_DETAIL_SUCCESS",
  GET_INVENTORY_BY_DETAIL_ERROR: "GET_INVENTORY_BY_DETAIL_ERROR",

  GET_SIMILAR_PRODUCTS_START: "GET_SIMILAR_PRODUCTS_START",
  GET_SIMILAR_PRODUCTS_SUCCESS: " GET_SIMILAR_PRODUCTS_SUCCESS",
  GET_SIMILAR_PRODUCTS_ERROR: " GET_SIMILAR_PRODUCTS_ERROR",

  ADD_PHARMACY_PRODUCT_START: "ADD_PHARMACY_PRODUCT_START",
  ADD_PHARMACY_PRODUCT_SUCCESS: "ADD_PHARMACY_PRODUCT_SUCCESS",
  ADD_PHARMACY_PRODUCT_ERROR: "ADD_PHARMACY_PRODUCT_ERROR",

  GET_PRODUCT_BY_DIN_START: "GET_PRODUCT_BY_DIN_START",
  GET_PRODUCT_BY_DIN_SUCCESS: "GET_PRODUCT_BY_DIN_SUCCESS",
  GET_PRODUCT_BY_DIN_ERROR: "GET_PRODUCT_BY_DIN_ERROR",

  UPLOAD_PHARMACY_PRODUCT_IMAGE_START: "UPLOAD_PHARMACY_PRODUCT_IMAGE_START",
  UPLOAD_PHARMACY_PRODUCT_IMAGE_SUCCESS:
    "UPLOAD_PHARMACY_PRODUCT_IMAGE_SUCCESS",
  UPLOAD_PHARMACY_PRODUCT_IMAGE_ERROR: "UPLOAD_PHARMACY_PRODUCT_IMAGE_ERROR",

  REMOVE_PHARMACY_PRODUCT_IMAGE_START: "REMOVE_PHARMACY_PRODUCT_IMAGE_START",
  REMOVE_PHARMACY_PRODUCT_IMAGE_SUCCESS:
    "REMOVE_PHARMACY_PRODUCT_IMAGE_SUCCESS",
  REMOVE_PHARMACY_PRODUCT_IMAGE_ERROR: "REMOVE_PHARMACY_PRODUCT_IMAGE_ERROR",

  OPEN_DRAWER_START: "OPEN_DRAWER_START",
  OPEN_DRAWER_SUCCESS: "OPEN_DRAWER_SUCCESS",
  OPEN_DRAWER_ERROR: "OPEN_DRAWER_ERROR",

  ADD_PRODUCTS_TO_CART_START: "ADD_PRODUCTS_TO_CART_START",
  ADD_PRODUCTS_TO_CART_SUCCESS: "ADD_PRODUCTS_TO_CART_SUCCESS",
  ADD_PRODUCTS_TO_CART_ERROR: "ADD_PRODUCTS_TO_CART_ERROR",

  ADD_CART_START: "ADD_CART_START",
  ADD_CART_SUCCESS: "ADD_CART_SUCCESS",
  ADD_CART_ERROR: "ADD_CART_ERROR",

  GET_CART_START: "GET_CART_START",
  GET_CART_SUCCESS: "GET_CART_SUCCESS",
  GET_CART_ERROR: "GET_CART_ERROR",

  GET_RESTORE_CART_START: "GET_RESTORE_CART_START",
  GET_RESTORE_CART_SUCCESS: "GET_RESTORE_CART_SUCCESS",
  GET_RESTORE_CART_ERROR: "GET_RESTORE_CART_ERROR",

  EMPTY_CART_START: "EMPTY_CART_START",
  EMPTY_CART_SUCCESS: "EMPTY_CART_SUCCESS",
  EMPTY_CART_ERROR: "EMPTY_CART_ERROR",

  UPDATE_CART_START: "UPDATE_CART_START",
  UPDATE_CART_SUCCESS: "UPDATE_CART_SUCCESS",
  UPDATE_CART_ERROR: "UPDATE_CART_ERROR",

  CREATE_PHARMACY_ORDER_START: "CREATE_PHARMACY_ORDER_START",
  CREATE_PHARMACY_ORDER_SUCCESS: "CREATE_PHARMACY_ORDER_SUCCESS",
  CREATE_PHARMACY_ORDER_ERROR: "CREATE_PHARMACY_ORDER_ERROR",
  PURCHASE_ORDER_START: "PURCHASE_ORDER_START",
  PURCHASE_ORDER_SUCCESS: "PURCHASE_ORDER_SUCCESS",
  PURCHASE_ORDER_ERROR: "PURCHASE_ORDER_ERROR",
  GET_PHARMACY_ORDER_START: "GET_PHARMACY_ORDER_START",
  GET_PHARMACY_ORDER_SUCCESS: "GET_PHARMACY_ORDER_SUCCESS",
  GET_PHARMACY_ORDER_ERROR: "GET_PHARMACY_ORDER_ERROR",
  GET_ORDER_DETAIL_START: "GET_ORDER_DETAIL_START",
  GET_ORDER_DETAIL_SUCCESS: "GET_ORDER_DETAIL_SUCCESS",
  GET_ORDER_DETAIL_ERROR: "GET_ORDER_DETAIL_ERROR",
  PURCHASE_ORDER_DETAIL_START: " PURCHASE_ORDER_DETAIL_START",
  PURCHASE_ORDER_DETAIL_SUCCESS: " PURCHASE_ORDER_DETAIL_SUCCESS",
  PURCHASE_ORDER_DETAIL_ERROR: " GET_ORDER_DETAIL_ERROR",

  GET_ADMIN_NOTIFICATIONS: "GET_ADMIN_NOTIFICATIONS",
  GET_ADMIN_NOTIFICATIONS_SUCCESS: "GET_ADMIN_NOTIFICATIONS_SUCCESS",
  GET_ADMIN_NOTIFICATIONS_ERROR: "GET_ADMIN_NOTIFICATIONS_ERROR",

  UPDATE_ADMIN_NOTIFICATIONS: "UPDATE_ADMIN_NOTIFICATIONS",
  UPDATE_ADMIN_NOTIFICATIONS_SUCCESS: "UPDATE_ADMIN_NOTIFICATIONS_SUCCESS",
  UPDATE_ADMIN_NOTIFICATIONS_ERROR: "UPDATE_ADMIN_NOTIFICATIONS_ERROR",

  GET_PHARMACY_MEMBERS: "GET_PHARMACY_MEMBERS",
  GET_PHARMACY_MEMBERS_SUCCESS: "GET_PHARMACY_MEMBERS_SUCCESS",
  GET_PHARMACY_MEMBERS_ERROR: "GET_PHARMACY_MEMBERS_ERROR",

  GET_PHARMACY_MEMBER: "GET_PHARMACY_MEMBER",
  GET_PHARMACY_MEMBER_SUCCESS: "GET_PHARMACY_MEMBER_SUCCESS",
  GET_PHARMACY_MEMBER_ERROR: "GET_PHARMACY_MEMBER_ERROR",

  UPDATE_PHARMACY_MEMBER: "UPDATE_PHARMACY_MEMBER",
  UPDATE_PHARMACY_MEMBER_SUCCESS: "UPDATE_PHARMACY_MEMBER_SUCCESS",
  UPDATE_PHARMACY_MEMBER_ERROR: "UPDATE_PHARMACY_MEMBER_ERROR",

  CREATE_PHARMACY_MEMBERS: "CREATE_PHARMACY_MEMBERS",
  CREATE_PHARMACY_MEMBERS_SUCCESS: "CREATE_PHARMACY_MEMBERS_SUCCESS",
  CREATE_PHARMACY_MEMBERS_ERROR: "CREATE_PHARMACY_MEMBERS_ERROR",

  SENT_LINK_TO_ADD_MEMBERS_START: "SENT_LINK_TO_ADD_MEMBERS_START",
  SENT_LINK_TO_ADD_MEMBERS_SUCCESS: "SENT_LINK_TO_ADD_MEMBERS_SUCCESS",
  SENT_LINK_TO_ADD_MEMBERS_ERROR: "SENT_LINK_TO_ADD_MEMBERS_ERROR",

  GET_SUBORDER_DETAIL_START: "GET_SUBORDER_DETAIL_START",
  GET_SUBORDER_DETAIL_SUCCESS: "GET_SUBORDER_DETAIL_SUCCESS",
  GET_SUBORDER_DETAIL_ERROR: "GET_SUBORDER_DETAIL_ERROR",

  UPDATE_ORDER_STATUS: "UPDATE_ORDER_STATUS",
  UPDATE_ORDER_STATUS_SUCCESS: "UPDATE_ORDER_STATUS_SUCCESS",
  UPDATE_ORDER_STATUS_ERROR: "UPDATE_ORDER_STATUS_ERROR",

  REJECT_ORDER_START: "REJECT_ORDER_START",
  REJECT_ORDER_SUCCESS: "REJECT_ORDER_SUCCESS",
  REJECT_ORDER_ERROR: "REJECT_ORDER_ERROR",

  CANCEL_ORDER_BUYER_START: "CANCEL_ORDER_BUYER_START",
  CANCEL_ORDER_BUYER_SUCCESS: "CANCEL_ORDER_BUYER_SUCCESS",
  CANCEL_ORDER_BUYER_ERROR: "CANCEL_ORDER_BUYER_ERROR",

  ACCEPT_ORDER_BUYER_START: "ACCEPT_ORDER_BUYER_START",
  ACCEPT_ORDER_BUYER_SUCCESS: "ACCEPT_ORDER_BUYER_SUCCESS",
  ACCEPT_ORDER_BUYER_ERROR: "ACCEPT_ORDER_BUYER_ERROR",

  RETURN_ORDER_START: "RETURN_ORDER_START",
  RETURN_ORDER_SUCCESS: "RETURN_ORDER_SUCCESS",
  RETURN_ORDER_ERROR: "RETURN_ORDER_ERROR",

  EDIT_PRODUCT_DETAIL_START: "EDIT_PRODUCT_DETAIL_START",
  EDIT_PRODUCT_DETAIL_SUCCESS: "EDIT_PRODUCT_DETAIL_SUCCESS",
  EDIT_PRODUCT_DETAIL_ERROR: "EDIT_PRODUCT_DETAIL_ERROR",
  GET_ALL_PRODUCT_INVENTORY_START: "GET_ALL_PRODUCT_INVENTORY_START",
  GET_ALL_PRODUCT_INVENTORY_SUCCESS: "GET_ALL_PRODUCT_INVENTORY_SUCCESS",
  GET_ALL_PRODUCT_INVENTORY_ERROR: "GET_ALL_PRODUCT_INVENTORY_ERROR",

  GET_INVENTORY_WISH_LISTS_START: "GET_INVENTORY_WISH_LISTS_START",
  GET_INVENTORY_WISH_LISTS_SUCCESS: "GET_INVENTORY_WISH_LISTS_SUCCESS",
  GET_INVENTORY_WISH_LISTS_ERROR: "GET_INVENTORY_WISH_LISTS_ERROR",

  ADD_INVENTORY_WISHLIST_START: "ADD_INVENTORY_WISHLIST_START",
  ADD_INVENTORY_WISHLIST_SUCCESS: "ADD_INVENTORY_WISHLIST_SUCCESS",
  ADD_INVENTORY_WISHLIST_ERROR: "ADD_INVENTORY_WISHLIST_ERROR",

  REMOVE_INVENTORY_WISHLIST_START: "REMOVE_INVENTORY_WISHLIST_START",
  REMOVE_INVENTORY_WISHLIST_SUCCESS: "REMOVE_INVENTORY_WISHLIST_SUCCESS",
  REMOVE_INVENTORY_WISHLIST_ERROR: "REMOVE_INVENTORY_WISHLIST_ERROR",

  DELETE_PRODUCT_INVENTORY_START: "DELETE_PRODUCT_INVENTORY_START",
  DELETE_PRODUCT_INVENTORY_SUCCESS: "DELETE_PRODUCT_INVENTORY_SUCCESS",
  DELETE_PRODUCT_INVENTORY_ERROR: "DELETE_PRODUCT_INVENTORY_ERROR",

  DELETE_PURCHASE_ORDER_START: "DELETE_PURCHASE_ORDER_START",
  DELETE_PURCHASE_ORDER_SUCCESS: "DELETE_PURCHASE_ORDER_SUCCESS",
  DELETE_PURCHASE_ORDER_ERROR: "DELETE_PURCHASE_ORDER_ERROR",

  GET_PRODUCT_INVENTORY_DETAIL_START: "GET_PRODUCT_INVENTORY_DETAIL_START",
  GET_PRODUCT_INVENTORY_DETAIL_SUCCESS: "GET_PRODUCT_INVENTORY_DETAIL_SUCCESS",
  GET_PRODUCT_INVENTORY_DETAIL_ERROR: "GET_PRODUCT_INVENTORY_DETAIL_ERROR",

  UPDATE_INVENTORY_DETAIL_START: "UPDATE_INVENTORY_DETAIL_START",
  UPDATE_INVENTORY_DETAIL_SUCCESS: "UPDATE_INVENTORY_DETAIL_SUCCESS",
  UPDATE_INVENTORY_DETAIL_ERROR: "UPDATE_INVENTORY_DETAIL_ERROR",

  ADD_PRODUCT_INVENTORY_START: "ADD_PRODUCT_INVENTORY_START",
  ADD_PRODUCT_INVENTORY_SUCCESS: "ADD_PRODUCT_INVENTORY_SUCCESS",
  ADD_PRODUCT_INVENTORY_ERROR: "ADD_PRODUCT_INVENTORY_ERROR",

  GET_CURRENT_USER_PERMISSIONS_START: "GET_CURRENT_USER_PERMISSIONS_START",
  GET_CURRENT_USER_PERMISSIONS_SUCCESS: "GET_CURRENT_USER_PERMISSIONS_SUCCESS",
  GET_CURRENT_USER_PERMISSIONS_ERROR: "GET_CURRENT_USER_PERMISSIONS_ERROR",

  GET_CURRENT_USER_PHARMACY_PERMISSIONS_START:
    "GET_CURRENT_USER_PHARMACY_PERMISSIONS_START",
  GET_CURRENT_USER_PHARMACY_PERMISSIONS_SUCCESS:
    "GET_CURRENT_USER_PHARMACY_PERMISSIONS_SUCCESS",
  GET_CURRENT_USER_PHARMACY_PERMISSIONS_ERROR:
    "GET_CURRENT_USER_PHARMACY_PERMISSIONS_ERROR",

  GET_CURRENT_USER_PHARMACIES_START: "GET_CURRENT_USER_PHARMACIES_START",
  GET_CURRENT_USER_PHARMACIES_SUCCESS: "GET_CURRENT_USER_PHARMACIES_SUCCESS",
  GET_CURRENT_USER_PHARMACIES_ERROR: "GET_CURRENT_USER_PHARMACIES_ERROR",

  ADD_BUSINESS_PHARMACY_START: "ADD_BUSINESS_PHARMACY_START",
  ADD_BUSINESS_PHARMACY_SUCCESS: "ADD_BUSINESS_PHARMACY_SUCCESS",
  ADD_BUSINESS_PHARMACY_ERROR: "ADD_BUSINESS_PHARMACY_ERROR",

  GET_ALL_PERMISSIONS_START: "GET_ALL_PERMISSIONS_START",
  GET_ALL_PERMISSIONS_SUCCESS: "GET_ALL_PERMISSIONS_SUCCESS",
  GET_ALL_PERMISSIONS_ERROR: "GET_ALL_PERMISSIONS_ERROR",

  GET_BUSINESS_NOTIFICATION_START: "GET_BUSINESS_NOTIFICATION_START",
  GET_BUSINESS_NOTIFICATION_SUCCESS: "GET_BUSINESS_NOTIFICATION_SUCCESS",
  GET_BUSINESS_NOTIFICATION_ERROR: "GET_BUSINESS_NOTIFICATION_ERROR",
  UPDATE_BUSINESS_NOTIFICATION_START: "UPDATE_BUSINESS_NOTIFICATION_START",
  UPDATE_BUSINESS_NOTIFICATION_SUCCESS: "UPDATE_BUSINESS_NOTIFICATION_SUCCESS",
  UPDATE_BUSINESS_NOTIFICATION_ERROR: "UPDATE_BUSINESS_NOTIFICATION_ERROR",

  GET_MEMBER_NOTIFICATION_START: "GET_MEMBER_NOTIFICATION_START",
  GET_MEMBER_NOTIFICATION_SUCCESS: "GET_MEMBER_NOTIFICATION_SUCCESS",
  GET_MEMBER_NOTIFICATION_ERROR: "GET_MEMBER_NOTIFICATION_ERROR",
  UPDATE_MEMBER_NOTIFICATION_START: "UPDATE_MEMBER_NOTIFICATION_START",
  UPDATE_MEMBER_NOTIFICATION_SUCCESS: "UPDATE_MEMBER_NOTIFICATION_SUCCESS",
  UPDATE_MEMBER_NOTIFICATION_ERROR: "UPDATE_MEMBER_NOTIFICATION_ERROR",

  UPDATE_BUSINESS_PROFILE_DETAILS_START:
    "UPDATE_BUSINESS_PROFILE_DETAILS_START",
  UPDATE_BUSINESS_PROFILE_DETAILS_SUCCESS:
    "UPDATE_BUSINESS_PROFILE_DETAILS_SUCCESS",
  UPDATE_BUSINESS_PROFILE_DETAILS_ERROR:
    "UPDATE_BUSINESS_PROFILE_DETAILS_ERROR",
  UPDATE_BUSINESS_PASSWORD_START: "UPDATE_BUSINESS_PASSWORD_START",
  UPDATE_BUSINESS_PASSWORD_SUCCESS: "UPDATE_BUSINESS_PASSWORD_SUCCESS",
  UPDATE_BUSINESS_PASSWORD_ERROR: "UPDATE_BUSINESS_PASSWORD_ERROR",

  UPDATE_MEMBER_PROFILE_DETAILS_START: "UPDATE_MEMBER_PROFILE_DETAILS_START",
  UPDATE_MEMBER_PROFILE_DETAILS_SUCCESS:
    "UPDATE_MEMBER_PROFILE_DETAILS_SUCCESS",
  UPDATE_MEMBER_PROFILE_DETAILS_ERROR: "UPDATE_MEMBER_PROFILE_DETAILS_ERROR",
  UPDATE_MEMBER_PASSWORD_START: "UPDATE_MEMBER_PASSWORD_START",
  UPDATE_MEMBER_PASSWORD_SUCCESS: "UPDATE_MEMBER_PASSWORD_SUCCESS",
  UPDATE_MEMBER_PASSWORD_ERROR: "UPDATE_MEMBER_PASSWORD_ERROR",
  UPLOAD_KYC_DOCUMENT_START: "UPLOAD_KYC_DOCUMENT_START",
  UPLOAD_KYC_DOCUMENT_SUCCESS: "UPLOAD_KYC_DOCUMENT_SUCCESS",
  UPLOAD_KYC_DOCUMENT_ERROR: "UPLOAD_KYC_DOCUMENT_ERROR",
  GET_KYC_DOCUMENT_START: "GET_KYC_DOCUMENT_START",
  GET_KYC_DOCUMENT_SUCCESS: "GET_KYC_DOCUMENT_SUCCESS",
  GET_KYC_DOCUMENT_ERROR: "GET_KYC_DOCUMENT_ERROR",

  GENERATE_QR_START: "GENERATE_QR_START",
  GENERATE_QR_SUCCESS: "GENERATE_QR_SUCCESS",
  GENERATE_QR_ERROR: "GENERATE_QR_ERROR",

  SCAN_QR_START: "SCAN_QR_START",
  SCAN_QR_SUCCESS: "SCAN_QR_SUCCESS",
  SCAN_QR_ERROR: "SCAN_QR_ERROR",

  ADD_SOCKETS_SUCCESS: "ADD_SOCKETS_SUCCESS",

  GENERATE_PDF_START: "GENERATE_PDF_START",
  GENERATE_PDF_SUCCESS: "GENERATE_PDF_SUCCESS",
  GENERATE_PDF_ERROR: "GENERATE_PDF_ERROR",

  GENERATE_ALL_ORDERS_PDF_START: "GENERATE_ALL_ORDERS_PDF_START",
  GENERATE_ALL_ORDERS_PDF_SUCCESS: "GENERATE_ALL_ORDERS_PDF_SUCCESS",
  GENERATE_ALL_ORDERS_PDF_ERROR: "GENERATE_ALL_ORDERS_PDF_ERROR",

  GENERATE_PRESCRIPTION_PDF_START: "GENERATE_PRESCRIPTION_PDF_START",
  GENERATE_PRESCRIPTION_PDF_SUCCESS: "GENERATE_PRESCRIPTION_PDF_SUCCESS",
  GENERATE_PRESCRIPTION_PDF_ERROR: "GENERATE_PRESCRIPTION_PDF_ERROR",

  PHARMACY_ORDER_REPORTING_START: "PHARMACY_ORDER_REPORTING_START",
  PHARMACY_ORDER_REPORTING_SUCCESS: "PHARMACY_ORDER_REPORTING_SUCCESS",
  PHARMACY_ORDER_REPORTING_ERROR: "PHARMACY_ORDER_REPORTING_ERROR",

  PHARMACY_ORDER_REPORTING_CARDS_START: "PHARMACY_ORDER_REPORTING_CARDS_START",
  PHARMACY_ORDER_REPORTING_CARDS_SUCCESS:
    "PHARMACY_ORDER_REPORTING_CARDS_SUCCESS",
  PHARMACY_ORDER_REPORTING_CARDS_ERROR: "PHARMACY_ORDER_REPORTING_CARDS_ERROR",

  PHARMACY_PURCHASE_REPORTING_START: "PHARMACY_PURCHASE_REPORTING_START",
  PHARMACY_PURCHASE_REPORTING_SUCCESS: "PHARMACY_PURCHASE_REPORTING_SUCCESS",
  PHARMACY_PURCHASE_REPORTING_ERROR: "PHARMACY_PURCHASE_REPORTING_ERROR",

  PHARMACY_MONTHLY_SALE_REPORTING_START:
    "PHARMACY_MONTHLY_SALE_REPORTING_START",
  PHARMACY_MONTHLY_SALE_REPORTING_SUCCESS:
    "PHARMACY_MONTHLY_SALE_REPORTING_SUCCESS",
  PHARMACY_MONTHLY_SALE_REPORTING_ERROR:
    "PHARMACY_MONTHLY_SALE_REPORTING_ERROR",
  BUSINESS_ORDER_REPORTING_START: "BUSINESS_ORDER_REPORTING_START",
  BUSINESS_ORDER_REPORTING_SUCCESS: "BUSINESS_ORDER_REPORTING_SUCCESS",
  BUSINESS_ORDER_REPORTING_ERROR: "BUSINESS_ORDER_REPORTING_ERROR",

  BUSINESS_ORDER_REPORTING_CARDS_START: "BUSINESS_ORDER_REPORTING_CARDS_START",
  BUSINESS_ORDER_REPORTING_CARDS_SUCCESS:
    "BUSINESS_ORDER_REPORTING_CARDS_SUCCESS",
  BUSINESS_ORDER_REPORTING_CARDS_ERROR: "BUSINESS_ORDER_REPORTING_CARDS_ERROR",

  BUSINESS_LEVEL_COUNT_START: "BUSINESS_LEVEL_COUNT_START",
  BUSINESS_LEVEL_COUNT_SUCCESS: "BUSINESS_LEVEL_COUNT_SUCCESS",
  BUSINESS_LEVEL_COUNT_ERROR: "BUSINESS_LEVEL_COUNT_ERROR",

  STORE_LEVEL_COUNT_START: "STORE_LEVEL_COUNT_START",
  STORE_LEVEL_COUNT_SUCCESS: "STORE_LEVEL_COUNT_SUCCESS",
  STORE_LEVEL_COUNT_ERROR: "STORE_LEVEL_COUNT_ERROR",

  SEARCH_PRODUCTS_START: "SEARCH_PRODUCTS_START",
  SEARCH_PRODUCTS_SUCCESS: "SEARCH_PRODUCTS_SUCCESS",
  SEARCH_PRODUCTS_ERROR: "SEARCH_PRODUCTS_ERROR",

  BUSINESS_PURCHASE_ORDER_REPORTING_START:
    "BUSINESS_PURCHASE_ORDER_REPORTING_START",
  BUSINESS_PURCHASE_ORDER_REPORTING_SUCCESS:
    "BUSINESS_PURCHASE_ORDER_REPORTING_SUCCESS",
  BUSINESS_PURCHASE_ORDER_REPORTING_ERROR:
    "BUSINESS_PURCHASE_ORDER_REPORTING_ERROR",

  BUSINESS_MONTHLY_SALE_REPORT_START: "BUSINESS_MONTHLY_SALE_REPORT_START",
  BUSINESS_MONTHLY_SALE_REPORT_SUCCESS: "BUSINESS_MONTHLY_SALE_REPORT_SUCCESS",
  BUSINESS_MONTHLY_SALE_REPORT_ERROR: "BUSINESS_MONTHLY_SALE_REPORT_ERROR",

  TOP_SELLING_PRODUCTS_START: "TOP_SELLING_PRODUCTS_START",
  TOP_SELLING_PRODUCTS_SUCCESS: "TOP_SELLING_PRODUCTS_SUCCESS",
  TOP_SELLING_PRODUCTS_ERROR: "TOP_SELLING_PRODUCTS_ERROR",

  STORE_TOP_SELLING_PRODUCTS_START: "STORE_TOP_SELLING_PRODUCTS_START",
  STORE_TOP_SELLING_PRODUCTS_SUCCESS: "STORE_TOP_SELLING_PRODUCTS_SUCCESS",
  STORE_TOP_SELLING_PRODUCTS_ERROR: "STORE_TOP_SELLING_PRODUCTS_ERROR",

  SET_ONLINE_USERS: "SET_ONLINE_USERS",
  SET_CONVERSATIONS: "SET_CONVERSATIONS",
  SET_MESSAGES: "SET_MESSAGES",
  SET_CHOOSEN_DETAILS: "SET_CHOOSEN_DETAILS",
  SET_CHOOSEN_DETAILS_COPY: "SET_CHOOSEN_DETAILS_COPY",
  SET_RECENT_CONVERSATIONS: "SET_RECENT_CONVERSATIONS",

  GET_BUSINESS_ORDER_START: "GET_BUSINESS_ORDER_START",
  GET_BUSINESS_ORDER_SUCCESS: "GET_BUSINESS_ORDER_SUCCESS",
  GET_BUSINESS_ORDER_ERROR: "GET_BUSINESS_ORDER_ERROR",

  GET_BUSINESS_PURCHASE_ORDER_START: "GET_BUSINESS_PURCHASE_ORDER_START",
  GET_BUSINESS_PURCHASE_ORDER_SUCCESS: "GET_BUSINESS_PURCHASE_ORDER_SUCCESS",
  GET_BUSINESS_PURCHASE_ORDER_ERROR: "GET_BUSINESS_PURCHASE_ORDER_ERROR",

  GET_BUSINESS_PRODUCTS_START: "GET_BUSINESS_PRODUCTS_START",
  GET_BUSINESS_PRODUCTS_SUCCESS: "GET_BUSINESS_PRODUCTS_SUCCESS",
  GET_BUSINESS_PRODUCTS_ERROR: "GET_BUSINESS_PRODUCTS_ERROR",

  ENLIST_DELIST_PRODUCTS_START: "ENLIST_DELIST_PRODUCTS_START",
  ENLIST_DELIST_PRODUCTS_SUCCESS: "ENLIST_DELIST_PRODUCTS_SUCCESS",
  ENLIST_DELIST_PRODUCTS_ERROR: "ENLIST_DELIST_PRODUCTS_ERROR",

  UPDATE_MEMBER_STATUS_START: "UPDATE_MEMBER_STATUS_START",
  UPDATE_MEMBER_STATUS_SUCCESS: "UPDATE_MEMBER_STATUS_SUCCESS",
  UPDATE_MEMBER_STATUS_ERROR: "UPDATE_MEMBER_STATUS_ERROR",

  ENLIST_DELIST_INVENTORY_START: "ENLIST_DELIST_INVENTORY_START",
  ENLIST_DELIST_INVENTORY_SUCCESS: "ENLIST_DELIST_INVENTORY_SUCCESS",
  ENLIST_DELIST_INVENTORY_ERROR: "ENLIST_DELIST_INVENTORY_ERROR",

  CANCEL_ORDER_START: "CANCEL_ORDER_START",
  CANCEL_ORDER_SUCCESS: "CANCEL_ORDER_SUCCESS",
  CANCEL_ORDER_ERROR: "CANCEL_ORDER_ERROR",

  BUYER_CANCEL_ORDER_START: "BUYER_CANCEL_ORDER_START",
  BUYER_CANCEL_ORDER_SUCCESS: "BUYER_CANCEL_ORDER_SUCCESS",
  BUYER_CANCEL_ORDER_ERROR: "BUYER_CANCEL_ORDER_ERROR",
  ACCEPT_ORDER_START: "ACCEPT_ORDER_START",
  ACCEPT_ORDER_SUCCESS: "ACCEPT_ORDER_SUCCESS",
  ACCEPT_ORDER_ERROR: "ACCEPT_ORDER_ERROR",
  PURCHASE_ORDER_SUGGESTION_START: "PURCHASE_ORDER-SUGGESTION_START",
  PURCHASE_ORDER_SUGGESTION_SUCCESS: "PURCHASE_ORDER-SUGGESTION_SUCCESS",
  PURCHASE_ORDER_SUGGESTION_ERROR: "PURCHASE_ORDER-SUGGESTION_ERROR",

  REJECT_PRODUCT_ORDER_START: "REJECT_PRODUCT_ORDER_START",
  REJECT_PRODUCT_ORDER_SUCCESS: "REJECT_PRODUCT_ORDER_SUCCESS",
  REJECT_PRODUCT_ORDER_ERROR: "REJECT_PRODUCT_ORDER_ERROR",
  ACCEPT_PRODUCT_ORDER_START: "ACCEPT_PRODUCT_ORDER_START",
  ACCEPT_PRODUCT_ORDER_SUCCESS: "ACCEPT_PRODUCT_ORDER_SUCCESS",
  ACCEPT_PRODUCT_ORDER_ERROR: "ACCEPT_PRODUCT_ORDER_ERROR",

  GET_RELATED_PRODUCTS_START: "GET_RELATED_PRODUCTS_START",
  GET_RELATED_PRODUCTS_SUCCESS: "GET_RELATED_PRODUCTS_SUCCESS",
  GET_RELATED_PRODUCTS_ERROR: "GET_RELATED_PRODUCTS_ERROR",
  PRODUCTS_LISTING_START: "PRODUCTS_LISTING_START",
  PRODUCTS_LISTING_SUCCESS: "PRODUCTS_LISTING_SUCCESS",
  PRODUCTS_LISTING_ERROR: "PRODUCTS_LISTING_ERROR",
  CATEGORIES_BRANDS_START: " CATEGORIES_BRANDS_START",
  CATEGORIES_BRANDS_SUCCESS: "CATEGORIES_BRANDS_SUCCESS",
  CATEGORIES_BRANDS_ERROR: "CATEGORIES_BRANDS_ERROR",
  GET_STORES_START: " GET_STORES_START",
  GET_STORES_SUCCESS: "GET_STORES_SUCCESS",
  GET_STORES_ERROR: "GET_STORES_ERROR",

  GET_ALL_WATCHLIST_START: "GET_ALL_WATCHLIST_START",
  GET_ALL_WATCHLIST_SUCCESS: "GET_ALL_WATCHLIST_SUCCESS",
  GET_ALL_WATCHLIST_ERROR: "GET_ALL_WATCHLIST_ERROR",

  GET_SINGLE_WATCHLIST_START: "GET_SINGLE_WATCHLIST_START",
  GET_SINGLE_WATCHLIST_SUCCESS: "GET_SINGLE_WATCHLIST_SUCCESS",
  GET_SINGLE_WATCHLIST_ERROR: "GET_SINGLE_WATCHLIST_ERROR",

  ADD_WATCHLIST_START: "ADD_WATCHLIST_START",
  ADD_WATCHLIST_SUCCESS: "ADD_WATCHLIST_SUCCESS",
  ADD_WATCHLIST_ERROR: "ADD_WATCHLIST_ERROR",

  UPDATE_WATCHLIST_START: "UPDATE_WATCHLIST_START",
  UPDATE_WATCHLIST_SUCCESS: "UPDATE_WATCHLIST_SUCCESS",
  UPDATE_WATCHLIST_ERROR: "UPDATE_WATCHLIST_ERROR",

  UPDATE_PURCHASE_ORDER_START: "UPDATE_PURCHASE_ORDER_START",
  UPDATE_PURCHASE_ORDER_SUCCESS: "UPDATE_PURCHASE_ORDER_SUCCESS",
  UPDATE_PURCHASE_ORDER_ERROR: "UPDATE_PURCHASE_ORDER_ERROR",

  DELETE_WATCHLIST_START: "DELETE_WATCHLIST_START",
  DELETE_WATCHLIST_SUCCESS: "DELETE_WATCHLIST_SUCCESS",
  DELETE_WATCHLIST_ERROR: "DELETE_WATCHLIST_ERROR",

  PHARMACIES_ORDER_APPROVE_START: "PHARMACIES_ORDER_APPROVE_START",
  PHARMACIES_ORDER_APPROVE_SUCCESS: "PHARMACIES_ORDER_APPROVE_SUCCESS",
  PHARMACIES_ORDER_APPROVE_ERROR: "PHARMACIES_ORDER_APPROVE_ERROR",

  PHARMACIES_ORDER_REJECT_START: "PHARMACIES_ORDER_REJECT_START",
  PHARMACIES_ORDER_REJECT_SUCCESS: "PHARMACIES_ORDER_REJECT_SUCCESS",
  PHARMACIES_ORDER_REJECT_ERROR: "PHARMACIES_ORDER_REJECT_ERROR",

  GET_PURCHASE_ORDERS_START: "GET_PURCHASE_ORDERS_START",
  GET_PURCHASE_ORDERS_SUCCESS: "GET_PURCHASE_ORDERS_SUCCESS",
  GET_PURCHASE_ORDERS_ERROR: "GET_PURCHASE_ORDERS_ERROR",

  GET_ALL_PRE_ORDERS_START: "GET_ALL_PRE_ORDERS_START",
  GET_ALL_PRE_ORDERS_SUCCESS: "GET_ALL_PRE_ORDERS_SUCCESS",
  GET_ALL_PRE_ORDERS_ERROR: "GET_ALL_PRE_ORDERS_ERROR",

  PRE_ORDER_DETAIL_START: "PRE_ORDER_DETAIL_START",
  PRE_ORDER_DETAIL_SUCCESS: "PRE_ORDER_DETAIL_SUCCESS",
  PRE_ORDER_DETAIL_ERROR: "PRE_ORDER_DETAIL_ERROR",

  APPROVE_PRE_ORDER_START: "APPROVE_PRE_ORDER_START",
  APPROVE_PRE_ORDER_SUCCESS: "APPROVE_PRE_ORDER_SUCCESS",
  APPROVE_PRE_ORDER_ERROR: "APPROVE_PRE_ORDER_ERROR",

  REJECT_PRE_ORDER_START: "REJECT_PRE_ORDER_START",
  REJECT_PRE_ORDER_SUCCESS: "REJECT_PRE_ORDER_SUCCESS",
  REJECT_PRE_ORDER_ERROR: "REJECT_PRE_ORDER_ERROR",

  UPDATE_PRE_ORDER_START: "UPDATE_PRE_ORDER_START",
  UPDATE_PRE_ORDER_SUCCESS: "UPDATE_PRE_ORDER_SUCCESS",
  UPDATE_PRE_ORDER_ERROR: "UPDATE_PRE_ORDER_ERROR",

  GET_ALL_BUSINESS_PRE_ORDERS_START: "GET_ALL_BUSINESS_PRE_ORDERS_START",
  GET_ALL_BUSINESS_PRE_ORDERS_SUCCESS: "GET_ALL_BUSINESS_PRE_ORDERS_SUCCESS",
  GET_ALL_BUSINESS_PRE_ORDERS_ERROR: "GET_ALL_BUSINESS_PRE_ORDERS_ERROR",

  BUSINESS_PRE_ORDER_DETAIL_START: "BUSINESS_PRE_ORDER_DETAIL_START",
  BUSINESS_PRE_ORDER_DETAIL_SUCCESS: "BUSINESS_PRE_ORDER_DETAIL_SUCCESS",
  BUSINESS_PRE_ORDER_DETAIL_ERROR: "BUSINESS_PRE_ORDER_DETAIL_ERROR",

  APPROVE_BUSINESS_PRE_ORDER_START: "APPROVE_BUSINESS_PRE_ORDER_START",
  APPROVE_BUSINESS_PRE_ORDER_SUCCESS: "APPROVE_BUSINESS_PRE_ORDER_SUCCESS",
  APPROVE_BUSINESS_PRE_ORDER_ERROR: "APPROVE_BUSINESS_PRE_ORDER_ERROR",

  REJECT_BUSINESS_PRE_ORDER_START: "REJECT_BUSINESS_PRE_ORDER_START",
  REJECT_BUSINESS_PRE_ORDER_SUCCESS: "REJECT_BUSINESS_PRE_ORDER_SUCCESS",
  REJECT_BUSINESS_PRE_ORDER_ERROR: "REJECT_BUSINESS_PRE_ORDER_ERROR",

  UPDATE_BUSINESS_PRE_ORDER_START: "UPDATE_BUSINESS_PRE_ORDER_START",
  UPDATE_BUSINESS_PRE_ORDER_SUCCESS: "UPDATE_BUSINESS_PRE_ORDER_SUCCESS",
  UPDATE_BUSINESS_PRE_ORDER_ERROR: "UPDATE_BUSINESS_PRE_ORDER_ERROR",

  PHARMACIST_AUTHORIZATION_START: "PHARMACIST_AUTHORIZATION_START",
  PHARMACIST_AUTHORIZATION_SUCCESS: "PHARMACIST_AUTHORIZATION_SUCCESS",
  PHARMACIST_AUTHORIZATION_ERROR: "PHARMACIST_AUTHORIZATION_ERROR",

  GET_BUSINESS_PRESCRIPTION_START: "GET_BUSINESS_PRESCRTIPTION_START",
  GET_BUSINESS_PRESCRIPTION_SUCCESS: "GET_BUSINESS_PRESCRTIPTION_SUCCESS",
  GET_BUSINESS_PRESCRIPTION_ERROR: "GET_BUSINESS_PRESCRTIPTION_ERROR",

  GET_STORE_PRESCRIPTION_START: "GET_STORE_PRESCRTIPTION_START",
  GET_STORE_PRESCRIPTION_SUCCESS: "GET_STORE_PRESCRTIPTION_SUCCESS",
  GET_STORE_PRESCRIPTION_ERROR: "GET_STORE_PRESCRTIPTION_ERROR",

  DOWNLOAD_PRESCRIPTION_START: "DOWNLOAD_PRESCRTIPTION_START",
  DOWNLOAD_PRESCRIPTION_SUCCESS: "DOWNLOAD_PRESCRTIPTION_SUCCESS",
  DOWNLOAD_PRESCRIPTION_ERROR: "DOWNLOAD_PRESCRTIPTION_ERROR",

  GET_ORDER_PRESCRIPTION_START: "GET_ORDER_PRESCRIPTION_START",
  GET_ORDER_PRESCRIPTION_SUCCESS: "GET_ORDER_PRESCRIPTION_SUCCESS",
  GET_ORDER_PRESCRIPTION_ERROR: "GET_ORDER_PRESCRIPTION_ERROR",

  GET_CONVERSATIONS_START: "GET_CONVERSATIONS_START",
  GET_CONVERSATIONS_SUCCESS: "GET_CONVERSATIONS_SUCCESS",
  GET_CONVERSATIONS_ERROR: "GET_CONVERSATIONS_ERROR",
  //GET_CONVERSATIONS BY ID
  GET_CONVERSATION_START: "GET_CONVERSATION_START",
  GET_CONVERSATION_SUCCESS: "GET_CONVERSATION_SUCCESS",
  GET_CONVERSATION_ERROR: "GET_CONVERSATION_ERROR",
  //OPEN CHAT
  CHAT_OPEN_SUCCESS: "CHAT_OPEN_SUCCESS",
  SET_ACTIVE_LINK: "SET_ACTIVE_LINK",
  //CONVERSATION COUNT
  UPDATE_CONVRSATION_COUNT_START: "UPDATE_CONVRSATION_COUNT_START",

  UPDATE_NOTI_COUNT_START: "UPDATE_NOTI_COUNT",
  SHOW_PREORDER_BAR: "SHOW_PREORDER_BAR",
  STATE_BAR_COUNTS: "STATE_BAR_COUNTS",
};
