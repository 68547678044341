export default {
  GET_STARSHIP_DETAILS: "starships/",
  USER_SIGNUP_DETAILS: "/auth/signup",
  USER_LOGIN_QR: "/auth/login-qr",
  RESEND_QR: "/auth/resend-qr",
  USER_PERMISSIONS: "/auth/perms",
  LOGOUT: "/auth/logout",
  PHARMACY_PERMISSIONS: "/auth/store/perms",
  BUSINESS_SIGNUP_DETAILS: "/auth/signup",
  QUERY_DETAILS: "/query",
  PHARMACY_LOGIN: "/auth/login",
  PHARMACY_VERIFY_OTP: "/auth/verifyOTP",
  PHARMACY_RESEND_VERIFY_OTP: "/auth/resendOTPVerificationCode",
  UPLOAD_VERIFICATION_DOCUMENT: "/store/auth/uploadVerificationDocs",
  CREATE_PHARMACY_PRODUCT: "/store/product",
  ADD_PRODUCT_IMAGE: "/store/product/",
  UPLOAD_PRODUCT_IMAGE: "/store/product/upload-product-image",
  ROOT_CATEGORY: "/admin/category",
  PRODUCT_CATEGORY: "/store/product_category",
  FORGOT_PASSWORD_REQUEST: "/auth/forgotPassword",
  RESET_PASSWORD_REQUEST: "/auth/resetPassword/",
  CREATE_PASSWORD_REQUEST: "/store/member/create-password/",
  UPDATE_PASSWORD_REQUEST: "/store/auth/updateMyPassword",
  PHARMACY_PROFILE_REQUEST: "/store/auth/updateMe",
  GET_PHARMACY_PRODUCTS: "/products",
  PRODUCT_DETAIL: "/products/",
  PRODUCTS: "/store/product",
  GET_PRODUCT_CATEGORIES: "/products/categories",
  GET_SAME_PRODUCT_INVENTORY: "/products/product/",
  GET_PRODUCT_INVENTORY_BY_DETAIL: "/products/inventory/",
  SIMILAR_PRODUCTS: "/products/similar/",
  CREATE_ORDER: "/store/orders",
  PURCHASE_ORDER: "/store/orders/purchaseOrders",
  GET_ORDERS: "/store/orders",
  ORDER_DETAIL: "/store/orders/subOrder",
  ORDERS_PRESCRIPTION: "/store/orders/prescription",
  SAVE_CART: "/store/cart",
  GET_CART: "/store/cart",
  EMPTY_CART: "/store/cart",
  NOTIFICATIONS: "/store/notifications",
  MEMBER: "/store/member",
  CREATE_MEMBER: "store/member/create-member",
  EDIT_PRODUCT_DETAIL: "store/product/",
  GET_INVENTORY_BY_PRODUCT_ID: "/store/product/",
  DELETE_PRODUCT_INVENTORY: "/store/product/inventory/",
  DELETE_PURCHASE_ORDER: "store/orders/sub-order/",
  GET_PRODUCT_INVENTORY_DETAIL: "/store/product/inventory/",
  UPDATE_PRODUCT_INVENTORY: "/store/product/inventory/",
  ADD_PRODUCT_INVENTORY: "/store/product/createInventory",
  PRODUCT_INVENTORY_WISHLIST: "/user/wishlist/",
  GET_CURRENT_USER_PHARMACIES: "auth/business/stores",
  ADD_BUSINESS_PHARMACY: "auth/store-register",
  GET_PHARMS: "auth/stores",
  GET_PHARM_TOKEN: "auth/store-token",
  GET_MEMBER_NOTIFICATION: "/store/member/notifications",
  UPDATE_MEMBER_NOTIFICATION: "/store/member/notifications",
  GET_BUSINESS_NOTIFICATION: "/auth/business/notifications",
  UPDATE_BUSINESS_NOTIFICATION: "/auth/business/notifications",
  UPDATE_BUSINESS_PROFILE: "/auth/business/updateMe",
  UPDATE_BUSINESS_PASSWORD: "/auth/business/updateMyPassword",
  GET_ALL_PERMISSIONS: "/auth/getAllPerms",
  UPDATE_MEMBER: "/store/member",
  UPDATE_MEMBER_PROFILE: "/store/member/updateMe",
  UPDATE_MEMBER_PASSWORD: "/store/member/updateMyPassword",
  UPLOAD_KYC_DOCUMENT: "/store/member/uploadVerificationDocs",
  GET_KYC_DOCUMENT: "/store/member/verificationDocs",
  PHARMACY_ORDER_REPORTING: "/store/stats/total-orders",
  PHARMACY_PURCHASE_ORDER_REPORTING: "/store/stats/purchase-orders",
  PHARMACY_MONTHLY_SALE_REPORTING: "/store/stats/sale-report",
  BUSINESS_ORDER_REPORTING: "/business/stats/total-orders",
  BUSINESS_LEVEL_COUNT: "/business/stats/business-level-count",
  STORE_LEVEL_COUNT: "/store/stats/store-level-count",
  BUSINESS_PURCHASE_ORDER_REPORTING: "/business/stats/purchase-orders",
  BUSINESS_MONTHLY_SALE_REPORT: "/business/stats/sale-report",
  SEARCH_PRODUCTS: "/products/search",

  TOP_SELLING_PRODUCTS: "/business/stats/top-selling-prdoucts",

  STORE_TOP_SELLING_PRODUCTS: "/store/stats/top-selling-prdoucts",

  GET_BUSINESS_ORDERS: "/business/stats/orders",
  GET_BUSINESS_PURCHASE_ORDER: "/business/stats/purchaseOrders",
  GET_BUSINESS_PRODUCTS_LIST: "/business/stats/products",

  ENLIST_DELIST_PRODUCTS: "/store/product/",
  ENLIST_DELIST_INVENTORY: "/store/product/inventory/",
  CANCEL_ORDER: "/store/orders/subOrder/",
  RETURN_ORDER: "/store/orders/subOrder/",
  BUYER_CANCEL_ORDER: "/store/orders/subOrder/",
  ACCEPT_ORDER: "/store/orders/subOrder/",
  PURCHASE_ORDER_SUGGESTION: "/store/orders/purchaseOrders/suggestions",
  RELATED_PRODUCTS: "/products",
  PRODUCTS_LISTING: "/products/listing",
  STORES: "/products/store",
  CATEGORIES_BRANDS: "/products/brandsAndCategories",
  GET_ALL_WATCHLIST: "/user/watchlist/getAll",
  ADD_WATCHLIST: "/user/watchlist",
  WATCHLIST_ID: "/user/watchlist/",
  ORDER_APPROVAL: "/store/orders/purchaseOrders/",
  ORDER_REJECTION: "/store/orders/purchaseOrders/",
  GET_PURCASE_ORDER: "/purchase",
  GET_PRE_ORDERS: "/store/pre-orders",
  GET_BUSINESS_PRE_ORDERS: "/business/stats/preOrders",
  GET_BUSINESS_PRESCRIPTION: "/business/stats/prescriptions",
  GET_STORE_PRESCRIPTION: "/store/orders/prescriptions",
  GET_ORDER_PRESCRIPTION: "store/orders/prescription",
  GET_CONVERSATIONS: "/chat/conversation/store",
  GET_CONVERSATION: "/chat/conversation/message",
};
