import React, { useEffect, useState } from "react";
import { Container } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import Stripe from "../../shared/Stripe";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import {
  Box,
  Grid,
  CardActions,
  CardContent,
  Card,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import {
  getTotalOfCarts,
  getTotalTax,
  getShippingTotal,
  getGrandTotal,
  getShippingTotalOfCarts,
  getAllShippingTotal,
} from "../../helpers/pricing";
import "./checkout.scss";
import { getCart } from "../../services/cart";
import { createPharmacyOrder } from "../../services/orders";
import { addProducts } from "../../services/cart";
import { formatNumberWithCommas } from "../../helpers/getTotalValue";
import PreOrderDetail from "./PreOrderDetails";
import OrderSummaryDetails from "./OrderSummaryDetails";
import { useTranslation } from "react-i18next";

const Checkout = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.auth);
  const cartLoading = useSelector((state) => state?.cart?.getCart?.loading);
  const updateCartLoading = useSelector(
    (state) => state?.cart?.updateCart?.loading
  );
  const loading = useSelector((state) => state?.order?.createOrder?.loading);
  const history = useNavigate();
  const [preOrders, setPreOrders] = useState([]);
  const [orderDeliver, setOrderDeliver] = useState([]);
  const [immuteableOrders, setImmuteableOrders] = useState([]);
  const [unCheckimmuteableOrders, setUnCheckImmuteableOrders] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [showShoppingBtn, setShowShoppingBtn] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [count, setCount] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints?.down("sm"));
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: "0 8px 30px 0 rgb(0 0 0 / 20%)",
    p: 4,
  };

  useEffect(() => {
    if (user && user?.token) {
      dispatch(
        getCart(function (res) {
          if (res) {
            let preOrders =
              res?.data && res?.data?.preOrder && res?.data?.preOrder?.length
                ? res?.data?.preOrder
                : [];
            setPreOrders(JSON.parse(JSON.stringify(preOrders)));
            setImmuteableOrders(JSON.parse(JSON.stringify(preOrders)));
            let tempPreOrders = [...JSON.parse(JSON.stringify(preOrders))];
            const products = [];
            for (let i = 0; i < tempPreOrders?.length; i++) {
              tempPreOrders[i].isChecked = false;

              for (let j = 0; j < tempPreOrders[i]?.carts?.length; j++) {
                tempPreOrders[i].carts[j].isChecked = false;
                for (
                  let k = 0;
                  k < tempPreOrders[i]?.carts[j]?.products?.length;
                  k++
                ) {
                  tempPreOrders[i].carts[j].products[k].isChecked = false;
                  let product = {};
                  product.DIN_NUMBER =
                    tempPreOrders[i]?.carts[j]?.products[
                      k
                    ]?.product?.DIN_NUMBER;
                  product.batch_number =
                    tempPreOrders[i]?.carts[j]?.products[
                      k
                    ]?.product?.batch_number;
                  product.for = tempPreOrders[i]?.carts[j]?.products[k]?.for;
                  product.isActive =
                    tempPreOrders[i]?.carts[j]?.products[k]?.product?.isActive;
                  product.quantity =
                    tempPreOrders[i]?.carts[j]?.products[k]?.product?.quantity;
                  product.imageCover =
                    tempPreOrders[i]?.carts[j]?.products[
                      k
                    ]?.product?.product?.imageCover;
                  product.product_name =
                    tempPreOrders[i]?.carts[j]?.products[
                      k
                    ]?.product?.product?.product_name;
                  product.brand =
                    tempPreOrders[i]?.carts[j]?.products[
                      k
                    ]?.product?.product?.brand;
                  product.count =
                    tempPreOrders[i]?.carts[j]?.products[k]?.count;
                  product.store =
                    tempPreOrders[i]?.carts[j]?.products[
                      k
                    ]?.product?.store?._id;
                  product.product =
                    tempPreOrders[i]?.carts[j]?.products[
                      k
                    ]?.product?.product?._id;
                  product.price =
                    tempPreOrders[i]?.carts[j]?.products[k]?.price;
                  if (
                    tempPreOrders[i]?.carts[j]?.products[k]?.discountedPrice
                  ) {
                    product.discountedPrice =
                      tempPreOrders[i]?.carts[j]?.products[k]?.discountedPrice;
                  }
                  product.expiry_date =
                    tempPreOrders[i]?.carts[j]?.products[
                      k
                    ]?.product?.expiry_date;
                  product._id = tempPreOrders[i]?.carts[j]?.products[k]?._id;
                  products.push(product);
                }
              }
            }
            setUnCheckImmuteableOrders(tempPreOrders);
            if (typeof window !== "undefined") {
              localStorage.setItem("products", JSON.stringify(products));
            }
            dispatch(addProducts(products));
          }
        })
      );
    }
  }, [count]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [count]);

  const handlePlaceOrder = () => {
    if (preOrders?.every((el) => !el.isChecked)) {
      toast.warn("Atleast one cart should be selected");
      return;
    } else {
      setOpen(true);
    }
  };

  const handleOrder = () => {
    if (preOrders?.every((el) => !el.isChecked)) {
      toast.warn("Atleast one cart should be selected");
      return;
    }
    let tmpOrders = [...JSON.parse(JSON.stringify(preOrders))];
    let preToCreate = [
      ...JSON.parse(JSON.stringify(tmpOrders)).filter((el) => el.isChecked),
    ];
    let preToCart = [...JSON.parse(JSON.stringify(preOrders))];
    let productsForCart = [];

    for (let i = 0; i < preToCreate?.length; i++) {
      for (let j = 0; j < preToCreate[i]?.carts?.length; j++) {
        if (preToCreate[i]?.carts[j]?.products?.every((el) => !el?.isChecked)) {
          preToCreate[i]?.carts?.splice(j, 1);
          j = j - 1;
        } else {
          for (let k = 0; k < preToCreate[i]?.carts[j]?.products?.length; k++) {
            if (preToCreate[i]?.carts[j]?.products[k]?.isChecked == false) {
              preToCreate[i]?.carts[j]?.products?.splice(k, 1);

              k = k - 1;
            }
          }
        }
      }
    }

    for (let i = 0; i < preToCart?.length; i++) {
      for (let j = 0; j < preToCart[i]?.carts?.length; j++) {
        preToCart[i].isChecked = false;
        if (preToCart[i]?.carts[j]?.products?.every((el) => el?.isChecked)) {
          preToCart[i]?.carts?.splice(j, 1);
          j = j - 1;
        } else {
          for (let k = 0; k < preToCart[i]?.carts[j]?.products?.length; k++) {
            if (preToCart[i]?.carts[j]?.products[k]?.isChecked == true) {
              preToCart[i]?.carts[j]?.products?.splice(k, 1);

              k = k - 1;
            }
          }
        }
        if (preToCart[i]?.carts?.length == 0) {
          preToCart?.splice(i, 1);
          i = i - 1;
        }
      }
    }
    for (let x = 0; x < preToCart?.length; x++) {
      for (let y = 0; y < preToCart[x]?.carts?.length; y++) {
        for (let z = 0; z < preToCart[x]?.carts[y]?.products?.length; z++) {
          productsForCart.push(preToCart[x]?.carts[y]?.products[z]);
        }
      }
    }

    dispatch(
      createPharmacyOrder(
        {
          newCart: preToCart,
          preOrders: preToCreate,
          productsForCart: productsForCart,
        },
        async function (res) {
          if (res) {
            toast.success("Order has been placed successfully");
            setOrderDeliver(preToCreate);

            if (productsForCart?.length) {
              setSelectAll(false);
              setCount((pre) => pre + 1);
            } else {
              if (typeof window !== "undefined") {
                localStorage.removeItem("products");
              }
              dispatch(addProducts([]));
              setPreOrders([]);
              setShowShoppingBtn(true);
            }
          }
        }
      )
    );
  };

  const handleDeliveryClick = (preIndex, cartIndex, productIndex, type) => {
    let tmpOrders = [...JSON.parse(JSON.stringify(preOrders))];
    let tmpUnpack = [...JSON.parse(JSON.stringify(unCheckimmuteableOrders))];
    let tmpImmute = [...JSON.parse(JSON.stringify(immuteableOrders))];

    let selectedShipping = {
      total:
        tmpOrders[preIndex]?.carts[cartIndex]?.shipping?.estimatedShipping
          ?.normalPrice,
      shippingType: "normal",
      ride_charges:
        tmpOrders[preIndex]?.carts[cartIndex]?.shipping?.estimatedShipping
          ?.ride_charges_normal,
      tax: tmpOrders[preIndex].carts[cartIndex]?.shipping?.estimatedShipping
        ?.tax,
    };

    if (type == "urgent") {
      selectedShipping = {
        total:
          tmpOrders[preIndex]?.carts[cartIndex]?.shipping?.estimatedShipping
            ?.urgent_price,
        shippingType: "urgent",
        ride_charges:
          tmpOrders[preIndex]?.carts[cartIndex]?.shipping?.estimatedShipping
            ?.ride_charges_urgent,
        tax: tmpOrders[preIndex].carts[cartIndex]?.shipping?.estimatedShipping
          ?.tax,
      };
    }
    let shipping = {
      ...tmpOrders[preIndex]?.carts[cartIndex]?.shipping,
      selectedShipping: selectedShipping,
    };
    tmpUnpack[preIndex].carts[cartIndex].products[productIndex].shipping =
      shipping;
    tmpOrders[preIndex].carts[cartIndex].products[productIndex].shipping =
      shipping;
    tmpOrders[preIndex].carts[cartIndex].shipping = shipping;
    tmpUnpack[preIndex].carts[cartIndex].shipping = shipping;
    tmpImmute[preIndex].carts[cartIndex].shipping = shipping;

    let total = getShippingTotal(tmpUnpack[preIndex].carts);
    tmpUnpack[preIndex].shipping.total = total;
    tmpOrders[preIndex].shipping.total = total;
    tmpImmute[preIndex].shipping.total = total;

    setPreOrders(tmpOrders);
    setUnCheckImmuteableOrders(tmpUnpack);
    setImmuteableOrders(tmpImmute);
  };

  const handlePreOrderSelect = (preIndex, val) => {
    let tmpOrders = [...JSON.parse(JSON.stringify(preOrders))];

    tmpOrders[preIndex].isChecked = val;
    for (let j = 0; j < tmpOrders[preIndex]?.carts?.length; j++) {
      tmpOrders[preIndex].carts[j].isChecked = val;
      for (
        let k = 0;
        k < tmpOrders[preIndex]?.carts[j]?.products?.length;
        k++
      ) {
        tmpOrders[preIndex].carts[j].products[k].isChecked = val;
      }
    }
    let tmpSelect = false;
    if (tmpOrders.every((el) => !el.isChecked)) {
      tmpSelect = false;
    }

    if (tmpOrders.every((el) => el.isChecked)) {
      tmpSelect = true;
    }
    setSelectAll(tmpSelect);
    setPreOrders(tmpOrders);
  };

  const handleProductClick = (preInd, cartIndex, productIndex, val) => {
    let tmpOrders = [...JSON.parse(JSON.stringify(preOrders))];
    tmpOrders[preInd].carts[cartIndex].products[productIndex].isChecked = val;

    let tmpSelect = false;

    if (val) {
      tmpOrders[preInd].isChecked = val;

      if (tmpOrders.every((el) => el.isChecked)) {
        setSelectAll(true);
      }
    } else {
      let count = 0;

      for (let j = 0; j < tmpOrders[preInd]?.carts?.length; j++) {
        for (
          let k = 0;
          k < tmpOrders[preInd]?.carts[j]?.products?.length;
          k++
        ) {
          if (tmpOrders[preInd].carts[j].products[k].isChecked) {
            count++;

            break;
          }
        }
      }

      if (count <= 0) {
        tmpOrders[preInd].isChecked = false;
      }

      if (tmpOrders.every((el) => !el.isChecked)) {
        setSelectAll(false);
      } else if (
        tmpOrders.some((el) => el.isChecked) &&
        tmpOrders.some((el) => !el.isChecked)
      ) {
        setSelectAll(false);
      }
    }

    setPreOrders(tmpOrders);
  };

  const handleCheckAll = (val) => {
    let tmpOrders = [];
    if (val) {
      let tmpImute = [...JSON.parse(JSON.stringify(unCheckimmuteableOrders))];

      for (let i = 0; i < tmpImute?.length; i++) {
        tmpImute[i].isChecked = true;
        for (let j = 0; j < tmpImute[i]?.carts?.length; j++) {
          tmpImute[i].carts[j].isChecked = true;
          for (let k = 0; k < tmpImute[i]?.carts[j]?.products?.length; k++) {
            tmpImute[i].carts[j].products[k].isChecked = true;
          }
        }
      }

      tmpOrders = [...tmpImute];
    } else {
      tmpOrders = [...unCheckimmuteableOrders];
    }

    setPreOrders(tmpOrders);
    setSelectAll(val);
  };

  const handleContinueShopping = () => {
    history("/marketplace");
  };

  return (
    <Container
      sx={{ paddingLeft: "0 !important", paddingRight: "0 !important" }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={updateCartLoading}
      >
        <CircularProgress sx={{ color: "#235D5E" }} />
      </Backdrop>

      <Grid
        container
        justifyContent="center"
        width="100%"
        sx={{ margin: "0px" }}
        spacing={2}
      >
        <Grid item lg={8} md={12} sm={12} xs={12}>
          {cartLoading ? (
            <Box
              sx={{
                justifyContent: "center",
                height: "400px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CircularProgress
                sx={{
                  color: "#235D5E",
                }}
              />
            </Box>
          ) : (
            <Box>
              {preOrders && preOrders?.length > 0 && (
                <FormGroup
                  sx={{ flexDirection: "row", justifyContent: "flex-end" }}
                >
                  <FormControlLabel
                    sx={{ margin: "0px 12px" }}
                    control={
                      <Checkbox
                        sx={{ color: "#235D5E !important" }}
                        disabled={loading}
                        value={selectAll}
                        onChange={(e) => handleCheckAll(e?.target?.checked)}
                        checked={selectAll}
                      />
                    }
                    label={t("sideBar.selectAll")}
                  />
                </FormGroup>
              )}

              <Box>
                {preOrders && preOrders?.length
                  ? preOrders?.map((pre, ind) => {
                      return (
                        <PreOrderDetail
                          pre={pre}
                          ind={ind}
                          loading={loading}
                          handlePreOrderSelect={handlePreOrderSelect}
                          handleProductClick={handleProductClick}
                          handleDeliveryClick={handleDeliveryClick}
                          isMobile={isMobile}
                        />
                      );
                    })
                  : null}
              </Box>
            </Box>
          )}
        </Grid>
        {preOrders && preOrders?.length > 0 ? (
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Card
              sx={{
                borderRadius: "20px",
                boxShadow: "0px 84px 214px -80px rgba(0, 0, 0, 0.08)",
                border: "1px solid #F0F0F0",
              }}
            >
              <CardContent>
                <>
                  <Typography
                    mb={3}
                    className="summary-heading"
                    fontSize={{ lg: 24, md: 24, sm: 20, xs: 18 }}
                  >
                    {t("sideBar.orderSummary")}
                  </Typography>
                  <Box display="flex" my={2} alignItems="center">
                    <Typography
                      sx={{ flex: "1" }}
                      className="summary-heading"
                      fontSize={{ lg: 18, md: 18, sm: 16, xs: 14 }}
                    >
                      {t("sideBar.SubTotal")}
                      <span>{preOrders?.length}</span> Items
                    </Typography>
                    <Typography
                      variant="h5"
                      fontSize={{ lg: 24, md: 24, sm: 20, xs: 18 }}
                      className="summary-heading"
                    >
                      $
                      {formatNumberWithCommas(
                        parseFloat(getShippingTotalOfCarts(preOrders))?.toFixed(
                          2
                        )
                      )}
                    </Typography>
                  </Box>

                  <Box display="flex" my={2} alignItems="center">
                    <Typography
                      sx={{ flex: "1" }}
                      fontSize={{ lg: 18, md: 18, sm: 16, xs: 14 }}
                      className="summary-heading"
                    >
                      {t("EditProduct.tax")}
                    </Typography>
                    <Typography
                      variant="h5"
                      fontSize={{ lg: 24, md: 24, sm: 20, xs: 18 }}
                      className="summary-heading"
                    >
                      $
                      {formatNumberWithCommas(
                        parseFloat(
                          Number(
                            getTotalTax(
                              getTotalOfCarts(preOrders[0]?.carts),
                              preOrders[0]?.carts[0]?.taxDetails
                            )
                          )
                        )?.toFixed(2)
                      )}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" my={2}>
                    <Typography
                      sx={{ flex: "1" }}
                      className="summary-heading"
                      fontSize={{ lg: 18, md: 18, sm: 16, xs: 14 }}
                    >
                      {t("sideBar.shippingFee")}
                    </Typography>
                    <Typography
                      variant="h5"
                      fontSize={{ lg: 24, md: 24, sm: 20, xs: 18 }}
                      className="summary-heading"
                    >
                      {`$${formatNumberWithCommas(
                        parseFloat(getAllShippingTotal(preOrders)).toFixed(2)
                      )}`}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" my={2}>
                    <Typography
                      sx={{ flex: "1" }}
                      className="summary-heading"
                      fontSize={{ lg: 18, md: 18, sm: 16, xs: 14 }}
                    >
                      {t("sideBar.Total")}
                    </Typography>
                    <Typography
                      variant="h5"
                      fontSize={{ lg: 24, md: 24, sm: 20, xs: 18 }}
                      className="summary-heading"
                    >
                      $
                      {formatNumberWithCommas(
                        getGrandTotal(
                          getTotalTax(
                            getShippingTotalOfCarts(preOrders),
                            preOrders[0]?.carts[0]?.taxDetails
                          ),
                          getAllShippingTotal(preOrders),
                          getShippingTotalOfCarts(preOrders)
                        )
                      )}
                    </Typography>
                  </Box>
                </>
              </CardContent>
              <CardActions>
                {preOrders && preOrders?.length ? (
                  <Button
                    onClick={handleOrder}
                    fullWidth
                    className="containedPrimary"
                    variant="contained"
                    disabled={loading}
                    sx={{ opacity: loading ? "0.5" : "1" }}
                  >
                    {loading ? (
                      <ClipLoader size={25} color="white" loading />
                    ) : (
                      <>{t("ProductDetail.createApproval")}</>
                    )}
                  </Button>
                ) : (
                  <></>
                )}
              </CardActions>
            </Card>
          </Grid>
        ) : null}

        <Grid item lg={10} md={12} sm={12} xs={12}>
          {orderDeliver &&
            orderDeliver?.length > 0 &&
            orderDeliver?.map((pre, ind) => {
              return <OrderSummaryDetails pre={pre} isMobile={isMobile} />;
            })}
        </Grid>

        {preOrders && preOrders?.length > 0
          ? null
          : orderDeliver &&
            orderDeliver?.length > 0 && (
              <Grid item lg={10} md={12} sm={12} xs={12} pt={"0px !important"}>
                <Card
                  sx={{
                    borderRadius: "20px",
                    boxShadow: "0px 84px 214px -80px rgba(0, 0, 0, 0.08)",
                    border: "1px solid #F0F0F0",
                  }}
                >
                  <CardContent>
                    <>
                      <Typography
                        mb={3}
                        className="summary-heading"
                        fontSize={{ lg: 24, md: 24, sm: 20, xs: 18 }}
                      >
                        {t("sideBar.orderSummary")}
                      </Typography>
                      <Box display="flex" my={2} alignItems="center">
                        <Typography
                          sx={{ flex: "1" }}
                          className="summary-heading"
                          fontSize={{ lg: 18, md: 18, sm: 16, xs: 14 }}
                        >
                          {t("sideBar.SubTotal")}
                        </Typography>
                        <Typography
                          variant="h5"
                          fontSize={{ lg: 24, md: 24, sm: 20, xs: 18 }}
                          className="summary-heading"
                        >
                          $
                          {formatNumberWithCommas(
                            parseFloat(
                              getShippingTotalOfCarts(orderDeliver)
                            )?.toFixed(2)
                          )}
                        </Typography>
                      </Box>

                      <Box display="flex" my={2} alignItems="center">
                        <Typography
                          sx={{ flex: "1" }}
                          fontSize={{ lg: 18, md: 18, sm: 16, xs: 14 }}
                          className="summary-heading"
                        >
                          {t("EditProduct.tax")}
                        </Typography>
                        <Typography
                          variant="h5"
                          fontSize={{ lg: 24, md: 24, sm: 20, xs: 18 }}
                          className="summary-heading"
                        >
                          $
                          {formatNumberWithCommas(
                            parseFloat(
                              Number(
                                getTotalTax(
                                  getTotalOfCarts(orderDeliver[0]?.carts),
                                  orderDeliver[0]?.carts[0]?.taxDetails
                                )
                              )
                            )?.toFixed(2)
                          )}
                        </Typography>
                      </Box>

                      <Box display="flex" alignItems="center" my={2}>
                        <Typography
                          sx={{ flex: "1" }}
                          className="summary-heading"
                          fontSize={{ lg: 18, md: 18, sm: 16, xs: 14 }}
                        >
                          {t("sideBar.shippingFee")}
                        </Typography>
                        <Typography
                          variant="h5"
                          fontSize={{ lg: 24, md: 24, sm: 20, xs: 18 }}
                          className="summary-heading"
                        >
                          {`$${formatNumberWithCommas(
                            parseFloat(
                              getAllShippingTotal(orderDeliver)
                            ).toFixed(2)
                          )}`}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" my={2}>
                        <Typography
                          sx={{ flex: "1" }}
                          className="summary-heading"
                          fontSize={{ lg: 18, md: 18, sm: 16, xs: 14 }}
                        >
                          {t("sideBar.Total")}
                        </Typography>
                        <Typography
                          variant="h5"
                          fontSize={{ lg: 24, md: 24, sm: 20, xs: 18 }}
                          className="summary-heading"
                        >
                          $
                          {formatNumberWithCommas(
                            getGrandTotal(
                              getTotalTax(
                                getShippingTotalOfCarts(orderDeliver),
                                orderDeliver[0]?.carts[0]?.taxDetails
                              ),
                              getAllShippingTotal(orderDeliver),
                              getShippingTotalOfCarts(orderDeliver)
                            )
                          )}
                        </Typography>
                      </Box>
                    </>
                  </CardContent>
                  <CardActions>
                    {preOrders && preOrders?.length ? (
                      <Button
                        onClick={handleOrder}
                        fullWidth
                        className="containedPrimary"
                        variant="contained"
                        disabled={loading}
                        sx={{ opacity: loading ? "0.5" : "1" }}
                      >
                        {loading ? (
                          <ClipLoader size={25} color="white" loading />
                        ) : (
                          <>{t("ProductDetail.createApproval")}</>
                        )}
                      </Button>
                    ) : (
                      <></>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            )}

        {preOrders && preOrders?.length > 0
          ? null
          : orderDeliver &&
            orderDeliver?.length > 0 && (
              <Grid item lg={10} md={12} sm={12} xs={12}>
                <Button
                  onClick={() => handleContinueShopping()}
                  fullWidth
                  className="containedPrimary"
                  variant="contained"
                  sx={{
                    paddingLeft: "15px",
                    marginTop: "15px",
                  }}
                >
                  {t("ProductDetail.continueShopping")}
                </Button>
              </Grid>
            )}
      </Grid>
    </Container>
  );
};

export default Checkout;
