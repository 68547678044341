import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import Footer from "../../../shared/components/footer";
import NewsLetter from "../../../shared/components/footer/newsletter";
import { Grid } from "@mui/material";
import Vactor from "../../../assets/businessPage/Vactor.svg";
import Icon1 from "../../../assets/businessPage/crossCirclefill.svg";
import ApproachImage from "../../../assets/businessPage/approach-banner.png";
import FaqResponsiveHeader from "../faqHeader";
import { useTranslation } from "react-i18next";
import "./approach.scss";

const Approach = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const productList = [
    {
      title: "LS - LINDANE LOTION - SCABICIDE",
    },
    {
      title: "narcotics",
    },
    {
      title: "LS - DECONGESTANT ORAL ADULT",
    },
    {
      title: "LS - HYDROCORTISONE",
    },
    {
      title: "LS - BISMUTH SUBSALICYLATE",
    },
    {
      title: "LS - ETHYLENE OXIDE GASEOUS STERILANT",
    },
    {
      title: "LS - WART REMOVERS",
    },
    {
      title: "LS - CORN AND CALLOUS REMOVERS",
    },
    {
      title: "LS - FEVERFEW LEAF",
    },
    {
      title: "CAT IV - DIETARY MIN/DIETARY VIT. SUPPLEMENTS",
    },
    {
      title: "LS - VIT. SUPPL./MIN. SUPPL./UBIQUINONE",
    },
    {
      title: "CAT IV - MED. SKIN CARE PROD./SUNBURN PROTECTANTS",
    },
    {
      title: "LS - CAFFEINE",
    },
    {
      title: "LS - PEPPERMINT",
    },
    {
      title: "LS - CHAMOMILE",
    },
    {
      title: "LS - INTESTINAL FLORA MODIFIERS",
    },
    {
      title: "LS - ECHINACEA ROOT",
    },
    {
      title: "LS - DENTAL AND ORAL CARE PRODUCTS FOR PROF. USE",
    },
    {
      title: "CAT IV - DIETARY MINERAL SUPPLEMENTS",
    },
    {
      title: "LS - INTESTINAL FLORA MODIFIERS",
    },
  ];

  return (
    <Container>
      <FaqResponsiveHeader />
      <Container maxWidth={false} className="uniorxLandingPageContainer ">
        <Box sx={{ background: "#235d5e" }}>
          <Box
            className="VactorCus"
            sx={{
              backgroundImage: `url(${Vactor})`,
            }}
          >
            <Typography
              color="#ffff"
              className="mainHeading"
              sx={{
                padding: {
                  xs: "20px 15px 10px !important",
                  sm: "32px !important",
                },
              }}
            >
              {t("OurApproach.approach")}
            </Typography>

            <Typography
              color="#ffff"
              fontSize={"14px !important"}
              fontWeight={200}
              className="mainParagraph"
            >
              {t("OurApproach.details")}
            </Typography>
          </Box>
        </Box>
        <Grid mt={2}>
          <Box className="approachCardMains">
            <Grid container spacing={0}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Box className="approachImg">
                  <img src={ApproachImage} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={8}>
                <Box className="approachText">
                  <Box className="icons">
                    <img src={Icon1} />
                  </Box>
                  <Box className="head">
                    <Typography className="h3">
                      {t("OurApproach.heading1")}
                    </Typography>
                    <Typography className="p">
                      {t("OurApproach.headingDetail")}
                    </Typography>
                  </Box>
                </Box>
                <Box className="approachText">
                  <Box className="icons">
                    <img src={Icon1} />
                  </Box>
                  <Box className="head">
                    <Typography className="h3">
                      {t("OurApproach.heading2")}
                    </Typography>
                    <Typography className="p">
                      {t("OurApproach.headingDetail")}
                    </Typography>
                  </Box>
                </Box>
                <Box className="approachText">
                  <Box className="icons">
                    <img src={Icon1} />
                  </Box>
                  <Box className="head">
                    <Typography className="h3">
                      {t("OurApproach.heading3")}
                    </Typography>
                    <Typography className="p">
                      {t("OurApproach.headingDetail")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Container>
      <NewsLetter />
      <Footer />
    </Container>
  );
};
export default Approach;
