import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, TextField, IconButton, InputLabel } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { Formik } from "formik";
import { initialValues, Schema, qrInitialValues, qrSchema } from "./helper";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../shared/components/authLayout";
import eye from "../../assets/images/autheye.svg";
import { ClipLoader } from "react-spinners";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import QRModal from "./QRModal";
import useDialogModal from "../../hooks/useDialogModal";
import ResendQRModal from "../ResendQR";
import { addProducts } from "../../services/cart";
import { useLocation } from "react-router-dom";
import { redirectPath } from "../../helpers/redirectPath";
import { updateSession, pharmacyLoginRequest } from "../../services/BAuth";
import FErrorMessage from "../../shared/components/FErrorMessage";
import { useTranslation } from "react-i18next";
import "./signin.scss";

const Signin = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const loading = useSelector((state) => state?.auth?.pharmacyLogin?.loading);
  const { user, isSessionExpired } = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  const [qrImg, setQRImage] = useState("");
  const [is_qr, setIs_Qr] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [
    ResendQRModalDialog,
    showResendQRModalDialog,
    closeResendQRModalDialog,
  ] = useDialogModal(ResendQRModal);
  const [QRModalDetailDialog, showPharmacyDetailDialog, closePharmacyDialog] =
    useDialogModal(QRModal);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    redirectPath(user, navigate);
  }, []);

  useEffect(() => {
    let products = [];
    if (typeof window !== "undefined") {
      if (localStorage.getItem("products")) {
        products = JSON.parse(localStorage.getItem("products"));
      }

      dispatch(addProducts(products));
    }
  }, []);

  useEffect(() => {
    if (isSessionExpired) {
      dispatch(updateSession(false));
    }
  }, []);

  return (
    <>
      <Formik
        initialValues={!is_qr ? initialValues : qrInitialValues}
        enableReinitialize={true}
        onSubmit={(values, { resetForm }) => {
          dispatch(
            pharmacyLoginRequest(
              values,
              navigate,
              toast,
              function (obj) {
                if (obj.response && obj.is_qr) {
                  if (obj?.response?.data?.token != "false") {
                    setQRImage(obj?.response?.data?.token);
                    showPharmacyDetailDialog();
                    setIs_Qr(true);
                  } else {
                    setIs_Qr(true);
                  }
                }
              },
              location
            )
          );
        }}
        validationSchema={!is_qr ? Schema : qrSchema}
      >
        {(props) => (
          <AuthLayout>
            <Box>
              <Box>
                <Typography className="auth-main-heading">
                  {is_qr ? t("SignIn.enterCode") : t("SignIn.welcome")}
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  gutterBottom
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#70747E",
                  }}
                >
                  {is_qr ? t("SignIn.heading1") : t("SignIn.heading2")}
                </Typography>
              </Box>
              <form onSubmit={props.handleSubmit}>
                <Box pt={3}>
                  {!is_qr ? (
                    <>
                      <InputLabel shrink>Email</InputLabel>
                      <TextField
                        fullWidth
                        placeholder={t("newsLetter.enterEmail")}
                        value={props.values.email}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        name="email"
                        error={
                          props.touched.email && Boolean(props.errors.email)
                        }
                        type="email"
                        className="authfield"
                        required
                      />
                      <FErrorMessage name="email" />
                      <Box pt={2}>
                        <InputLabel shrink>{t("Signup.password")}</InputLabel>
                      </Box>
                      <TextField
                        fullWidth
                        placeholder={t("Signup.password")}
                        className="authfield"
                        value={props.values.password}
                        type={showPassword ? "text" : "password"}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        name="password"
                        error={
                          props.touched.password &&
                          Boolean(props.errors.password)
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Box pr={1}>
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <img src={eye} />
                                  ) : (
                                    <img src={eye} />
                                  )}
                                </IconButton>
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                        required
                      />
                      <FErrorMessage name="password" />
                    </>
                  ) : (
                    <>
                      <InputLabel shrink>
                        {t("SignIn.authenticatorCode")}
                      </InputLabel>
                      <TextField
                        fullWidth
                        className="authfield"
                        placeholder={t("SignIn.authenticatorCode")}
                        value={props.values.token}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        name="token"
                        type="text"
                        error={
                          props.touched.token && Boolean(props.errors.token)
                        }
                        required
                      />
                      <FErrorMessage name="token" />
                      <Box
                        sx={{ cursor: "pointer" }}
                        mt={2}
                        onClick={() => showResendQRModalDialog()}
                      >
                        <Typography variant="subtitle2" gutterBottom>
                          {t("SignIn.didn'tGetCode")}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>

                {!is_qr && (
                  <>
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            style={{
                              color: "#235D5E",
                              "&.Mui-checked": {
                                color: "#D3DFDF",
                              },
                            }}
                          />
                        }
                        label={t("SignIn.rememberMe")}
                        sx={{ whiteSpace: "nowrap" }}
                      />
                      <Button
                        sx={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#F04438",
                          textTransform: "capitalize",
                          whiteSpace: "nowrap",
                        }}
                        onClick={() =>
                          navigate("/forgotPassword", { replace: true })
                        }
                      >
                        {t("SignIn.forgetPassword")}
                      </Button>
                    </Box>
                  </>
                )}

                <Button
                  disabled={loading}
                  className="containedPrimaryAuth"
                  size="large"
                  sx={{
                    marginTop: { xs: "50px", sm: "16px" },
                    width: "100% !important",
                  }}
                  variant="contained"
                  onClick={props.handleSubmit}
                >
                  {loading ? (
                    <ClipLoader size={25} color="white" loading />
                  ) : (
                    t("header.login")
                  )}
                </Button>
              </form>
              <Box
                pt={{ xs: 1, sm: 3 }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  color="text.secondary"
                  variant="body"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#70747E",
                  }}
                >
                  {t("SignIn.noHaveAccount")}
                </Typography>
                <Button
                  variant="text"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#03AD54",
                    textTransform: "capitalize",
                  }}
                  onClick={() => navigate("/signup", { replace: true })}
                >
                  {t("SignIn.signup")}
                </Button>
              </Box>
              <QRModalDetailDialog qrImage={qrImg} />
              <ResendQRModalDialog />
            </Box>
          </AuthLayout>
        )}
      </Formik>
    </>
  );
};

export default Signin;
