import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import Footer from "../../../shared/components/footer";
import NewsLetter from "../../../shared/components/footer/newsletter";

import "./blog.scss";
import {
  Card,
  Grid,
  List,
  TextField,
  ListSubheader,
  ListItemButton,
  ListItemText,
  Button,
  IconButton,
  Stack,
} from "@mui/material";
import Vactor from "../../../assets/businessPage/Vactor.svg";
import BlogImage from "../../../assets/businessPage/blog.jpg";
import FaqResponsiveHeader from "../faqHeader";
import { useTranslation } from "react-i18next";

const Blog = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <Container>
      <FaqResponsiveHeader />
      <Container maxWidth={false} className="uniorxLandingPageContainer">
        <Box sx={{ background: "#235d5e" }}>
          <Box
            className="VactorCus"
            sx={{
              backgroundImage: `url(${Vactor})`,
            }}
          >
            <Typography
              color="#ffff"
              className="mainHeading"
              sx={{
                padding: {
                  xs: "20px 15px 10px !important",
                  sm: "32px !important",
                },
              }}
            >
              {t("blogs.blogDetail")}
            </Typography>

            <Typography
              color="#ffff"
              fontSize={"14px !important"}
              fontWeight={200}
              className="mainParagraph"
            >
              {t("blogs.blogDetailDescr")}
            </Typography>
          </Box>
        </Box>
        <Grid mt={5}>
          <Grid container spacing={3} sx={{ position: "relative" }}>
            <Grid
              item
              xs={12}
              sm={8}
              md={8}
              lg={9}
              xl={9}
              sx={{ order: { xs: "1", sm: "1" } }}
            >
              <Box className="blogDetailCardMain">
                <Box className="blogDetailCard">
                  <Box className="imageIcon">
                    <img src={BlogImage} />
                  </Box>
                  <Box className="blogText">
                    <Typography className="heading">
                      {t("blogs.cardTitle")}
                    </Typography>
                    <Typography className="title">
                      {t("blogs.paragraph1")}
                    </Typography>
                    <Typography className="title">
                      {t("blogs.paragraph1")}
                    </Typography>
                    <Typography className="heading">
                      {t("blogs.cardTitle")}
                    </Typography>
                    <Typography className="title">
                      {t("blogs.paragraph1")}
                    </Typography>
                    <Typography className="title">
                      {t("blogs.paragraph1")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={3}
              xl={3}
              sx={{
                order: { xs: "2", sm: "2" },
              }}
            >
              <Box className="blogList">
                <Typography className="listHeading">
                  {t("blogs.relatedBlogs")}
                </Typography>
                <ul>
                  <li>
                    <Typography className="listText">
                      {t("blogs.relatedBlogTitle")}
                    </Typography>
                  </li>
                  <li>
                    <Typography className="listText">
                      {t("blogs.relatedBlogTitle")}
                    </Typography>
                  </li>
                  <li>
                    <Typography className="listText">
                      {t("blogs.relatedBlogTitle")}
                    </Typography>
                  </li>
                  <li>
                    <Typography className="listText">
                      {t("blogs.relatedBlogTitle")}
                    </Typography>
                  </li>
                </ul>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <NewsLetter />
      <Footer />
    </Container>
  );
};
export default Blog;
