import Header from "../../header";
import { Sidebar } from "../../sidebar";
import { SidebarAdmin } from "../../sidebaradmin";
import Box from "@mui/material/Box";
import Footer from "../../footer";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/authContext";
import { useDispatch, useSelector } from "react-redux";

const Layout = (props) => {
  const businessLevelCount = useSelector(
    (state) => state?.businessDashboard?.businessLevelCount?.response?.data
  );

  const { shouldRenderSidebar, shouldRenderAdminSidebar, shouldRenderHeader } =
    useContext(AuthContext);
  const [open, setOpen] = useState(shouldRenderSidebar);
  const [shouldAddMargin, setShouldAddMargin] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    setOpen(matches);
  }, [matches]);

  useEffect(() => {
    let addMargin = false;
    if (businessLevelCount && businessLevelCount?.preOrdersCount > 0) {
      addMargin = true;
    }
    if (businessLevelCount && businessLevelCount?.saleOrdersCount > 0) {
      addMargin = true;
    }
    setShouldAddMargin(addMargin);
  }, [businessLevelCount]);

  return (
    <div>
      {!shouldRenderHeader && (
        <Header setOpen={setOpen} open={open} props={props} matches={matches} />
      )}

      {(open ? shouldRenderSidebar : !shouldRenderSidebar) && (
        <>
          {!shouldRenderAdminSidebar ? (
            <Sidebar setOpen={setOpen} open={open} matches={matches} />
          ) : (
            <>
              <SidebarAdmin setOpen={setOpen} open={open} matches={matches} />
            </>
          )}
        </>
      )}
      <Box
        component="main"
        style={
          shouldAddMargin && !shouldRenderSidebar && !shouldRenderHeader
            ? { marginTop: "6.5rem" }
            : {}
        }
        sx={{
          flexGrow: 1,
          ...((open ? props.sidebar : !props.sidebar) && {
            ml: matches ? 30 : 0,
          }),
        }}
      >
        {props?.children}
      </Box>

      <>{!shouldRenderSidebar && !shouldRenderHeader && <Footer />}</>
    </div>
  );
};

export default Layout;
