import React from "react";
import "./footer.scss";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router";
import { Container } from "@mui/system";
import landingMonitor from "../../../assets/images/landingMonitor.png";
import { useTranslation } from "react-i18next";

const Newsletter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const isproductlistingRoute = location?.pathname?.includes("/productlisting");

  return (
    <Container sx={{ padding: "20px !important" }}>
      {!isproductlistingRoute && (
        <Box className="newsletter">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              sx={{
                textAlign: { xs: "center", md: "center" },
              }}
            >
              <img
                src={landingMonitor}
                style={{
                  objectFit: "cover",
                  maxHeight: "280px",
                  maxWidth: "100%",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className="newsletter-section"
            >
              <Typography
                className="newsletter-heading"
                sx={{ padding: { xs: "0 20px", md: "0" } }}
              >
                {t("newsLetter.ourNewsLetter")}
              </Typography>

              <Box
                display="flex"
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  width: { xs: "100%", md: "70%" },
                  padding: { xs: "0 20px", md: "0" },
                }}
              >
                <TextField
                  id="outlined-basic"
                  placeholder={t("newsLetter.enterEmail")}
                  className="newsletter-input "
                  variant="outlined"
                  inputProps={{
                    style: {
                      color: "#FFFF",
                    },
                  }}
                  sx={{
                    marginRight: "10px",
                    width: "100%",
                    flex: "1",
                    color: "#285D5E",
                    ".MuiOutlinedInput-root .MuiOutlinedInput-input": {
                      color: "#ffff !important",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(255, 255, 255, 0.3) !important",
                    },
                    marginTop: "10px",
                  }}
                />
                <Button
                  className="outlined-white"
                  variant="outlined"
                  color="primary"
                  sx={{
                    marginTop: "10px",
                    height: "40px",
                    backgroundColor: "#fff",
                    padding: "5px 20px !important",
                  }}
                >
                  <Typography
                    className="text-hide"
                    sx={{
                      color: "#101828",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    {t("newsLetter.subscribe")}
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Container>
  );
};

export default Newsletter;
