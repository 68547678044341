import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import Footer from "../../../shared/components/footer";
import NewsLetter from "../../../shared/components/footer/newsletter";

import "./product.scss";
import {
  Card,
  Grid,
  List,
  TextField,
  ListSubheader,
  ListItemButton,
  ListItemText,
  Button,
  IconButton,
  Stack,
} from "@mui/material";
import Vactor from "../../../assets/businessPage/Vactor.svg";
import BlogImage from "../../../assets/businessPage/blog.jpg";
import FaqResponsiveHeader from "../faqHeader";
import { useTranslation } from "react-i18next";

const Product = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const productList = [
    {
      title: "RAMIPRIL",
    },
    {
      title: "MAR-AMLODIPINE",
    },
    {
      title: "TRAJENTA",
    },
    {
      title: "CLINDAMYCIN PHOSPHATE TOPICAL SOLUTION USP",
    },
    {
      title: "AVENTYL",
    },
    {
      title: "ALDACTAZIDE 25",
    },
    {
      title: "ACCUPRIL 5MG TAB",
    },
    {
      title: "METADOL-D",
    },
    {
      title: "APO-ATOMOXETINE",
    },
    {
      title: "CAT IV - DIETARY MIN/DIETARY VIT. SUPPLEMENTS",
    },
    {
      title: "ACT OLANZAPINE ODT",
    },
    {
      title: "APO-MEMANTINE",
    },
    {
      title: "FOQUEST",
    },
    {
      title: "APO-INDAPAMIDE 1.25 MG TABLETS",
    },
    {
      title: "COREG (CARVEDILOL) TABLETS",
    },
    {
      title: "REDDY-DASATINIB",
    },
    {
      title: "ADVIL MINI-GELS",
    },
    {
      title: "ASPIRIN 81MG QUICK CHEWS",
    },
    {
      title: "APO-FLUTICASONE HFA",
    },
    {
      title: "LS - INTESTINAL FLORA MODIFIERS",
    },
  ];

  return (
    <Container>
      <FaqResponsiveHeader />
      <Container maxWidth={false} className="uniorxLandingPageContainer ">
        <Box sx={{ background: "#235d5e" }}>
          <Box
            className="VactorCus"
            sx={{
              backgroundImage: `url(${Vactor})`,
            }}
          >
            <Typography
              color="#ffff"
              className="mainHeading"
              sx={{
                padding: {
                  xs: "20px 15px 10px !important",
                  sm: "32px !important",
                },
              }}
            >
              {t("Products.title")}
            </Typography>

            <Typography
              color="#ffff"
              fontSize={"14px !important"}
              fontWeight={200}
              className="mainParagraph"
            >
              {t("Products.heading")}
            </Typography>
          </Box>
        </Box>
        <Grid mt={2}>
          <Box className="blogCardMains">
            <Grid container spacing={0}>
              {productList?.map((item) => (
                <>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    lg={2.4}
                    onClick={() => navigate("/login")}
                  >
                    <Card raised className="category-card">
                      <Grid item xs={12}>
                        <Box className="carousel-img-container-category">
                          <img src={BlogImage} />
                        </Box>
                      </Grid>
                      <Box pt={1}>
                        <Typography
                          className="category-text text-ellipses"
                          title={item.title}
                        >
                          {item.title}
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                </>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Container>
      <NewsLetter />
      <Footer />
    </Container>
  );
};
export default Product;
