import * as Yup from "yup";

export const initialValues = {
  DIN_NO: "",
};

export const Schema = Yup.object().shape({
  DIN_NO: Yup.string()
    .max(255)
    .required("Drug identification number is required"),
});
