import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Footer from "../../shared/components/footer";
import NewsLetter from "../../shared/components/footer/newsletter";
import { useNavigate } from "react-router-dom";
import { redirectPath } from "../../helpers/redirectPath";
import { useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Container } from "@mui/system";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/free-mode/free-mode.min.css";
import "swiper/modules/thumbs/thumbs.min.css";
import FaqResponsiveHeader from "./faqHeader";
import Icon1 from "../../assets/businessPage/crossCirclefill.svg";
import Icon2 from "../../assets/businessPage/questionmarkApp.svg";
import Icon3 from "../../assets/businessPage/questionmarkApp1.svg";
import { Card, IconButton, Stack } from "@mui/material";
import Pagination from "../../shared/components/Pagination";
import SearchIcon from "@mui/icons-material/Search";
import Vactor from "../../assets/businessPage/Vactor.svg";
import { useTranslation } from "react-i18next";

const Faq = () => {
  const { t } = useTranslation();
  const itemsPerPage = 5;
  const dummyFAQs = [
    {
      question: "faq.question1",
      answer: "faq.question1",
    },
    {
      question: "faq.question2",
      answer: "faq.question2",
    },
    {
      question: "faq.question3",
      answer: "faq.question3",
    },
    {
      question: "faq.question4",
      answer: "faq.question4",
    },
    {
      question: "faq.question5",
      answer: "faq.question5",
    },
    {
      question: "faq.question1",
      answer: "faq.question1",
    },
    {
      question: "faq.question2",
      answer: "faq.question2",
    },
    {
      question: "faq.question3",
      answer: "faq.question3",
    },
    {
      question: "faq.question4",
      answer: "faq.question4",
    },
    {
      question: "faq.question5",
      answer: "faq.question5",
    },
    {
      question: "faq.question2",
      answer: "faq.question2",
    },
    {
      question: "faq.question3",
      answer: "faq.question3",
    },
    {
      question: "faq.question4",
      answer: "faq.question4",
    },
    {
      question: "faq.question5",
      answer: "faq.question5",
    },
    {
      question: "What types of products can I sell on UnioRx?",
      answer: "What types of products can I sell on UnioRx?",
    },
  ];

  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.auth);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearch = (value) => {
    console.log("Searching for:", value);
  };

  const faqItemsToDisplay = dummyFAQs.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <Container>
      <FaqResponsiveHeader />
      <Container maxWidth={false} className="uniorxLandingPageContainer">
        <Box sx={{ background: "#235d5e" }}>
          <Box
            className="VactorCus"
            sx={{
              backgroundImage: `url(${Vactor})`,
            }}
          >
            <Typography
              color="#ffff"
              className="mainHeading"
              sx={{ padding: "32px !important" }}
            >
              {t("contactUs.title")}
            </Typography>
            <Box
              sx={{ width: { xs: "90%", sm: "60%" } }}
              margin="auto "
              display="flex"
              justifyContent="center"
              mb={5}
            >
              <TextField
                id="search-input"
                placeholder={t("faq.askQuestion")}
                sx={{
                  input: {
                    "&::placeholder": {
                      opacity: 2,
                    },
                  },
                  background: "rgba(255, 255, 255, 0.10)",
                  borderRadius: "4px",
                }}
                fullWidth
                InputLabelProps={{ shrink: false }}
                inputProps={{
                  style: {
                    color: "#ffff",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <IconButton variant="contained">
                        <SearchIcon sx={{ color: "#ffff" }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        sx={{
                          backgroundColor: "white !important",
                          color: "#235d5e",
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                        onClick={() => {
                          const searchValue =
                            document.getElementById("search-input").value;
                          handleSearch(searchValue);
                        }}
                      >
                        {t("faq.search")}
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Typography
              color="#ffff"
              fontSize={"14px !important"}
              fontWeight={200}
              className="mainParagraph"
              paddingBottom={18}
            >
              {t("faq.subTitle")}
            </Typography>
          </Box>
        </Box>
        <Box
          className="carousel-container"
          marginTop="-50px"
          padding="0px 20px"
        >
          <Swiper
            spaceBetween={20}
            slidesPerGroup={4}
            autoHeight={true}
            autoplay={false}
            navigation={true}
            modules={[Autoplay, Navigation]}
            rewind={true}
            loop={true}
            className="mySwiper"
            breakpoints={{
              320: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
                slidesPerGroup: 3,
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 5,
                slidesPerGroup: 5,
                slidesPerView: 5,
              },
              1199: {
                slidesPerView: 5,
                slidesPerGroup: 5,
                slidesPerView: 5,
              },
            }}
          >
            <SwiperSlide>
              <Card
                raised
                sx={{
                  minHeight: "110px",
                  maxHeight: "110px",
                  border: "none",
                  borderRadius: "10px",
                  backgroundColor: "#ffff !important",
                  boxShadow: "0px 2px 70px rgba(0, 0, 0, 0.2) !important",
                }}
              >
                <Box textAlign="center">
                  {<img src={Icon3} style={{ height: "50px" }} />}
                </Box>
                <Typography
                  sx={{
                    overflow: "hidden",
                    textAlign: "center",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    color: "#000000",
                    WebkitLineClamp: "3",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  UnioRx App
                </Typography>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card
                raised
                sx={{
                  minHeight: "110px",
                  maxHeight: "110px",
                  border: "none",
                  borderRadius: "10px",
                  backgroundColor: "#ffff !important",
                  boxShadow: "0px 2px 70px rgba(0, 0, 0, 0.2) !important",
                }}
              >
                <Box textAlign="center">
                  {<img src={Icon1} style={{ height: "50px" }} />}
                </Box>
                <Typography
                  sx={{
                    overflow: "hidden",
                    textAlign: "center",
                    textOverflow: "ellipsis",
                    color: "#000000",
                    display: "-webkit-box",
                    WebkitLineClamp: "3",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {t("faq.medicalEquipment")}
                </Typography>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card
                raised
                sx={{
                  minHeight: "110px",
                  maxHeight: "110px",
                  border: "1px solid green",
                  borderRadius: "10px",
                  backgroundColor: "#235d5e !important",
                  boxShadow: "0px 2px 70px rgba(0, 0, 0, 0.2) !important",
                }}
              >
                <Box textAlign="center">
                  {<img src={Icon2} style={{ height: "50px" }} />}
                </Box>
                <Typography
                  sx={{
                    overflow: "hidden",
                    textAlign: "center",
                    textOverflow: "ellipsis",
                    color: "#ffff",
                    display: "-webkit-box",
                    WebkitLineClamp: "3",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {t("faq.generalQuestions")}
                </Typography>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card
                raised
                sx={{
                  minHeight: "110px",
                  maxHeight: "110px",
                  border: "none",
                  borderRadius: "10px",
                  backgroundColor: "#ffff !important",
                  boxShadow: "0px 2px 70px rgba(0, 0, 0, 0.2) !important",
                }}
              >
                <Box textAlign="center">
                  {<img src={Icon3} style={{ height: "50px" }} />}
                </Box>
                <Typography
                  sx={{
                    overflow: "hidden",
                    textAlign: "center",
                    textOverflow: "ellipsis",
                    color: "#000000",
                    display: "-webkit-box",
                    WebkitLineClamp: "3",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {t("faq.usingAccount")}
                </Typography>
              </Card>
            </SwiperSlide>
            <SwiperSlide>
              <Card
                raised
                sx={{
                  minHeight: "110px",
                  maxHeight: "110px",
                  border: "none",
                  borderRadius: "10px",
                  backgroundColor: "#ffff !important",
                  boxShadow: "0px 2px 30px rgba(0, 0, 0, 0.2) !important",
                }}
              >
                <Box textAlign="center">
                  {<img src={Icon1} style={{ height: "50px" }} />}
                </Box>
                <Typography
                  sx={{
                    overflow: "hidden",
                    textAlign: "center",
                    textOverflow: "ellipsis",
                    color: "#000000",
                    display: "-webkit-box",
                    WebkitLineClamp: "3",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {t("faq.usingAccount")}
                </Typography>
              </Card>
            </SwiperSlide>
          </Swiper>
        </Box>
        <Box textAlign="center" pt={8}>
          <Typography fontSize={24} fontWeight={500} mb={2}>
            {t("faq.generalQuestions")}
          </Typography>
          <Typography
            color="#70747E"
            sx={{ width: { xs: "100%", sm: "40%" } }}
            margin="auto"
          >
            {t("contactUs.Details")}
          </Typography>
        </Box>
        <Box sx={{ padding: { xs: "10px 0px", sm: "40px 120px" } }}>
          {faqItemsToDisplay.map((faq, index) => (
            <Accordion key={index} sx={{ boxShadow: "none" }}>
              <AccordionSummary
                aria-controls={`faq-panel-${index}`}
                id={`faq-header-${index}`}
                sx={{ padding: "16px 0px" }}
              >
                <Typography fontWeight={600} width={{ xs: "100%", md: "70%" }}>
                  {t(faq?.question)}
                </Typography>
                <Box display="flex" style={{ marginLeft: "auto" }}>
                  <Typography
                    color="#03AD54"
                    fontSize="14px"
                    sx={{
                      textTransform: "capitalize",
                      display: { xs: "none", md: "block" },
                    }}
                  >
                    {t("faq.showAnswer")}
                  </Typography>
                  <img src={Icon1} className="cusImg" />
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{t(faq?.answer)}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}

          <Stack spacing={2} sx={{ alignItems: "center", marginTop: "40px" }}>
            <Pagination
              totalCount={Math.ceil(dummyFAQs.length / itemsPerPage)}
              page={currentPage}
              onPageChange={handleChangePage}
            />
          </Stack>
        </Box>
      </Container>
      <NewsLetter />
      <Footer />
    </Container>
  );
};
export default Faq;
