import React from "react";
import "./style.scss";
import { Button, Typography, Box } from "@mui/material";

const Index = (props) => {
  return (
    <Box className="signature-prev-container">
      <Typography color={"#101828"} fontSize={14} fontWeight={500}>
        Signatures
      </Typography>
      <Box className="signature-prev-image">
        <img src={props?.url} alt="nexus" />
        {props?.delete ? (
          <Button
            className="prev-img-clr outlined-white"
            variant="outlined"
            color="error"
            size="small"
            onClick={() => props?.handleDelete()}
          >
            Delete
          </Button>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

export default Index;
