import React from "react";
import "./authLayout.scss";
import { Box, Grid, Typography } from "@mui/material";
import main from "../../../assets/images/main.png";
import logo from "../../../assets/images/newLogo.svg";
import { useLocation, useNavigate } from "react-router";
import LanguageToggle from "../../../../LanguageToggle";
import { useTranslation } from "react-i18next";

const AuthLayout = ({ children }) => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const signUpPage = location?.pathname?.includes("signup");
  const signInPage = location?.pathname?.includes("login");

  return (
    <Box className={signInPage && signInPage ? "loginStyle" : ""}>
      <Box textAlign="right" pt={1}>
        <LanguageToggle className={"language-toggle"} langColor="#00000" />
      </Box>
      <Box
        className="auth-container"
        margin="0px auto"
        maxWidth="1200px"
        sx={{ padding: { xs: "20px", sm: "0px" } }}
        width="100%"
      >
        <Box justifyContent="center" display="flex" alignItems=" center">
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                // height="100vh"
                minHeight="100%"
              >
                <Box
                  onClick={() => {
                    navigate("/");
                  }}
                  sx={{ cursor: "pointer" }}
                  className={
                    signUpPage && signUpPage
                      ? "logoContainerSignUp"
                      : "logoContainer"
                  }
                >
                  <img src={logo} className="logo" />
                  <Typography className="logoText">UnioRx</Typography>
                </Box>
                {signUpPage ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    {children}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    {children}
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid
              item
              md={6}
              textAlign="right"
              alignSelf="start"
              // alignSelf="center"
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <Box className="loginImg">
                <img src={main} className="main" />
                <Box className="inner">
                  <Typography className="head">
                    {t("landingPage.title") + " "}
                    {t("landingPage.title1")}
                  </Typography>
                  <Typography className="text">
                    {t("landingPage.subTitle") + " "}
                    {t("landingPage.subTitle1")}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default AuthLayout;
