import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ResponsiveAppBar from "./businessHeader";
import { Tab, Tabs } from "@mui/material";
import Footer from "../../shared/components/footer";
import NewsLetter from "../../shared/components/footer/newsletter";
import { useNavigate } from "react-router-dom";
import { redirectPath } from "../../helpers/redirectPath";
import { useSelector } from "react-redux";
import dashboardIcon from "../../assets/images/dashboardIcon.svg";
import bulletsIcon from "../../assets/images/bulletStarIcon.svg";
import "./business.scss";
import { Container } from "@mui/system";
import ViewCard from "../viewCart/ViewCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/free-mode/free-mode.min.css";
import "swiper/modules/thumbs/thumbs.min.css";
import BusinessPageCard from "./businessPageCard";
import FirstImg from "../../assets/images/landingPageImage.svg";
import landingBag from "../../assets/images/landingBag.png";
import landingCapsule from "../../assets/images/landingCapsule.png";
import landingMedi from "../../assets/images/landingMedi.png";
import MicrosoftImg from "../../assets/images/MicrosoftTeams-image.png";
import landingMedical1 from "../../assets/images/landingMedical1.png";
import landingPlus from "../../assets/images/landingPlus.png";
import landingTube from "../../assets/images/landingTube.png";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const NexusBusiness = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.auth);
  const [selectedTab, setSelectedTab] = useState(0);

  const products = [
    {
      _id: "64e458d0a72f6ab5ab43ac5f",
      product_name: "RIO-BRONZE SPF 30 TOWELETTES",
      description:
        "RIO-BRONZE SPF 30 TOWELETTES. DIN : 02248248 Electronic product monograph is not available. Company: ROGER'S AGENCIES INCORPORATED 9 56 Pennysylvania Avenue",
      brand: "ROGERS AGENCIES INCORPORATED",
      imageCover: {
        full_image:
          "https://firebasestorage.googleapis.com/v0/b/nxus-8dc57.appspot.com/o/pharmacy%2Fproducts%2F61FCw37emdL._AC_UF1000%2C1000_QL80_.jpg-1692686527981?alt=media&token=9103d26a-95d5-4ad7-85f8-2d82e3a9e9bd",
      },
      store: [
        {
          _id: "64dd57abaea55fa8cd523586",
          store_name: "twasa",
          business: "64dd57a9aea55fa8cd523582",
          city: "Calgary",
          status: "approved",
          uuid: "Zxo7KmWdly",
        },
      ],
      PRODUCT_CATEGORIZATION: "CAT IV - SUNBURN PROTECTANTS",
      DRUG_IDENTIFICATION_NUMBER: "02248248",
      createdAt: "2023-08-22T06:42:24.104Z",
      updatedAt: "2023-08-22T06:42:24.104Z",
      total: 3271,
      price: 37,
      product: [
        {
          _id: "64e458d0a72f6ab5ab43ac64",
          product: "64e458d0a72f6ab5ab43ac5f",
          batch_number: "43093958238",
          store: "64dd57abaea55fa8cd523586",
          market_place_status: "enlisted",
          discount: {
            isAutomatedDiscountApplied: false,
            discountsArray: [],
            isManualDiscountApplied: false,
          },
          quantity: 3271,
          expiry_date: "2025-12-31T19:00:00.000Z",
          type: "self",
          isActive: true,
          price: 37,
          faqs: [],
          DIN_NUMBER: "02248248",
          createdAt: "2023-08-22T06:42:24.429Z",
          updatedAt: "2023-09-13T15:55:57.715Z",
          __v: 0,
        },
      ],
      inventory: {
        _id: "64e458d0a72f6ab5ab43ac64",
        product: "64e458d0a72f6ab5ab43ac5f",
        batch_number: "43093958238",
        store: "64dd57abaea55fa8cd523586",
        market_place_status: "enlisted",
        discount: {
          isAutomatedDiscountApplied: false,
          discountsArray: [],
          isManualDiscountApplied: false,
        },
        quantity: 3271,
        expiry_date: "2025-12-31T19:00:00.000Z",
        type: "self",
        isActive: true,
        price: 37,
        faqs: [],
        DIN_NUMBER: "02248248",
        createdAt: "2023-08-22T06:42:24.429Z",
        updatedAt: "2023-09-13T15:55:57.715Z",
        __v: 0,
      },
    },
    {
      _id: "64e30f0f4483ce2e75d05e77",
      product_name: "TARO-CLOBETASOL CREAM USP",
      description:
        "Clobetasol is a topical (skin-applied) corticosteroid medication used to treat skin conditions that have not responded well to other corticosteroids, including moderate to severe psoriasis and eczematous dermatitis.",
      brand: "TARO PHARMACEUTICALS INC",
      imageCover: {
        full_image:
          "https://firebasestorage.googleapis.com/v0/b/nxus-8dc57.appspot.com/o/pharmacy%2Fproducts%2F1258010.png-1692602068208?alt=media&token=54158c21-a5e7-416f-89e3-37c0d4917461",
      },
      store: [
        {
          _id: "64da3db239ada3e2118b00d1",
          store_name: "pharma club",
          business: "64da3db139ada3e2118b00cd",
          city: "Calgary",
          status: "approved",
          uuid: "iwdoQGbkbF",
        },
      ],
      PRODUCT_CATEGORIZATION: "other",
      DRUG_IDENTIFICATION_NUMBER: "02245523",
      createdAt: "2023-08-21T07:15:27.392Z",
      updatedAt: "2023-08-21T13:48:09.199Z",
      total: 5438,
      price: 32,
      product: [
        {
          _id: "64e30f0f4483ce2e75d05e7c",
          product: "64e30f0f4483ce2e75d05e77",
          batch_number: "675432425235",
          store: "64da3db239ada3e2118b00d1",
          market_place_status: "enlisted",
          discount: {
            isAutomatedDiscountApplied: true,
            discountsArray: [
              {
                month: 3,
                discount: 30,
                _id: "64e30f0f4483ce2e75d05e7d",
              },
            ],
            isManualDiscountApplied: false,
          },
          quantity: 5438,
          expiry_date: "2026-01-20T19:00:00.000Z",
          type: "self",
          isActive: true,
          price: 32,
          faqs: [
            {
              question: "Is clobetasol bad for skin?",
              answer:
                "You're more likely to have a serious side effect if you use clobetasol on a large area of skin for a long time. Clobetasol cream contains cetostearyl alcohol, which may cause a skin reaction in the area you are treating.",
              id: "X0MnkXeN",
            },
            {
              question: "How long to use taro clobetasol?",
              answer:
                "Do not use clobetasol for more than 4 weeks without talking to a doctor. If you need treatment for a long time, a doctor may recommend that you use a milder cream or ointment.",
              id: "9QY0O4VL",
            },
            {
              question: "Can I use Taro clobetasol on my face?",
              answer:
                "This medicine should not be used to treat certain kinds of skin infections or conditions, such as severe burns. Do not use this medicine on the face, groin, or underarms, or if you have skin thinning, unless directed to do so by your doctor.",
              id: "Lat7xFl2",
            },
          ],
          DIN_NUMBER: "02245523",
          createdAt: "2023-08-21T07:15:27.806Z",
          updatedAt: "2023-09-14T06:53:23.912Z",
          __v: 0,
        },
      ],
      inventory: {
        _id: "64e30f0f4483ce2e75d05e7c",
        product: "64e30f0f4483ce2e75d05e77",
        batch_number: "675432425235",
        store: "64da3db239ada3e2118b00d1",
        market_place_status: "enlisted",
        discount: {
          isAutomatedDiscountApplied: true,
          discountsArray: [
            {
              month: 3,
              discount: 30,
              _id: "64e30f0f4483ce2e75d05e7d",
            },
          ],
          isManualDiscountApplied: false,
        },
        quantity: 5438,
        expiry_date: "2026-01-20T19:00:00.000Z",
        type: "self",
        isActive: true,
        price: 32,
        faqs: [
          {
            question: "Is clobetasol bad for skin?",
            answer:
              "You're more likely to have a serious side effect if you use clobetasol on a large area of skin for a long time. Clobetasol cream contains cetostearyl alcohol, which may cause a skin reaction in the area you are treating.",
            id: "X0MnkXeN",
          },
          {
            question: "How long to use taro clobetasol?",
            answer:
              "Do not use clobetasol for more than 4 weeks without talking to a doctor. If you need treatment for a long time, a doctor may recommend that you use a milder cream or ointment.",
            id: "9QY0O4VL",
          },
          {
            question: "Can I use Taro clobetasol on my face?",
            answer:
              "This medicine should not be used to treat certain kinds of skin infections or conditions, such as severe burns. Do not use this medicine on the face, groin, or underarms, or if you have skin thinning, unless directed to do so by your doctor.",
            id: "Lat7xFl2",
          },
        ],
        DIN_NUMBER: "02245523",
        createdAt: "2023-08-21T07:15:27.806Z",
        updatedAt: "2023-09-14T06:53:23.912Z",
        __v: 0,
        discountedPrice: {
          discountedPrice: "32.00",
          discountPercentage: "0%",
        },
      },
    },
    {
      _id: "64db2fdcac242277fff21571",
      product_name: "APO-OMEPRAZOLE",
      description:
        "It slows or prevents the production of acid within the stomach. ... It is also used in combination with antibiotics to treat stomach ulcers caused by H. pylori",
      brand: "APOTEX INC",
      imageCover: {
        full_image:
          "https://firebasestorage.googleapis.com/v0/b/nxus-8dc57.appspot.com/o/pharmacy%2Fproducts%2F810251160.webp-1692086227076?alt=media&token=868b6b1b-50a1-4d0a-8a50-b314b050b415",
      },
      store: [
        {
          _id: "64db29d4ac242277fff2136b",
          store_name: "beacon",
          business: "64db29d3ac242277fff21367",
          city: "Calgary",
          status: "approved",
          uuid: "4vrMR7O5G2",
        },
      ],
      PRODUCT_CATEGORIZATION: "other",
      DRUG_IDENTIFICATION_NUMBER: "02245058",
      createdAt: "2023-08-15T07:57:16.191Z",
      updatedAt: "2023-08-15T07:57:16.191Z",
      total: 842,
      price: 50,
      product: [
        {
          _id: "64db2fdcac242277fff21576",
          product: "64db2fdcac242277fff21571",
          batch_number: "345436457235",
          store: "64db29d4ac242277fff2136b",
          market_place_status: "enlisted",
          discount: {
            isAutomatedDiscountApplied: false,
            discountsArray: [],
            isManualDiscountApplied: false,
          },
          quantity: 842,
          expiry_date: "2030-01-23T19:00:00.000Z",
          type: "self",
          isActive: true,
          price: 50,
          faqs: [],
          DIN_NUMBER: "02245058",
          createdAt: "2023-08-15T07:57:16.289Z",
          updatedAt: "2023-09-21T06:20:17.410Z",
          __v: 0,
        },
      ],
      inventory: {
        _id: "64db2fdcac242277fff21576",
        product: "64db2fdcac242277fff21571",
        batch_number: "345436457235",
        store: "64db29d4ac242277fff2136b",
        market_place_status: "enlisted",
        discount: {
          isAutomatedDiscountApplied: false,
          discountsArray: [],
          isManualDiscountApplied: false,
        },
        quantity: 842,
        expiry_date: "2030-01-23T19:00:00.000Z",
        type: "self",
        isActive: true,
        price: 50,
        faqs: [],
        DIN_NUMBER: "02245058",
        createdAt: "2023-08-15T07:57:16.289Z",
        updatedAt: "2023-09-21T06:20:17.410Z",
        __v: 0,
      },
    },
    {
      _id: "64db2f57ac242277fff21556",
      product_name: "VIAGRA",
      description:
        "Sildenafil is used to treat male sexual function problems (impotence or erectile dysfunction-ED). In combination with sexual stimulation, sildenafil works",
      brand: "UPJOHN CANADA ULC",
      imageCover: {
        full_image:
          "https://firebasestorage.googleapis.com/v0/b/nxus-8dc57.appspot.com/o/pharmacy%2Fproducts%2FVIAGRA-100MG-4S-600x600.jpg-1692086078926?alt=media&token=cff642a3-363a-4b24-a889-b3539c24161b",
      },
      store: [
        {
          _id: "64db29d4ac242277fff2136b",
          store_name: "beacon",
          business: "64db29d3ac242277fff21367",
          city: "Calgary",
          status: "approved",
          uuid: "4vrMR7O5G2",
        },
      ],
      PRODUCT_CATEGORIZATION: "other",
      DRUG_IDENTIFICATION_NUMBER: "02239766",
      createdAt: "2023-08-15T07:55:03.748Z",
      updatedAt: "2023-08-22T21:51:45.553Z",
      total: 2265,
      price: 42,
      product: [
        {
          _id: "64db2f57ac242277fff2155b",
          product: "64db2f57ac242277fff21556",
          batch_number: "345346546362",
          store: "64db29d4ac242277fff2136b",
          market_place_status: "enlisted",
          discount: {
            isAutomatedDiscountApplied: true,
            discountsArray: [
              {
                month: 2,
                discount: 50,
                _id: "64e52e3d8a682f4b6c027b49",
              },
            ],
          },
          quantity: 2265,
          expiry_date: "2023-10-30T19:00:00.000Z",
          type: "self",
          isActive: true,
          price: 42,
          faqs: [],
          DIN_NUMBER: "02239766",
          createdAt: "2023-08-15T07:55:03.846Z",
          updatedAt: "2023-09-22T12:45:57.050Z",
          __v: 0,
        },
      ],
      inventory: {
        _id: "64db2f57ac242277fff2155b",
        product: "64db2f57ac242277fff21556",
        batch_number: "345346546362",
        store: "64db29d4ac242277fff2136b",
        market_place_status: "enlisted",
        discount: {
          isAutomatedDiscountApplied: true,
          discountsArray: [
            {
              month: 2,
              discount: 50,
              _id: "64e52e3d8a682f4b6c027b49",
            },
          ],
        },
        quantity: 2265,
        expiry_date: "2023-10-30T19:00:00.000Z",
        type: "self",
        isActive: true,
        price: 42,
        faqs: [],
        DIN_NUMBER: "02239766",
        createdAt: "2023-08-15T07:55:03.846Z",
        updatedAt: "2023-09-22T12:45:57.050Z",
        __v: 0,
        discountedPrice: {
          discountedPrice: "21.00",
          discountPercentage: "50%",
        },
      },
    },
    {
      _id: "64db2eddac242277fff2153b",
      product_name: "SPECTAM WATER SOLUBLE CONC 500MG/GM",
      description:
        "Buy SPECTAM WATER SOLUBLE CONC 500MG/GM Powder For Solution by P.V.U., DIVISION OF Vu00c9TOQUINOL N.-A. INC.. Get quick delivery of medicines online from ",
      brand: "P.V.U.",
      imageCover: {
        full_image:
          "https://firebasestorage.googleapis.com/v0/b/nxus-8dc57.appspot.com/o/pharmacy%2Fproducts%2Fwater-soluble-cbd-500mg-800x800.pngw3.webp-1692085970653?alt=media&token=0f648d16-6f96-49b6-8408-dea29647f10d",
      },
      store: [
        {
          _id: "64db29d4ac242277fff2136b",
          store_name: "beacon",
          business: "64db29d3ac242277fff21367",
          city: "Calgary",
          status: "approved",
          uuid: "4vrMR7O5G2",
        },
      ],
      PRODUCT_CATEGORIZATION: "other",
      DRUG_IDENTIFICATION_NUMBER: "00565873",
      createdAt: "2023-08-15T07:53:01.607Z",
      updatedAt: "2023-08-15T07:53:01.607Z",
      total: 34604,
      price: 55,
      product: [
        {
          _id: "64db2eddac242277fff21540",
          product: "64db2eddac242277fff2153b",
          batch_number: "65423456764",
          store: "64db29d4ac242277fff2136b",
          market_place_status: "enlisted",
          discount: {
            isAutomatedDiscountApplied: false,
            discountsArray: [],
            isManualDiscountApplied: false,
          },
          quantity: 34604,
          expiry_date: "2026-08-18T19:00:00.000Z",
          type: "self",
          isActive: true,
          price: 55,
          faqs: [],
          DIN_NUMBER: "00565873",
          createdAt: "2023-08-15T07:53:01.705Z",
          updatedAt: "2023-09-22T19:03:54.470Z",
          __v: 0,
        },
      ],
      inventory: {
        _id: "64db2eddac242277fff21540",
        product: "64db2eddac242277fff2153b",
        batch_number: "65423456764",
        store: "64db29d4ac242277fff2136b",
        market_place_status: "enlisted",
        discount: {
          isAutomatedDiscountApplied: false,
          discountsArray: [],
          isManualDiscountApplied: false,
        },
        quantity: 34604,
        expiry_date: "2026-08-18T19:00:00.000Z",
        type: "self",
        isActive: true,
        price: 55,
        faqs: [],
        DIN_NUMBER: "00565873",
        createdAt: "2023-08-15T07:53:01.705Z",
        updatedAt: "2023-09-22T19:03:54.470Z",
        __v: 0,
      },
    },
    {
      _id: "64db2e5fac242277fff21515",
      product_name: "VITAMIN C 500MG WITH NON-MEDICINAL ECHINACEA",
      description:
        "Vitamins & minerals are essential nutrients that perform hundreds of important roles in the body. From boosting immunity to regulating metabolism,",
      brand: "HOLISTA HEALTH (CANADA) INC.",
      imageCover: {
        full_image:
          "https://firebasestorage.googleapis.com/v0/b/nxus-8dc57.appspot.com/o/pharmacy%2Fproducts%2F2157816-The-7-Best-Vitamins-and-Supplements-for-Stress-According-to-Dietitians-1296x728-Header-81b9bf.webp-1692085841064?alt=media&token=58147049-d029-418f-84c3-ca182d0f845a",
      },
      store: [
        {
          _id: "64db29d4ac242277fff2136b",
          store_name: "beacon",
          business: "64db29d3ac242277fff21367",
          city: "Calgary",
          status: "approved",
          uuid: "4vrMR7O5G2",
        },
      ],
      PRODUCT_CATEGORIZATION: "LS - VITAMIN SUPPLEMENTS",
      DRUG_IDENTIFICATION_NUMBER: "02231941",
      createdAt: "2023-08-15T07:50:55.483Z",
      updatedAt: "2023-08-15T07:50:55.483Z",
      total: 7552,
      price: 87,
      product: [
        {
          _id: "64db2e5fac242277fff2151a",
          product: "64db2e5fac242277fff21515",
          batch_number: "3453453623424",
          store: "64db29d4ac242277fff2136b",
          market_place_status: "enlisted",
          discount: {
            isAutomatedDiscountApplied: false,
            discountsArray: [],
            isManualDiscountApplied: false,
          },
          quantity: 7552,
          expiry_date: "2026-01-28T19:00:00.000Z",
          type: "self",
          isActive: true,
          price: 87,
          faqs: [],
          DIN_NUMBER: "02231941",
          createdAt: "2023-08-15T07:50:55.607Z",
          updatedAt: "2023-09-20T15:32:48.176Z",
          __v: 0,
        },
      ],
      inventory: {
        _id: "64db2e5fac242277fff2151a",
        product: "64db2e5fac242277fff21515",
        batch_number: "3453453623424",
        store: "64db29d4ac242277fff2136b",
        market_place_status: "enlisted",
        discount: {
          isAutomatedDiscountApplied: false,
          discountsArray: [],
          isManualDiscountApplied: false,
        },
        quantity: 7552,
        expiry_date: "2026-01-28T19:00:00.000Z",
        type: "self",
        isActive: true,
        price: 87,
        faqs: [],
        DIN_NUMBER: "02231941",
        createdAt: "2023-08-15T07:50:55.607Z",
        updatedAt: "2023-09-20T15:32:48.176Z",
        __v: 0,
      },
    },
    {
      _id: "64db2d6bac242277fff21478",
      product_name: "JOHNSON & JOHNSON ANTISEPTIC WIPE",
      description:
        "In my first aid kit I had a similar wipe made by Johnson & Johnson and it cleansed the wound beautifully without the stinging, painful agony of an alcohol wipe.",
      brand: "JOHNSON & JOHNSON INC",
      imageCover: {
        full_image:
          "https://firebasestorage.googleapis.com/v0/b/nxus-8dc57.appspot.com/o/pharmacy%2Fproducts%2F108690.jpg-1692085603090?alt=media&token=6aa7400b-a42b-4c94-b4a4-4671e1efd318",
      },
      store: [
        {
          _id: "64da3db239ada3e2118b00d1",
          store_name: "pharma club",
          business: "64da3db139ada3e2118b00cd",
          city: "Calgary",
          status: "approved",
          uuid: "iwdoQGbkbF",
        },
      ],
      PRODUCT_CATEGORIZATION: "CAT IV",
      DRUG_IDENTIFICATION_NUMBER: "02231916",
      createdAt: "2023-08-15T07:46:51.232Z",
      updatedAt: "2023-08-21T13:47:47.545Z",
      total: 45615,
      price: 65,
      product: [
        {
          _id: "64db2d6bac242277fff2147d",
          product: "64db2d6bac242277fff21478",
          batch_number: "4364232353467",
          store: "64da3db239ada3e2118b00d1",
          market_place_status: "enlisted",
          discount: {
            isAutomatedDiscountApplied: false,
            discountsArray: [],
            isManualDiscountApplied: false,
          },
          quantity: 45615,
          expiry_date: "2049-01-20T19:00:00.000Z",
          type: "self",
          isActive: true,
          price: 65,
          faqs: [],
          DIN_NUMBER: "02231916",
          createdAt: "2023-08-15T07:46:51.331Z",
          updatedAt: "2023-09-21T12:52:52.416Z",
          __v: 0,
        },
      ],
      inventory: {
        _id: "64db2d6bac242277fff2147d",
        product: "64db2d6bac242277fff21478",
        batch_number: "4364232353467",
        store: "64da3db239ada3e2118b00d1",
        market_place_status: "enlisted",
        discount: {
          isAutomatedDiscountApplied: false,
          discountsArray: [],
          isManualDiscountApplied: false,
        },
        quantity: 45615,
        expiry_date: "2049-01-20T19:00:00.000Z",
        type: "self",
        isActive: true,
        price: 65,
        faqs: [],
        DIN_NUMBER: "02231916",
        createdAt: "2023-08-15T07:46:51.331Z",
        updatedAt: "2023-09-21T12:52:52.416Z",
        __v: 0,
      },
    },
    {
      _id: "64db2d14ac242277fff2145d",
      product_name: "MAGNESIUM PHOSPHORICUM II SIMILIAPLEX",
      description:
        "Magnesium phosphoricum is a great anti-spasmodic remedy indicated in cramps, neuralgic pains, and radiating pains which are relieved by warmth.",
      brand: "SISU INC.",
      imageCover: {
        full_image:
          "https://firebasestorage.googleapis.com/v0/b/nxus-8dc57.appspot.com/o/pharmacy%2Fproducts%2Fbioplasgen-no-24.jpg-1692085513486?alt=media&token=20e996aa-bd27-49dc-ad30-bbb4a7505e66",
      },
      store: [
        {
          _id: "64da3db239ada3e2118b00d1",
          store_name: "pharma club",
          business: "64da3db139ada3e2118b00cd",
          city: "Calgary",
          status: "approved",
          uuid: "iwdoQGbkbF",
        },
      ],
      PRODUCT_CATEGORIZATION: "other",
      DRUG_IDENTIFICATION_NUMBER: "02231876",
      createdAt: "2023-08-15T07:45:24.598Z",
      updatedAt: "2023-08-15T07:45:24.598Z",
      total: 4330,
      price: 34,
      product: [
        {
          _id: "64db2d14ac242277fff21462",
          product: "64db2d14ac242277fff2145d",
          batch_number: "75645634523",
          store: "64da3db239ada3e2118b00d1",
          market_place_status: "enlisted",
          discount: {
            isAutomatedDiscountApplied: false,
            discountsArray: [],
            isManualDiscountApplied: false,
          },
          quantity: 4330,
          expiry_date: "2026-01-21T19:00:00.000Z",
          type: "self",
          isActive: true,
          price: 34,
          faqs: [],
          DIN_NUMBER: "02231876",
          createdAt: "2023-08-15T07:45:24.691Z",
          updatedAt: "2023-09-14T06:55:47.124Z",
          __v: 0,
        },
      ],
      inventory: {
        _id: "64db2d14ac242277fff21462",
        product: "64db2d14ac242277fff2145d",
        batch_number: "75645634523",
        store: "64da3db239ada3e2118b00d1",
        market_place_status: "enlisted",
        discount: {
          isAutomatedDiscountApplied: false,
          discountsArray: [],
          isManualDiscountApplied: false,
        },
        quantity: 4330,
        expiry_date: "2026-01-21T19:00:00.000Z",
        type: "self",
        isActive: true,
        price: 34,
        faqs: [],
        DIN_NUMBER: "02231876",
        createdAt: "2023-08-15T07:45:24.691Z",
        updatedAt: "2023-09-14T06:55:47.124Z",
        __v: 0,
      },
    },
    {
      _id: "64db2cabac242277fff21442",
      product_name: "ORAP TAB 10MG",
      description:
        "Orap 10mg Tablet is used in the treatment of schizophrenia. It works by altering the thoughts and elevating the mood, thereby improving the person's ability to think, feel, and behave.",
      brand: "MCNEIL PHARMACEUTICAL",
      imageCover: {
        full_image:
          "https://firebasestorage.googleapis.com/v0/b/nxus-8dc57.appspot.com/o/pharmacy%2Fproducts%2Fv44a2yt9ee14qomt7fiu.jpg-1692085400312?alt=media&token=886fe615-9475-4c89-8b8a-6eec51125961",
      },
      store: [
        {
          _id: "64da3db239ada3e2118b00d1",
          store_name: "pharma club",
          business: "64da3db139ada3e2118b00cd",
          city: "Calgary",
          status: "approved",
          uuid: "iwdoQGbkbF",
        },
      ],
      PRODUCT_CATEGORIZATION: "other",
      DRUG_IDENTIFICATION_NUMBER: "00573817",
      createdAt: "2023-08-15T07:43:39.208Z",
      updatedAt: "2023-08-15T07:43:39.208Z",
      total: 3413,
      price: 56,
      product: [
        {
          _id: "64db2cabac242277fff21447",
          product: "64db2cabac242277fff21442",
          batch_number: "45345212435",
          store: "64da3db239ada3e2118b00d1",
          market_place_status: "enlisted",
          discount: {
            isAutomatedDiscountApplied: false,
            discountsArray: [],
            isManualDiscountApplied: false,
          },
          quantity: 3413,
          expiry_date: "2049-01-13T19:00:00.000Z",
          type: "self",
          isActive: true,
          price: 56,
          faqs: [],
          DIN_NUMBER: "00573817",
          createdAt: "2023-08-15T07:43:39.307Z",
          updatedAt: "2023-09-14T06:55:47.124Z",
          __v: 0,
        },
      ],
      inventory: {
        _id: "64db2cabac242277fff21447",
        product: "64db2cabac242277fff21442",
        batch_number: "45345212435",
        store: "64da3db239ada3e2118b00d1",
        market_place_status: "enlisted",
        discount: {
          isAutomatedDiscountApplied: false,
          discountsArray: [],
          isManualDiscountApplied: false,
        },
        quantity: 3413,
        expiry_date: "2049-01-13T19:00:00.000Z",
        type: "self",
        isActive: true,
        price: 56,
        faqs: [],
        DIN_NUMBER: "00573817",
        createdAt: "2023-08-15T07:43:39.307Z",
        updatedAt: "2023-09-14T06:55:47.124Z",
        __v: 0,
      },
    },
    {
      _id: "64db29e0ac242277fff21377",
      product_name: "NU-IPRATROPIUM PLASTIC AMPULES 250 MCG/ML",
      description:
        "Nu-ipratropium Plastic Ampules 250 Mcg/ml, Solution, 250 mcg / mL ... Ipratropium Bromide 0.02% Solution Each Box Contains Twenty-Five 2.5ml Vials, 45.86USD",
      brand: "NU-PHARM INC",
      imageCover: {
        full_image:
          "https://firebasestorage.googleapis.com/v0/b/nxus-8dc57.appspot.com/o/pharmacy%2Fproducts%2Fc4-500x500.webp-1692084694138?alt=media&token=65c2e58d-56c7-439d-933c-956ebbf5c136",
      },
      store: [
        {
          _id: "64da3db239ada3e2118b00d1",
          store_name: "pharma club",
          business: "64da3db139ada3e2118b00cd",
          city: "Calgary",
          status: "approved",
          uuid: "iwdoQGbkbF",
        },
      ],
      PRODUCT_CATEGORIZATION: "other",
      DRUG_IDENTIFICATION_NUMBER: "02231785",
      createdAt: "2023-08-15T07:31:44.620Z",
      updatedAt: "2023-08-15T07:31:44.620Z",
      total: 5458,
      price: 24,
      product: [
        {
          _id: "64db29e0ac242277fff2137c",
          product: "64db29e0ac242277fff21377",
          batch_number: "346534643325",
          store: "64da3db239ada3e2118b00d1",
          market_place_status: "enlisted",
          discount: {
            isAutomatedDiscountApplied: false,
            discountsArray: [],
            isManualDiscountApplied: false,
          },
          quantity: 5458,
          expiry_date: "2034-01-03T19:00:00.000Z",
          type: "self",
          isActive: true,
          price: 24,
          faqs: [],
          DIN_NUMBER: "02231785",
          createdAt: "2023-08-15T07:31:44.735Z",
          updatedAt: "2023-09-21T22:18:35.783Z",
          __v: 0,
        },
      ],
      inventory: {
        _id: "64db29e0ac242277fff2137c",
        product: "64db29e0ac242277fff21377",
        batch_number: "346534643325",
        store: "64da3db239ada3e2118b00d1",
        market_place_status: "enlisted",
        discount: {
          isAutomatedDiscountApplied: false,
          discountsArray: [],
          isManualDiscountApplied: false,
        },
        quantity: 5458,
        expiry_date: "2034-01-03T19:00:00.000Z",
        type: "self",
        isActive: true,
        price: 24,
        faqs: [],
        DIN_NUMBER: "02231785",
        createdAt: "2023-08-15T07:31:44.735Z",
        updatedAt: "2023-09-21T22:18:35.783Z",
        __v: 0,
      },
    },
  ];

  const salesAndPurchasesBullets = [
    "landingPage.bp1",
    "landingPage.bp2",
    "landingPage.bp3",
    "landingPage.bp4",
    "landingPage.bp5",
  ];

  useEffect(() => {
    redirectPath(user, navigate);
  }, []);

  const tabChangeHandler = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const tabPanelContent = [
    <Grid container spacing={0} key={0}>
      <Grid item xs={12} md={6} sm={6} sx={{ zIndex: "1" }}>
        <Typography className="B2B-DetailHeading">
          {t("landingPage.exclusiveExchangeTitle")} <br />
          {t("landingPage.exclusiveExchangeTitle1")}
        </Typography>
        <Typography className="B2B-Detail">
          {t("landingPage.exclusiveExchangeDesc")}
        </Typography>
        <Button
          className="contained contained-primary"
          sx={{
            color: "#235d5e !important",
            backgroundColor: "white !important",
            border: "1px solid #235d5e ",
            borderRadius: "6px",
            textTransform: "capitalize",
            display: "flex",
            margin: "30px 0px",
          }}
          onClick={() => {
            navigate("/qr/businesscard");
          }}
        >
          {t("landingPage.learnMore")}
        </Button>
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Box position="relative">
          <img
            src={landingMedi}
            style={{
              objectFit: "cover",
              maxHeight: "310px",
              maxWidth: "100%",
            }}
          />
        </Box>
      </Grid>
    </Grid>,
    <Grid container spacing={0} key={1}>
      <Grid item xs={12} md={6} sm={6} sx={{ zIndex: "1" }}>
        <Typography className="B2B-DetailHeading">
          {t("landingPage.exclusiveExchangeTitle2")} <br />
          {t("landingPage.exclusiveExchangeTitle3")}
        </Typography>
        <Typography className="B2B-Detail">
          {t("landingPage.exclusiveExchangeDesc1")}
        </Typography>
        <Button
          className="contained contained-primary"
          sx={{
            color: "#235d5e !important",
            backgroundColor: "white !important",
            border: "1px solid #235d5e ",
            borderRadius: "6px",
            textTransform: "capitalize",
            display: "flex",
            margin: "30px 0px",
          }}
          onClick={() => {
            navigate("/signup");
          }}
        >
          {t("landingPage.learnMore")}
        </Button>
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Box position="relative" textAlign="center">
          <img src={landingMedical1} />
        </Box>
      </Grid>
    </Grid>,
    <Grid container spacing={0} key={1}>
      <Grid item xs={12} md={6} sm={6} sx={{ zIndex: "1" }}>
        <Typography className="B2B-DetailHeading">
          {t("landingPage.exclusiveExchangeTitle4")} <br />
          {t("landingPage.exclusiveExchangeTitle5")}
        </Typography>
        <Typography className="B2B-Detail">
          {t("landingPage.exclusiveExchangeDesc2")}
        </Typography>
        <Button
          className="contained contained-primary"
          sx={{
            color: "#235d5e !important",
            backgroundColor: "white !important",
            border: "1px solid #235d5e ",
            borderRadius: "6px",
            textTransform: "capitalize",
            display: "flex",
            margin: "30px 0px",
          }}
          onClick={() => {
            navigate("/signup");
          }}
        >
          {t("landingPage.learnMore")}
        </Button>
      </Grid>
      <Grid item xs={12} md={6} sm={6}>
        <Box position="relative" sx={{ textAlign: "center" }}>
          <img
            src={MicrosoftImg}
            style={{
              objectFit: "cover",
              maxHeight: "310px",
              maxWidth: "100%",
            }}
          />
        </Box>
      </Grid>
    </Grid>,
  ];

  return (
    <Container>
      <ResponsiveAppBar />
      <Container maxWidth={false} className="uniorxLandingPageContainer">
        <Box sx={{ background: "#f6f6f6" }}>
          <Typography className="mainHeading">
            {t("landingPage.title")}
            <br />
            {t("landingPage.title1")}
          </Typography>
          <Typography className="mainParagraph">
            {t("landingPage.subTitle")} <br />
            {t("landingPage.subTitle1")}
          </Typography>
          <Box
            sx={{
              display: { xs: "flex", sm: "flex" },
              gridGap: { xs: "10px", sm: "10px" },
              padding: { xs: "0 10px", sm: "0 15px" },
            }}
            justifyContent="center"
          >
            <Button
              className="contained contained-primary"
              sx={{
                padding: { xs: "6px 10px", sm: "6px 26px" },
                fontSize: { xs: "12px", sm: "16px" },
                borderRadius: "6px",
                textTransform: "capitalize",
                minWidth: { xs: "auto", sm: "200px" },
                flex: { xs: 1, sm: 0 },
              }}
              onClick={() => navigate("/signup")}
            >
              {t("landingPage.loginBtn")}
            </Button>
            <Button
              className="contained "
              sx={{
                border: "1px solid #ffc107",
                padding: { xs: "6px 10px", sm: "6px 26px" },
                fontSize: { xs: "12px", sm: "16px" },
                color: "#000000",
                backgroundColor: "#ffc107",
                borderRadius: "6px",
                textTransform: "capitalize",
                minWidth: { xs: "auto", sm: "200px" },
                flex: { xs: 1, sm: 0 },
              }}
              onClick={() => navigate("/qr/businesscard")}
            >
              {t("landingPage.joinforWaiting")}
            </Button>
          </Box>
          <Box>
            <div
              className="carousel-container"
              style={{ marginTop: "20px", color: "#494949" }}
            >
              <Swiper
                spaceBetween={20}
                slidesPerGroup={4}
                autoHeight={true}
                autoplay={false}
                navigation={true}
                modules={[Autoplay, Navigation]}
                rewind={true}
                loop={true}
                className="mySwiper"
                breakpoints={{
                  320: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    slidesPerView: 3,
                  },
                  1024: {
                    slidesPerView: 5,
                    slidesPerGroup: 5,
                    slidesPerView: 5,
                  },
                  1199: {
                    slidesPerView: 5,
                    slidesPerGroup: 5,
                    slidesPerView: 5,
                  },
                }}
              >
                {products &&
                  products?.map((product, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <BusinessPageCard el={product} />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>

              <br />
            </div>
          </Box>
        </Box>

        <Box mt={5} mb={8} className="secondSection">
          <Typography className="mainHeading">
            {t("landingPage.platform")}
          </Typography>
          <Box className="userDashboard">
            <Tabs
              value={selectedTab}
              onChange={tabChangeHandler}
              variant="fullWidth"
              className="cusTab"
              sx={{
                borderRadius: "6px",
                textTransform: "capitalize",
                display: "flex",
                width: "100%",
              }}
            >
              <Tab
                label={t("landingPage.welcome1")}
                sx={{
                  color: "black !important",
                  backgroundColor: "white !important",
                  border: "1px solid #efefef ",
                  borderRadius: "6px",
                  textTransform: "capitalize",
                  display: "flex",
                  width: "100%",
                }}
              />
              <Tab
                label={t("landingPage.welcome2")}
                sx={{
                  color: "black !important",
                  backgroundColor: "white !important",
                  border: "1px solid #efefef ",
                  borderRadius: "6px",
                  textTransform: "capitalize",
                  display: "flex",
                  width: "100%",
                }}
              />
              <Tab
                label={t("landingPage.welcome3")}
                sx={{
                  color: "black !important",
                  backgroundColor: "white !important",
                  border: "1px solid #efefef ",
                  borderRadius: "6px",
                  textTransform: "capitalize",
                  display: "flex",
                  width: "100%",
                }}
              />
            </Tabs>
          </Box>
          <Box sx={{ padding: { xs: "0rem", sm: "0rem 0rem" } }}>
            <Box className="seconCardDetail">
              {tabPanelContent[selectedTab]}
            </Box>
          </Box>
        </Box>

        <Box mt={5} mb={5}>
          <Box className="thirdSection">
            <Typography
              className="mainHeading"
              sx={{ color: "#ffff", width: { xs: "100%", sm: "75%" } }}
            >
              {t("landingPage.medicalProfessionals")}
            </Typography>
            <Box sx={{ padding: { xs: "1rem", lg: "3rem 6rem" } }}>
              <Grid container spacing={4} mb={5}>
                <Grid item xs={12} md={6} sx={{ zIndex: "1" }}>
                  <Grid container spacing={0} className="cardInfo">
                    <Grid item xs={8} md={8} lg={8} xl={8}>
                      <Typography
                        className="cardHeading"
                        sx={{ color: "#ffff" }}
                      >
                        {t("landingPage.regulatedMedical")}
                      </Typography>
                      <Typography
                        className="cardDetail"
                        sx={{ color: "#ffff" }}
                      >
                        {t("landingPage.realTimePayment")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      md={4}
                      lg={4}
                      xl={4}
                      alignSelf="center"
                      sx={{
                        display: { xs: "block", lg: "block" },
                        textAlign: "right",
                      }}
                    >
                      <img
                        src={landingPlus}
                        style={{
                          objectFit: "cover",
                          maxHeight: "100%",
                          maxWidth: "100%",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} sx={{ zIndex: "1" }}>
                  <Grid container spacing={0} className="cardInfo">
                    <Grid item xs={8} md={8} lg={8} xl={8}>
                      <Typography
                        className="cardHeading"
                        sx={{ color: "#ffff" }}
                      >
                        {t("landingPage.regulatedMedical1")}
                      </Typography>
                      <Typography
                        className="cardDetail"
                        sx={{ color: "#ffff" }}
                      >
                        {t("landingPage.realTimePayment1")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      md={4}
                      lg={4}
                      xl={4}
                      alignSelf="center"
                      sx={{
                        display: { xs: "block", lg: "block" },
                        textAlign: "right",
                      }}
                    >
                      <img
                        src={landingCapsule}
                        style={{
                          objectFit: "cover",
                          maxHeight: "100%",
                          maxWidth: "100%",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box mt={2} className="secondCardInfo">
                <Grid container spacing={0}>
                  <Grid item xs={8} md={4} lg={4} xl={4} sx={{ zIndex: "1" }}>
                    <Typography className="cardHeading" sx={{ color: "#ffff" }}>
                      {t("landingPage.regulatedMedical2")}
                    </Typography>
                    <Typography className="cardDetail" sx={{ color: "#ffff" }}>
                      {t("landingPage.realTimePayment2")}
                    </Typography>
                    <Button
                      className="contained contained-primary"
                      sx={{
                        color: "#235d5e !important",
                        backgroundColor: "white !important",
                        border: "1px solid #235d5e ",
                        borderRadius: "6px",
                        textTransform: "capitalize",
                        display: "flex",
                        margin: "15px 0px",
                        display: { xs: "block", md: "none" },
                      }}
                      onClick={() => {
                        navigate("/qr/businesscard");
                      }}
                    >
                      {t("landingPage.learnMore")}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    md={4}
                    lg={4}
                    xl={4}
                    sx={{
                      display: { xs: "block", lg: "block" },
                      textAlign: { xs: "right", md: "center" },
                      maxHeight: { xs: "180px", md: "215px" },
                    }}
                  >
                    <img
                      src={landingTube}
                      style={{
                        objectFit: "cover",
                        maxHeight: "100%",
                        maxWidth: "100%",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={4}
                    xl={4}
                    sx={{ display: { xs: "none", md: "block" } }}
                  >
                    <Typography className="cardDetail" sx={{ color: "#ffff" }}>
                      {t("landingPage.realTimePayment3")}
                    </Typography>
                    <Button
                      className="contained contained-primary"
                      sx={{
                        color: "#235d5e !important",
                        backgroundColor: "white !important",
                        border: "1px solid #235d5e ",
                        borderRadius: "6px",
                        textTransform: "capitalize",
                        display: "flex",
                        margin: "20px 0px",
                      }}
                      onClick={() => {
                        navigate("/signup");
                      }}
                    >
                      {t("landingPage.learnMore")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box mt={5} mb={1} className="forthSection">
          <Typography className="forthSectionHeading">
            {t("landingPage.exclusiveExchange")}
            <br /> {t("landingPage.Platform1")}
          </Typography>

          <Box
            sx={{ padding: { xs: "1rem", lg: "3rem 6rem" } }}
            position="relative"
          >
            <Box className="forthSectionDetail">
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={7}
                  xl={6}
                  sx={{ order: { xs: "2", md: "1" } }}
                >
                  <Typography className="forthSectionDetailHeading">
                    {t("landingPage.bulletPoints")}
                  </Typography>

                  <Box>
                    {salesAndPurchasesBullets?.map((el) => (
                      <Box display="flex" mb={2}>
                        <img src={bulletsIcon} />
                        <Box ml={1}>{t(el)}</Box>
                      </Box>
                    ))}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={5}
                  xl={6}
                  sx={{
                    display: { xs: "block", md: "block" },
                    textAlign: { xs: "center", md: "right" },
                    order: { xs: "1", md: "2" },
                  }}
                >
                  <img
                    src={landingBag}
                    style={{
                      objectFit: "cover",
                      maxHeight: "310px",
                      maxWidth: "100%",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>
      <NewsLetter />
      <Footer />
    </Container>
  );
};
export default NexusBusiness;
