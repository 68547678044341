import React from "react";
import Box from "@mui/material/Box";
import ResponsiveAppBar from "./businessHeader";
import "./business.scss";
import HeaderImg from "../../assets/businessPage/Header.png";
import FooterImg from "../../assets/businessPage/Footer.png";
import { Button, Stack, Typography } from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import logo from "../../assets/images/newLogo.svg";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/system";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import Footer from "../../shared/components/footer";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <Container>
      <ResponsiveAppBar />
      <Box
        className="VactorCus"
        sx={{
          backgroundImage: `url(${HeaderImg})`,
        }}
      >
        <Box
          textAlign="center"
          sx={{ padding: { xs: "80px 48px", sm: "165px 48px" } }}
        >
          <Typography
            fontSize={{ xs: "30px", sm: "40px" }}
            fontWeight="700"
            color="#fff"
            mb={3}
          >
            {t("privacyPolicy.privacyPolicy")}
          </Typography>
          <Typography
            fontSize={{ xs: "12px", sm: "14px" }}
            fontWeight="400"
            color="#fff"
            sx={{ width: { xs: "100%", md: "55%" }, margin: "auto" }}
          >
            {t("privacyPolicy.subTitle")}
          </Typography>
        </Box>
      </Box>

      <Stack
        spacing={6}
        sx={{ width: { xs: "95%", md: "75%" }, margin: "auto" }}
      >
        <Box>
          <Typography
            variant="h5"
            fontWeight="600"
            fontSize={{ xs: "20px", sm: "26px" }}
            mt={6}
            mb={4}
          >
            {t("privacyPolicy.heading1")}
          </Typography>
          <Typography fontSize={16}>{t("privacyPolicy.desc1")}</Typography>
        </Box>
        <Box>
          <Typography
            variant="h5"
            fontSize={{ xs: "20px", sm: "26px" }}
            fontWeight="600"
            mb={4}
          >
            {t("privacyPolicy.heading2")}
          </Typography>
          <Typography mb={2} fontSize={16}>
            {t("privacyPolicy.desc2")}
          </Typography>
          <ul>
            <li>
              <Typography fontSize={16}>
                {t("privacyPolicy.bullet1")}
              </Typography>
            </li>
            <li>
              <Typography fontSize={16}>
                {t("privacyPolicy.bullet2")}
              </Typography>
            </li>
            <li>
              <Typography fontSize={16}>
                {t("privacyPolicy.bullet3")}
              </Typography>
            </li>
            <li>
              <Typography fontSize={16}>
                {t("privacyPolicy.bullet4")}
              </Typography>
            </li>
            <li>
              <Typography fontSize={16}>
                {t("privacyPolicy.bullet5")}
              </Typography>
            </li>
          </ul>
        </Box>
        <Box>
          <Typography
            variant="h5"
            fontSize={{ xs: "20px", sm: "26px" }}
            fontWeight="600"
            mb={4}
          >
            {t("privacyPolicy.heading3")}
          </Typography>
          <Typography fontSize={16}>{t("privacyPolicy.desc3")}</Typography>
        </Box>
        <Box>
          <Typography
            variant="h5"
            fontSize={{ xs: "20px", sm: "26px" }}
            fontWeight="600"
            mb={4}
          >
            {t("privacyPolicy.heading4")}
          </Typography>
          <Typography fontSize={16}>{t("privacyPolicy.desc4")}</Typography>
        </Box>
        <Box>
          <Typography
            variant="h5"
            fontSize={{ xs: "20px", sm: "26px" }}
            fontWeight="600"
            mb={4}
          >
            {t("privacyPolicy.heading5")}
          </Typography>
          <Typography fontSize={16}>{t("privacyPolicy.desc5")}</Typography>
        </Box>
        <Box>
          <Typography
            variant="h5"
            fontSize={{ xs: "20px", sm: "26px" }}
            fontWeight="600"
            mb={4}
          >
            {t("privacyPolicy.heading6")}
          </Typography>
          <Typography fontSize={16}>{t("privacyPolicy.desc6")}</Typography>
        </Box>
        <Box>
          <Typography
            variant="h5"
            fontSize={{ xs: "20px", sm: "26px" }}
            fontWeight="600"
            mb={4}
          >
            {t("privacyPolicy.heading7")}
          </Typography>
          <Typography fontSize={16}>{t("privacyPolicy.desc7")}</Typography>
        </Box>
        <Box>
          <Typography
            variant="h5"
            fontSize={{ xs: "20px", sm: "26px" }}
            fontWeight="600"
            mb={4}
          >
            {t("privacyPolicy.heading8")}
          </Typography>
          <Typography fontSize={16}>{t("privacyPolicy.desc8")}</Typography>
        </Box>
        <Box>
          <Typography
            variant="h5"
            fontSize={{ xs: "20px", sm: "26px" }}
            fontWeight="600"
            mb={4}
          >
            {t("privacyPolicy.heading9")}
          </Typography>
          <Typography mb={6} fontSize={16}>
            {t("privacyPolicy.desc9")}
          </Typography>
        </Box>
      </Stack>

      <Box
        className="VactorCus"
        sx={{
          backgroundImage: `url(${FooterImg})`,
        }}
      >
        <Box
          textAlign="center"
          sx={{ padding: { xs: "50px 48px", sm: "100px 48px" } }}
        >
          <Typography
            fontSize={{ xs: "30px", sm: "40px" }}
            fontWeight="700"
            color="#fff"
            mb={3}
          >
            {t("privacyPolicy.contactUs")}
          </Typography>
          <Typography
            fontSize={{ xs: "12px", sm: "14px" }}
            fontWeight="400"
            color="#fff"
            mb={3}
          >
            {t("privacyPolicy.title")}
          </Typography>
          <Button
            onClick={() => navigate(`/qr/businesscard`, { replace: true })}
            sx={{
              borderRadius: "12px",
              border: "2px solid #FFF",
              color: "#FFF",
              textTransform: "capitalize",
              fontSize: { xs: "12px", sm: "14px" },
              padding: { xs: "10px 20px", sm: " 10px 30px" },
            }}
          >
            {t("privacyPolicy.btn")}
          </Button>
        </Box>
      </Box>
      <Footer />
    </Container>
  );
};
export default PrivacyPolicy;
