import * as Yup from "yup";

export const initialValues = {
  store_name: "",
  email: "",
  location: "",
  mobile_no: "",
  store_license_number: "",
  id_type: "",
  store_fax_no: "",
  front_picture: "",
  back_picture: "",
  store_landline_num: "",
  type: "",
  GST_NO: "",
  PST_NO: "",
  restricted_item_sales: false,
  narcotic_distribution_license: "",
};

export const Schema = Yup.object().shape({
  store_name: Yup.string()
    .max(255)
    .min(3, "Store Name must be at least 3 characters.")
    .required("Store Name is required"),
  email: Yup.string().max(255).required("Email is required"),
  store_license_number: Yup.string()
    .max(255)
    .min(3, " license must be at least 3 characters.")
    .required("License is required"),
  location: Yup.string().max(700).required("Location is required"),
  mobile_no: Yup.string().max(255).required("Phone number is required"),
  type: Yup.string().max(255).required("Store type is required"),
  GST_NO: Yup.string().max(255).required("GST_NO is required"),
  PST_NO: Yup.string().max(255),
  store_landline_num: Yup.number().required("Landline number required"),
  store_fax_no: Yup.number().required("Fax number required"),
  id_type: Yup.string().required("Identity type is required"),
  front_picture: Yup.string().required("front_picture type is required"),
  back_picture: Yup.string().required("back_picture type is required"),
  restricted_item_sales: Yup.boolean(),
  narcotic_distribution_license: Yup.string().when("restricted_item_sales", {
    is: (restricted_item_sales) =>
      restricted_item_sales == true ? true : false, //just an e.g. you can return a function
    then: Yup.string().required("Narcotic Distribution License is mandatory"),
    otherwise: Yup.string(),
  }),
});
