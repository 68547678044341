import * as Yup from "yup";

export const initialValues = {
  password: "",
  confirmPassword: "",
};
export const Schema = Yup.object().shape({
  password: Yup.string().max(255).required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm pasword required")
    .max(255)
    .oneOf([Yup.ref("password"), null], "Password must match"),
});
