import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { capitalize } from "../../../helpers/formatting";
import { formatNumberWithCommas } from "../../../helpers/getTotalValue";

const OrderSummaryDetails = ({ pre, isMobile }) => {
  return (
    <Box position={"relative"} mb={3}>
      <Box className="store-checkout">
        <b>Store : </b>
        {capitalize(pre?.orderedBy?.store_name)} ,<b> Location : </b>
        {pre?.carts[0]?.products[0]?.for?.location}
      </Box>
      <Card
        sx={{
          borderRadius: "20px",
          border: "1px solid #F0F0F0",
        }}
      >
        <CardContent>
          <>
            {pre && pre?.carts && pre?.carts?.length ? (
              pre?.carts?.map((el, i) => (
                <>
                  {el && el?.products && el?.products?.length
                    ? el?.products?.map((item, index) => (
                        <>
                          <Grid item md={12} sm={12} lg={12} xs={12} pt={2}>
                            {index == 0 && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box pt={3}>
                                  <Chip
                                    label={`From ${el?.orderedTo?.uuid}`}
                                    sx={{
                                      color: "#101828",
                                      backgroundColor: "#F3CA60",
                                      borderRadius: "8px",
                                    }}
                                    size={isMobile ? "small" : "medium"}
                                  />
                                </Box>
                              </Box>
                            )}
                          </Grid>
                          <Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              sx={{
                                width: "100%",
                              }}
                            >
                              <Grid item md={12} sm={12} lg={12} xs={12}>
                                <Grid
                                  container
                                  spacing={2}
                                  pt={3}
                                  alignItems="center"
                                >
                                  <Grid item md={2} lg={2} sm={2} xs={6}>
                                    {item?.product &&
                                    item?.product?.product?.imageCover
                                      ?.full_image &&
                                    item?.product?.product?.imageCover
                                      ?.full_image ? (
                                      <img
                                        src={
                                          item?.product?.product?.imageCover
                                            ?.full_image
                                        }
                                        style={{
                                          objectFit: "contain",
                                          width: "80px",
                                          height: "80px",
                                        }}
                                      />
                                    ) : (
                                      <Box
                                        className="cusProductName"
                                        sx={{
                                          width: "80px",
                                          height: "80px",
                                        }}
                                      >
                                        <Typography className="image-product-text">
                                          {item?.product?.product?.product_name}
                                        </Typography>
                                      </Box>
                                    )}
                                  </Grid>
                                  <Grid
                                    item
                                    md={4}
                                    lg={4}
                                    sm={4}
                                    xs={6}
                                    pl={"0px !important"}
                                  >
                                    <Box>
                                      <Tooltip
                                        title={
                                          item?.product?.product?.product_name
                                        }
                                      >
                                        <Typography
                                          mx={{
                                            lg: 1,
                                            md: 1,
                                            sm: 1,
                                          }}
                                          className="cart-text text-ellipses"
                                        >
                                          {item?.product?.product?.product_name}
                                        </Typography>
                                      </Tooltip>
                                    </Box>
                                  </Grid>
                                  <Grid item md={4} lg={2} sm={4} xs={6}>
                                    <Box>
                                      <Typography
                                        variant="subtitle1"
                                        className="cart-price"
                                      >
                                        {`$${formatNumberWithCommas(
                                          Number(
                                            item?.discountedPrice
                                              ? item?.discountedPrice
                                                  ?.discountedPrice
                                              : item?.price
                                          ).toFixed(2)
                                        )}`}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    md={4}
                                    lg={2}
                                    sm={4}
                                    xs={6}
                                    pl={"0px !important"}
                                  >
                                    <TextField
                                      className="counter"
                                      type="number"
                                      disabled
                                      value={item?.count}
                                      defaultValue={item?.count}
                                      id="outlined-basic"
                                      variant="outlined"
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    md={4}
                                    lg={2}
                                    sm={4}
                                    xs={6}
                                    textAlign="end"
                                  >
                                    <Box>
                                      <Typography
                                        variant="subtitle1"
                                        className="cart-price"
                                      >
                                        ={" "}
                                        {`$${formatNumberWithCommas(
                                          Number(
                                            item?.count *
                                              (item?.discountedPrice
                                                ? item?.discountedPrice
                                                    ?.discountedPrice
                                                : item?.price)
                                          ).toFixed(2)
                                        )}`}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                          {el?.products?.length - 1 == index && (
                            <Divider
                              sx={{
                                borderColor: "#ccc",
                                marginTop: "1.5rem",
                              }}
                            />
                          )}
                        </>
                      ))
                    : ""}
                </>
              ))
            ) : (
              <></>
            )}
          </>
        </CardContent>
      </Card>
    </Box>
  );
};

export default OrderSummaryDetails;
